<template>
  <div>
    <!-- Table Container Card -->
    <div no-body class="mb-0">
      <div class="m-2-br-bottm device-sensor">
        <b-row class="">
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile-50"
            ><h4 class="card-title">{{ $t("device.Commands") }}</h4>
          </b-col>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0 mobile-50 mobile-v-p"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="search-label d-none d-sm-inline">{{
                $t("Search")
              }}</label>
              <b-input-group class="" v-if="selected_tab === 'deviceCommand'">
                <b-form-input
                  v-model="filter"
                  ref="name_input"
                  debounce="500"
                  class="d-inline-block search-bg"
                  placeholder="Search..."
                ></b-form-input>
                <b-input-group-append class="input-prefix-img search-clear"
                  ><feather-icon
                    icon="XIcon"
                    size="24"
                    @click="
                      (e) => {
                        filter = '';
                      }
                    "
                  />
                </b-input-group-append>
              </b-input-group>
              <b-input-group class="" v-if="selected_tab === 'executeCommand'">
                <b-form-input
                  v-model="historyFilter"
                  ref="name_input"
                  debounce="500"
                  class="d-inline-block search-bg"
                  placeholder="Search..."
                ></b-form-input>
                <b-input-group-append class="input-prefix-img search-clear"
                  ><feather-icon
                    icon="XIcon"
                    size="24"
                    @click="
                      (e) => {
                        historyFilter = '';
                      }
                    "
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
            <b-button
              v-if="
                !isViewOnly &&
                checkAbility({
                  action: constants.PERMISSIONS_ACTION.EXECUTE_COMMAND,
                  subject: constants.PERMISSIONS_MODEL.DEVICES
                })
              "
              @click="executeDeviceCommand()"
              variant="primary"
              class="ml-10"
            >
              <feather-icon icon="PlayIcon" size="12" class="icon-margin" />
              <!-- <feather-icon icon="PlusIcon" size="12" class="icon-margin" /> -->
              <span class="d-none d-sm-inline rt">{{
                $t("device.Execute")
              }}</span>
            </b-button>
            <b-button
              @click="AddNewCommand(null, 'add')"
              variant="primary"
              v-if="!isViewOnly"
              class="ml-10"
            >
              <feather-icon icon="PlusIcon" size="12" class="icon-margin" />
              <span class="d-none d-sm-inline rt">{{ $t("Add") }}</span>
            </b-button>
            <b-button
              variant="outline-primary"
              size="sm"
              @click="
                (e) => {
                  $router.push({ name: 'devices-list' });
                }
              "
              class="btn set-back-mobile v2-back ml-10"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-tabs pills class="mt-1">
        <b-tab
          :title="$t('device.Commands')"
          @click="activeTabClick('deviceCommand')"
          :active="selected_tab === 'deviceCommand'"
        >
          <div
            v-if="show"
            :class="
              ['view-device', 'edit-device'].includes($route.name)
                ? 'edit-command'
                : 'table-responsive'
            "
          >
            <b-skeleton-table
              v-if="show"
              :rows="10"
              :columns="7"
              :table-props="{ bordered: true, striped: true }"
            />
          </div>
          <b-table
            v-if="!show"
            :class="
              ['view-device', 'edit-device'].includes($route.name)
                ? 'edit-command'
                : 'position-relative command-scroll'
            "
            responsive
            show-empty
            align-v="end"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :empty-text="$t('NoMatchingRecordsFound')"
          >
            <!-- Column: devices -->
            <template #cell(command)="row">
              <h6 class="mb-0">{{ row.item.code }} {{ row.item.index }}</h6>
            </template>
            <template #cell(enable)="row">
              <h6 class="mb-0">
                {{
                  `${
                    row.item.enable
                      ? $t("device.Enabled")
                      : $t("device.Disabled")
                  }`
                }}
              </h6>
            </template>
            <template #cell(description)="row">
              <h6 class="mb-0">
                {{
                  `${row.item.description ? `${row.item.description}` : " "}`
                }}
              </h6>
            </template>
            <template #cell(actions)="data">
              <div class="text-left min-80">
                <feather-icon
                  icon="EyeIcon"
                  style="
                    position: relative;
                    margin-right: 10px;
                    cursor: pointer;
                  "
                  class="text-primary action-icon"
                  size="18"
                  v-if="isViewOnly"
                  @click="AddNewCommand(data.item, 'update')"
                  v-b-tooltip.hover.v-primary
                  :title="$t('tooTip.view')"
                />
                <feather-icon
                  icon="EditIcon"
                  style="
                    position: relative;
                    margin-right: 10px;
                    cursor: pointer;
                  "
                  class="text-primary action-icon"
                  size="18"
                  v-if="!isViewOnly"
                  @click="AddNewCommand(data.item, 'update')"
                  v-b-tooltip.hover.v-primary
                  :title="$t('tooTip.update')"
                />

                <feather-icon
                  v-b-tooltip.hover.v-primary
                  :title="$t('tooTip.delete')"
                  v-if="!isViewOnly"
                  icon="Trash2Icon"
                  class="text-danger action-icon"
                  size="18"
                  @click="onClickClose(data.item)"
                  style="
                    position: relative;
                    margin-right: 10px;
                    cursor: pointer;
                  "
                />
                <feather-icon
                  v-b-tooltip.hover.v-primary
                  :title="$t('tooTip.execute')"
                  v-if="
                    !isViewOnly &&
                    data.item.enable &&
                    checkAbility({
                      action: constants.PERMISSIONS_ACTION.EXECUTE_COMMAND,
                      subject: constants.PERMISSIONS_MODEL.DEVICES
                    })
                  "
                  icon="PlayIcon"
                  class="text-success action-icon"
                  size="18"
                  @click="executeDeviceCommand(data.item)"
                  style="position: relative; cursor: pointer"
                />
              </div>
            </template>
          </b-table>
          <div class="mx-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                &nbsp;
              </b-col>
              <!-- Pagination -->
            </b-row>
          </div>

          <div class="mx-2">
            <b-row>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end commands-pagination"
              >
                <b-pagination
                  v-model="currentPage"
                  :per-page="perPage"
                  :total-rows="totalCommands"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                ></b-pagination>
              </b-col>
              <!-- Pagination -->
            </b-row>
          </div></b-tab
        >
        <b-tab
          :title="$t('device.CommandExecuteHistory')"
          @click="activeTabClick('executeCommand')"
          :active="selected_tab === 'executeCommand'"
          v-if="
            checkAbility({
              action: constants.PERMISSIONS_ACTION.EXECUTE_COMMAND_LOG_HISTORY,
              subject: constants.PERMISSIONS_MODEL.DEVICES
            })
          "
        >
          <div
            v-if="show"
            :class="
              ['view-device', 'edit-device'].includes($route.name)
                ? 'edit-command'
                : 'table-responsive'
            "
          >
            <b-skeleton-table
              v-if="show"
              :rows="10"
              :columns="7"
              :table-props="{ bordered: true, striped: true }"
            />
          </div>
          <b-table
            v-if="!show"
            :class="
              ['view-device', 'edit-device'].includes($route.name)
                ? 'edit-command'
                : 'position-relative command-scroll'
            "
            responsive
            show-empty
            align-v="end"
            :items="historyItems"
            :fields="historyTableColumns"
            :empty-text="$t('NoMatchingRecordsFound')"
          >
            <!-- Column: devices -->
            <template #cell(command)="row">
              <h6 class="mb-0">{{ row.item.command }}</h6>
            </template>
            <template #cell(time)="row">
              <h6 class="mb-0">{{ `${DateTimeConvert(row.item.time)}` }}</h6>
            </template>
            <template #cell(enable)="row">
              <h6 class="mb-0">
                {{ `${row.item.status}` }}
              </h6>
            </template>
            <template #cell(actions)="data">
              <div class="text-left min-80">
                <feather-icon
                  icon="EyeIcon"
                  style="
                    position: relative;
                    margin-right: 10px;
                    cursor: pointer;
                  "
                  class="text-primary action-icon"
                  size="18"
                  @click="getCommandLogDetails(data.item)"
                  v-b-tooltip.hover.v-primary
                  :title="$t('tooTip.view')"
                />
              </div>
            </template>
          </b-table>
          <div class="mx-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                &nbsp;
              </b-col>
              <!-- Pagination -->
            </b-row>
          </div>

          <div class="mx-2">
            <b-row>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end commands-pagination"
              >
                <b-pagination
                  v-model="currentPageHistory"
                  :per-page="perPageHistory"
                  :total-rows="totalHistoryCommands"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                ></b-pagination>
              </b-col>
              <!-- Pagination -->
            </b-row>
          </div>
        </b-tab></b-tabs
      >

      <AddDeviceSensorCommand
        :commandProps="commandProps"
        :onCloseDeviceAddCommand="onCloseDeviceAddCommand"
        :saveDeviceAddCommand="saveDeviceAddCommand"
        :deviceData="deviceData"
        :tab="tab"
        :isViewOnly="isViewOnly"
        :commandsList="commandsList"
      />
      <ExecuteDeviceSensorCommand
        :commandProps="commandProps"
        :commandsList="commandsList"
        :onCloseDeviceAddCommand="onCloseDeviceAddCommand"
        :saveDeviceAddCommand="saveDeviceAddCommand"
        :deviceData="deviceData"
        :tab="tab"
        :isViewOnly="isViewOnly"
        :savedCommands="items"
        :selectedCommandData="selectedCommandData"
      />
      <AreYouSureModal
        :data="{ ...deleteCommand, modelName: 'modal-sm-remove-command' }"
        :onClose="onCloseCommands"
        :removedUser="removeCommand"
      />
      <Loader :show="showLoading" />
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BForm,
  BImg,
  BTooltip,
  VBTooltip,
  BSkeletonTable,
  BTabs,
  BTab
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import DeviceService from "@/libs/api/device-service";
import CommandService from "@/libs/api/command-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import Loader from "@/layouts/components/Loader.vue";
import AddDeviceSensorCommand from "./AddDeviceSensorCommand.vue";
import ExecuteDeviceSensorCommand from "./ExecuteDeviceSensorCommand.vue";
import validators from "@/utils/validators";
import constants from "@/utils/constants";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    Loader,
    vSelect,
    show: false,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    BForm,
    BImg,
    BTooltip,
    VBTooltip,
    BSkeletonTable,
    AddDeviceSensorCommand,
    AreYouSureModal,
    ExecuteDeviceSensorCommand,
    BTabs,
    BTab
  },
  data() {
    return {
      selected_tab: "deviceCommand",
      oldItems: [],
      tableColumns: [
        {
          key: "code",
          tdClass: "command-table-td",
          thClass: "command-table-th",
          Class: "command-table-th",
          label: this.$t("device.code")
        },
        {
          key: "description",
          tdClass: "command-table-td",
          thClass: "command-table-th",
          Class: "command-table-th",
          label: this.$t("device.description")
        },
        {
          key: "enable",
          tdClass: "command-table-td",
          thClass: "command-table-th",
          Class: "command-table-th",
          label: this.$t("device.enable")
        },
        {
          key: "ACTIONS",
          tdClass: "cmd-action-width",
          tdClass: "command-table-td",
          thClass: "command-table-th",
          Class: "command-table-th",
          label: this.$t("device.ACTIONS")
        }
      ],
      historyTableColumns: [
        {
          key: "command",
          tdClass: "command-table-td",
          thClass: "command-table-th",
          Class: "command-table-th",
          label: this.$t("device.Command")
        },
        {
          key: "time",
          tdClass: "command-table-td",
          thClass: "command-table-th",
          Class: "command-table-th",
          label: this.$t("device.Time")
        },
        {
          key: "status",
          tdClass: "command-table-td",
          thClass: "command-table-th",
          label: this.$t("device.Status")
        },
        {
          key: "ACTIONS",
          tdClass: "cmd-action-width",
          tdClass: "command-table-td",
          thClass: "command-table-th",
          Class: "command-table-th",
          label: this.$t("device.ACTIONS")
        }
      ],
      perPage: 3,
      currentPage: 1,
      totalCommands: 10,
      items: [],
      commandsList: [],
      historyItems: [],
      totalHistoryCommands: 10,
      currentPageHistory: 1,
      perPageHistory: 10,
      isViewOnly: false,
      show: false,
      tab: "add",
      filter: "",
      historyFilter: "",
      commandProps: {},
      oldCommandProps: {},
      commandLogData: {},
      deleteCommand: {},
      showLoading: false,
      selectedCommandData: {}
    };
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  props: [
    "deviceData",
    "DeviceCommandFun",
    "saveCommands",
    "activeTab",
    "activeTabTitle"
  ],
  watch: {
    filter() {
      this.filterSensors();
      this.perPage = 10;
      this.currentPage = 1;
    },
    deviceData() {
      this.getAllCommandList();

      this.getAllCommands();
      if (this.selected_tab === "executeCommand") {
        if (!this.commandsList || !this.commandsList.length) {
          this.getAllCommandList();
        }
        this.executeCommand();
      }
    },
    activeTab() {
      this.focusInput();
      // this.getAllCommands();// this api call comment by rl206 for FLTM-5246 because duplicate api call.

      if (this.$t("device.Commands") === this.activeTabTitle) {
        this.getAllCommands();
        this.perPage = 10;
        this.currentPage = 1;
        if (this.selected_tab === "executeCommand") {
          this.perPageHistory = 10;
          this.currentPageHistory = 1;
          this.executeCommand();
        }
      }
    },
    historyFilter() {
      this.perPageHistory = 10;
      this.currentPageHistory = 1;
      this.executeCommand();
    },
    currentPageHistory() {
      this.executeCommand();
    },
    perPageHistory() {
      this.executeCommand();
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.commandsList || !this.commandsList.length) {
        this.getAllCommandList();
      }
    }, 10000);
    this.getAllCommandList();
    this.focusInput();
  },
  methods: {
    async executeCommandFun(item) {
      try {
        this.showLoading = true;
        const filter = {
          device_id: this.deviceData.id || this.$route.params.id,
          command: item.code,
          params: item.params
        };

        let response = await new CommandService().executeDeviceCommand(filter);

        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "",
              text: this.$t("device.CommandExecuteSuccessfully"),
              icon: "InfoIcon",
              variant: "success"
            }
          });
          this.showLoading = false;
          // this.close();
        } else if (response && response.error && response.error.message) {
          this.showLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    activeTabClick(tab) {
      this.selected_tab = tab;
      if (tab === "executeCommand") {
        this.perPageHistory = 10;
        this.currentPageHistory = 1;
        this.executeCommand();
      }
    },
    async executeCommand() {
      try {
        this.showLoading = true;
        const filter = {
          device_id: this.$route.params.id || this.deviceData.id,
          filter: this.historyFilter,
          page_size: this.perPageHistory,
          page: this.currentPageHistory
        };
        let response = await new CommandService().getAllCommandsHistory(filter);
        this.showLoading = false;
        if (response && response.data) {
          this.historyItems = response.data.devices;
          this.totalHistoryCommands = response.data.count;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getCommandLogDetails(log) {
      try {
        let aa = {};
        Object.keys(JSON.parse(log.params)).forEach((e) => {
          aa[e] = { value: JSON.parse(log.params)[e] };
        });
        this.executeDeviceCommand({
          params: aa,
          code: log.code || log.command,
          isviewOnly: true
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    onCloseCommands() {
      this.$bvModal.hide("modal-sm-remove-command");
    },
    onClickClose(data) {
      this.deleteCommand = data;
      this.deleteCommand.name = data.command;
      this.$bvModal.show("modal-sm-remove-command");
    },

    async getAllCommandList() {
      try {
        this.deviceDataUpdated = this.deviceData;
        this.isViewOnly = this.$route.name === "view-device" ? true : false;
        this.items = (this.deviceData && this.deviceData.commands) || [];
        this.oldItems = (this.deviceData && this.deviceData.commands) || [];
        this.items = this.items.map((e, i) => {
          e.parameter = Object.keys(e.params);
          e.index = i + 1;
          return e;
        });

        this.oldItems = this.items;
        this.totalCommands = this.items.length;
        this.focusInput();
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    AddNewCommand(item, page) {
      this.oldCommandProps = this.items.find(
        (e) => e && item && e.index === item.index
      );
      this.commandProps = Object.assign({}, item);

      this.tab = page;
      if (page === "edit") {
        this.commandProps.isEditable = true;
      }

      this.$bvModal.show("modal-add-command");
    },
    executeDeviceCommand(item) {
      if (item) {
        this.selectedCommandData = {
          device_id: this.deviceData.id || this.$route.params.id,
          command: item.code,
          params: item.params,
          isviewOnly: item.isviewOnly ? true : false
        };
      } else {
        this.selectedCommandData = {
          device_id: this.deviceData.id || this.$route.params.id,
          command: "",
          params: {},
          isviewOnly: false
        };
      }

      this.$bvModal.show("modal-execute-command");
    },
    onCloseDeviceAddCommand() {
      this.commandProps = Object.assign({}, this.oldCommandProps);
      this.$bvModal.hide("modal-add-command");
      this.focusInput();
    },
    focusInput() {
      if (this.$refs && this.$refs.name_input) {
        this.$refs.name_input.focus();
      }
    },

    async saveDeviceAddCommand(commandData) {
      this.focusInput();
      const data = JSON.parse(JSON.stringify(commandData));
      data.parameter =
        (commandData &&
          commandData.params &&
          Object.keys(commandData.params)) ||
        [];
      data.parameter.forEach((e) => {
        if (
          data.params[e].data_type &&
          data.params[e].data_type.toLowerCase() === "datetime" &&
          data.params[e].value
        ) {
          data.params[e].value = new Date(data.params[e].value);
        }

        if (data.params[e].data_type) {
          delete data.params[e]["data_type"];
        }
      });
      const checkDuplicateType = this.items.filter(
        (e) =>
          e.code &&
          data.code &&
          e.code.toLowerCase() === data.code.toLowerCase()
      );

      if (
        (checkDuplicateType &&
          checkDuplicateType.length > 0 &&
          data &&
          data["index"] != checkDuplicateType[0].index) ||
        (checkDuplicateType && checkDuplicateType.length > 1)
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Not allowed multiple ${data.code}  commands`,
            text: "",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return false;
      } else if (
        checkDuplicateType &&
        checkDuplicateType.length > 0 &&
        data &&
        data["index"] == "" &&
        this.tab != "edit"
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Not allowed multiple ${data.type}  commands`,
            text: "",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return false;
      } else {
        const findIndex = this.items.filter(
          (e) => e.index === data.index && data.index !== ""
        );
        if (findIndex && findIndex.length) {
          this.items = this.items.map((e) => {
            if (e.index === data.index) {
              e = data;
            }
            return e;
          });
        } else if (this.tab != "edit") {
          const index = Math.max(...this.items.map((o) => o.index));
          data.index = index + 1;
          this.items.push(data);
        }
        this.DeviceCommandFun(this.items);
        setTimeout(() => {
          if (this.$route.name === "edit-device") {
            this.saveCommands(this.tab);
          }

          this.$bvModal.hide("modal-add-command");
        }, 200);
        this.items = this.items.map((e, i) => {
          e.index = i + 1;
          return e;
        });
      }
    },
    DateTimeConvert(date) {
      return validators.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    async getAllCommands(search) {
      try {
        if (search === "allList") {
          search = "";
        }

        this.showLoading = true;

        let modelId;
        if (
          this.deviceData &&
          (this.deviceData.model || this.deviceData.model_id)
        ) {
          modelId = this.deviceData.model || this.deviceData.model_id;
        } else {
          return;
        }
        const filter = search
          ? { search_sensor: search, id: modelId }
          : { id: modelId };
        let response = await new DeviceService().getAllCommands(filter);
        this.showLoading = false;
        if (response && response.data) {
          this.commandsList = response.data.commands;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    filterSensors() {
      if (
        this.filter === "" ||
        !this.filter ||
        (this.filter && !this.filter.trim())
      ) {
        this.items = this.oldItems; // lowecase
      } else {
        const searchParam = this.filter.trim();
        const search = new RegExp(searchParam, "i"); // prepare a regex object
        this.items = this.oldItems.filter((item) => search.test(item.code));
      }
      this.totalCommands = this.items.length;
    },
    removeCommand(data) {
      this.items = this.items.filter((e) => e.index !== data.index);
      //this.removedSensors.push(data.index);
      this.DeviceCommandFun(this.items);
      if (this.$route.name === "edit-device") {
        this.saveCommands("remove");
      }
      this.$bvModal.hide("modal-sm-remove-command");
      this.$bvModal.hide("modal-add-command");
      this.focusInput();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
th {
  text-align: left;
}
.cmd-action-width {
  width: 190px;
  min-width: 190px;
  max-width: 190px;
  text-align: left !important;
}
.commands-pagination {
  margin-bottom: 15px;
}
.add-icon-s {
  vertical-align: middle !important;
  margin-right: 5px;
}
@media only screen and (max-width: 600px) {
  .device-sensor {
    padding: 10px !important;
  }
  .mobile-v-p {
    justify-content: end !important;
  }
  .command-scroll {
    height: calc(100vh - 423px) !important;
    overflow-y: auto;
  }
}
.command-scroll {
  height: calc(100vh - 388px);
  overflow-y: auto;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
.table-responsive {
  height: calc(100vh - 388px);
}
.edit-command {
  height: calc(100vh - 360px);
  overflow-y: auto;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
@media only screen and (max-width: 600px) {
  .edit-command {
    height: calc(100vh - 370px) !important;
    overflow-y: auto;
    .b-table {
      width: calc(100% - 18px) !important;
    }
  }
}
.edit-command {
  th {
    text-align: center !important;
  }
}
.rt {
  margin-left: 3px;
}
</style>
<style lang="scss">
.command-table-td {
  text-align: left !important;
}
.command-table-th {
  text-align: left !important;
}
</style>
