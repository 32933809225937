<template>
  <div>
    <div class="modal-info-track new-date-picker">
      <b-row class="flex-class">
        <b-col
          sm="12"
          v-for="(parameter, key) in parameters"
          :key="key"
          class=""
        >
          <span
            v-if="
              MAPPINGS[parameter.component] ||
              parameter.source == 'config' ||
              parameter.source == 'input'
            "
          >
            <validation-provider
              :name="parameter.field"
              #default="validationContext"
              :rules="{
                required: parameter.is_required ? true : false
              }"
              :ref="parameter.field"
            >
              <b-row
                v-if="
                  MAPPINGS[parameter.component] && parameter.source == 'config'
                "
                class="flex-class"
              >
                <b-col class="dy-m-27 dy-pr-10" sm="3">
                  <div class="sensor-label-wrap">
                    <span
                      style="text-transform: capitalize"
                      :title="`${
                        parameter.label
                          ? parameter.label
                          : parameter.field && formattedString(parameter.field)
                      }   ${parameter.is_required ? '*:' : ':'}`"
                    >
                      {{
                        parameter.label
                          ? parameter.label
                          : parameter.field && formattedString(parameter.field)
                      }}{{ parameter.is_required ? "*:" : ":" }}
                    </span>
                  </div>
                </b-col>
                <b-col sm="8" class="dy-m-17">
                  <component
                    :is="MAPPINGS[parameter.component]"
                    v-bind="{
                      ...parameter
                    }"
                    :state="getValidationState(validationContext)"
                    v-model="sensorInfo.fields[parameter.field].value"
                    :isEditable="isEditable"
                    :selectedSensor="selectedSensor"
                    :sensorInfo="sensorInfo"
                    :device="device"
                  >
                  </component>
                </b-col>
              </b-row>
              <b-row
                class="flex-class"
                v-if="
                  parameter.source == 'input' &&
                  parameter.component !== 'custom'
                "
              >
                <b-col sm="2" class="dy-m-40 dy-pr-10"
                  ><div class="sensor-label-wrap">
                    <span
                      style="text-transform: capitalize"
                      :title="`${
                        parameter.label
                          ? parameter.label
                          : parameter.field && formattedString(parameter.field)
                      }   ${parameter.is_required ? '*' : ''}`"
                    >
                      {{
                        parameter.label
                          ? parameter.label
                          : parameter.field && formattedString(parameter.field)
                      }}{{ parameter.is_required ? "*:" : ":" }}
                    </span>
                  </div>
                </b-col>
                <b-col sm="7" class="dy-m-27 dy-pr-10">
                  <DeviceProtocolParametersModal
                    v-model="sensorInfo.fields[parameter.field].value"
                    :isViewOnly="!isEditable"
                    :protocolName="protocolName"
                    :sensorsData="sensorsData"
                    :parameter="parameter"
                    :sensorInfo="sensorInfo"
                    @isInvalid="isInvalid"
                    :state="getValidationState(validationContext)"
                    :selectedSensor="selectedSensor"
                    :sensorField="{
                      field: parameter.field,
                      data_type: parameter.data_type,
                      is_required: parameter.is_required
                    }"
                  ></DeviceProtocolParametersModal>
                </b-col>
                <b-col sm="1" class="dy-m-27 dy-pr-10">
                  <div
                    class="sensor-check ml-1"
                    v-if="
                      parameter.data_type &&
                      parameter.data_type.toLowerCase() == 'number'
                    "
                  >
                    <b-form-checkbox
                      :checked="
                        sensorInfo &&
                        sensorInfo.fields[parameter.field] &&
                        sensorInfo.fields[parameter.field].table &&
                        sensorInfo.fields[parameter.field].table.length
                          ? true
                          : false
                      "
                      disabled
                      class="disabled-check-box"
                    >
                    </b-form-checkbox>
                  </div>
                </b-col>
                <b-col sm="2" class="dy-m-10 dy-pr-10 apply-table">
                  <div class="calculationTable">
                    <CalculationTableModal
                      v-model="sensorInfo.fields[parameter.field]"
                      :isViewOnly="!isEditable"
                      :sensorField="{
                        field: parameter.field,
                        data_type: parameter.data_type
                      }"
                      :keyField="parameter.field"
                    >
                    </CalculationTableModal>
                  </div>
                </b-col>
              </b-row>
            </validation-provider>
          </span>
        </b-col>
      </b-row>
      <b-row
        class="flex-class"
        v-if="
          parameters &&
          parameters.length &&
          parameters.map((e) => e.component).includes('custom')
        "
      >
        <b-col sm="12" class="dy-m-40 dy-pr-10">
          <CustomComponents
            :protocolName="protocolName"
            v-model="sensorInfo.fields"
            :isEditable="isEditable"
            :sensorsData="sensorsData"
            :sensorInfo="sensorInfo"
          ></CustomComponents>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BIcon,
  BCol,
  BRow,
  BButton,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback
} from "bootstrap-vue";

import TimePicker from "./TimePicker.vue";
import DatePicker from "./DatePicker.vue";
import DateTimePicker from "./DateTimePicker.vue";
import GroupCheckbox from "./GroupCheckbox.vue";
import Checkbox from "./Checkbox.vue";
import Selection from "./Selection.vue";
import TextInputs from "./TextInputs.vue";
import ColorInput from "./ColorInput";
import TextArea from "./TextArea.vue";
import CustomComponents from "./CustomComponents.vue";
import { ValidationProvider } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import CalculationTableModal from "@/views/DeviceOnboarding/CalculationTableModal.vue";
import DeviceProtocolParametersModal from "@/views/DeviceOnboarding/DeviceProtocolParametersModal";
const PARAMETER_COMPONENTS = {
  TimePicker,
  Checkbox,
  Selection,

  TextInputs,
  ColorInput,
  DatePicker,
  DateTimePicker,
  TextArea,
  GroupCheckbox
};

export default {
  name: "parameterComponents",
  components: {
    BIcon,
    BCard,
    BCardBody,
    BCol,
    BRow,
    ...PARAMETER_COMPONENTS,
    BButton,
    DeviceProtocolParametersModal,
    CalculationTableModal,
    BFormCheckbox,
    ValidationProvider,
    BFormInput,
    BFormInvalidFeedback,
    CustomComponents
  },
  // props: ["messages", "message_time", "options"],
  props: {
    parameters: {
      type: Array,
      default: () => {
        return [];
      }
    },
    sensorsData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isEditable: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    protocolName: {
      type: String,
      default: () => {
        return null;
      }
    },
    sensorInfo: {
      type: Object
    },
    selectedSensor: {
      type: String
    },
    device: {
      type: Object
    }
  },
  computed() {
    //  this.$emit("input", this.sensorInfo);
  },

  watch: {
    parameters: {
      handler(val) {
        this.$emit("input", this.sensorInfo);
        val.forEach((parameter) => {
          try {
            this.$nextTick(async () => {
              const a = await this.$refs[parameter.field][0].validate();
              // parameter.isValid = a.valid;
              // if (!a.valid && a.errors && a.errors.length) {
              //   parameter.errors = a.errors[0];
              // }
            });
            this.checkValidation(parameter.field);
          } catch (err) {
            console.log(err);
          }
        });
      },
      deep: true
    }
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      getValidationState,
      MAPPINGS: {
        timestamp: DateTimePicker,
        boolean: Checkbox,
        groupcheckbox: GroupCheckbox,
        multiselect: Selection,

        color: ColorInput,
        input: TextInputs,
        time: TimePicker,
        date: DatePicker,
        textarea: TextArea
      }
    };
  },
  methods: {
    formattedString(filed) {
      return this.formattedString(filed, "_", " ");
    },
    checkTableValue(table) {
      const field = table && table.field;

      const isValid =
        this.sensorInfo &&
        this.sensorInfo.fields &&
        this.sensorInfo.fields[field] &&
        this.sensorInfo.fields[field].table &&
        this.sensorInfo.fields[field].table.length
          ? true
          : false;
      return isValid;
    },
    getValidationRef(index) {
      return `${index}`;
    },
    async checkValidation(index) {},
    isInvalid(flag) {
      this.$emit("isInvalid", flag);
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-info-track {
  text-align: left;
}
.flex-class {
  display: flex;
  flex-wrap: wrap;
  // width: 100%;
}
.mt-10-mb {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}
.flex-class-item {
  display: flex;
  align-items: center;
  width: 50%;
}
.media {
  align-items: center !important;
  padding-top: 10px;
}
.report-time {
  text-align: center;
  width: 100%;
  padding: 13px;
  margin-top: 10px;
  padding-bottom: 0;
  h6 {
    font-style: italic;
    font-size: 10px;
    text-transform: initial;
  }
}
@media only screen and (max-width: 991px) {
  .flex-class-item {
    width: 100%;
    .mt-10-mb {
      margin-top: 0px;
    }
  }
}
</style>
<style lang="scss">
.new-date-picker {
  .flex-class-item {
    position: relative;
  }
  .calendars {
    flex-wrap: nowrap;
  }
  .daterangepicker.openscenter {
    left: 0 !important;
    transform: translate(-0%);
  }
}
@media screen and (max-width: 991px) {
  .row .flex-class-item {
    width: 100%;
  }
}
@media screen and (max-width: 556px) {
  .new-date-picker {
    .calendars {
      flex-wrap: wrap;
    }
    .daterangepicker.openscenter {
      left: 50% !important;
      transform: translate(-50%);
    }
  }
  .daterangepicker.show-calendar {
    .ranges {
      width: 100%;
    }
  }
  .row .flex-class-item {
    width: 100%;
  }
}
.hasErrorCss {
  margin-top: 10px;
}
.sensor-label-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
