<template>
  <div class="dynamic-parameter">
    <b-row
      class="value-row mb-1"
      v-for="(parameter, index) of inputVal"
      :key="index"
    >
      <b-col class="column" cols="12">
        <b-row class="flex-class">
          <b-col sm="3" class="dy-m-40 dy-pr-10">
            <span style="text-transform: capitalize">
              {{
                parameter.label
                  ? parameter.label
                  : parameter.field && formattedString(parameter.field)
              }}
            </span>
          </b-col>
          <b-col sm="5" class="dy-m-27 dy-pr-10">
            <DeviceProtocolParametersModal
              v-model="parameter.value"
              :isViewOnly="!isEditable"
              :protocolName="protocolName"
              :parameter="parameter"
              :sensorField="{
                field: parameter.field,
                data_type: parameter.data_type,
                is_required: false
              }"
            ></DeviceProtocolParametersModal>
          </b-col>
          <b-col sm="1" class="dy-m-27 dy-pr-10">
            <div
              class="sensor-check ml-1"
              v-if="
                parameter.data_type &&
                parameter.data_type.toLowerCase() == 'number'
              "
            >
              <b-form-checkbox
                :checked="
                  parameter.table && parameter.table.length ? true : false
                "
                class="disabled-check-box"
              >
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col sm="3" class="dy-m-10 dy-pr-10 apply-table">
            <div class="calculationTable">
              <CalculationTableModal
                v-model="parameter.table"
                :isViewOnly="!isEditable"
                :sensorField="{
                  field: parameter.field,
                  data_type: parameter.data_type
                }"
                :keyField="parameter.field"
              >
              </CalculationTableModal>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <!-- <b-col class="column" cols="2"
        ><feather-icon
          icon="Trash2Icon"
          color="red"
          v-if="!isEditable"
          class="curserPointer"
          size="22"
          @click="() => removeRow(index)"
      /></b-col> -->
    </b-row>

    <CustomComponentsModal
      @addCustomSensor="addCustomSensor"
      :protocolName="protocolName"
      :sensors="value"
      :sensorsData="sensorsData"
      :sensorInfo="sensorInfo"
    ></CustomComponentsModal>
    <b-row>
      <b-col cols="12">
        <a class="add-new-line" @click="this.addNewRow" v-if="true"
          >+ {{ $t("device.AddCustomSensor") }}</a
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BCardText,
  BForm,
  BInputGroup,
  BButton
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import CustomComponentsModal from "./CustomComponentsModal";
import { BFormCheckbox } from "bootstrap-vue";
import CalculationTableModal from "@/views/DeviceOnboarding/CalculationTableModal.vue";
import DeviceProtocolParametersModal from "@/views/DeviceOnboarding/DeviceProtocolParametersModal";
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BCardText,
    BButton,
    CalculationTableModal,
    DeviceProtocolParametersModal,
    CustomComponentsModal,
    BFormCheckbox
  },
  data() {
    return {
      inputVal: [],
      isIndeterminate: false
    };
  },
  props: ["protocolName", "isEditable", "value", "sensorsData", "sensorInfo"],
  mounted() {
    //   this.inputVal = this.value;
  },
  directives: {
    Ripple
  },
  methods: {
    addCustomSensor(data) {
      this.inputVal.push(data);
      const data1 = JSON.parse(JSON.stringify(this.value));
      data1[data.field] = {
        table: data.table,
        value: data.value,
        label: data.label,
        data_type: data.data_type,
        source: "input"
      };
      this.$emit("input", data1);
    },
    removeRow(index) {
      this.inputVal.splice(index, 1);
      this.$bvModal.hide(index);
      this.$emit("input", this.inputVal);
    },
    addNewRow() {
      this.$bvModal.show("CustomSensors");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
