<template>
  <div class="tab-new-ui">
    <b-row>
      <!-- Per Page -->
      <b-col cols="12" md="12" class="mt-1">
        <feather-icon
          style="margin-bottom: 5px"
          icon="AlertCircleIcon"
        ></feather-icon>
        <span>
          {{ $t("device.ProtocolDescription") }}
        </span>
      </b-col>

      <b-col cols="12" md="4" class="mt-1">
        <div
          class="d-flex align-items-center justify-content-end"
          style="padding-bottom: 15px"
        >
          <b-form-input
            debounce="500"
            class="d-inline-block search-bg"
            v-model="filter"
            :autofocus="true"
            placeholder="Search Parameter"
          />
        </div>
      </b-col>
    </b-row>
    <b-row class="device-protocol">
      <b-col cols="12" sm="12">
        <b-table
          show-empty
          align-v="end"
          :items="telParameters"
          :fields="tableColumns"
          :per-page="perPage"
          :current-page="currentPage"
          :empty-text="$t('NoMatchingRecordsFound')"
        >
          <template #cell(property)="row">
            <b-form-checkbox
              :disabled="isViewOnly"
              class="curserPointer"
              @change="
                (e) => {
                  selectedTelParameters(row.item, e);
                }
              "
              v-model="row.item.selected"
            >
              <span class="link-label">
                {{ row.item.property }}
                <b-badge
                  variant="primary"
                  v-if="!row.item.selected && row.item.isSuggestedWords"
                  >Suggested</b-badge
                ></span
              ></b-form-checkbox
            >
          </template>
          <template #cell(name)="row">
            <h6 class="mb-0">
              {{ `${row.item.name ? `${row.item.name}` : " "}` }}
            </h6>
          </template>
          <template #cell(type)="row">
            <h6 class="mb-0">
              {{ `${row.item.type ? `${row.item.type}` : " "}` }}
            </h6>
          </template>
          <template #cell(min_max_value)="row">
            <h6 class="mb-0">
              {{
                `${
                  row.item.min >= 0 && row.item.min != ""
                    ? row.item.min + "/"
                    : ""
                }${row.item.max >= 0 && row.item.max != "" ? row.item.max : ""}`
              }}
            </h6>
          </template>
          <template #cell(description)="row">
            <h6 class="mb-0">
              <span style="white-space: pre-line">
                {{
                  `${row.item.description ? `${row.item.description}` : " "}`
                }}</span
              >
            </h6>
          </template>
          <template #cell(Actions)="row">
            <div class="text-left min-80" v-if="!isViewOnly">
              <b-button
                pill
                variant="primary"
                @click="selectedTelParameters(row.item, true)"
                >Choose</b-button
              >
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <div class="mx-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          &nbsp;
        </b-col>
        <!-- Pagination -->
      </b-row>
    </div>
    <div class="d-flex justify-content-center">
      <!-- <b-button :disabled="!value" @click="closeModal" variant="primary">{{
        $t("Ok")
      }}</b-button> -->
    </div>
    <div class="d-flex justify-content-end">
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalProtocol"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormCheckbox,
  BTable,
  BSkeletonTable,
  BPagination,
  BButton,
  BBadge
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BSkeletonTable,
    BPagination,
    BBadge
  },
  data() {
    return {
      tableColumns: [
        { key: "property", Class: "", label: this.$t("device.property") },
        { key: "name", tdClass: "", label: this.$t("device.name") },
        { key: "type", Class: "", label: this.$t("device.type") },
        {
          key: "min_max_value",
          tdClass: "",
          label: this.$t("device.min_max_value")
        },
        {
          key: "description",
          tdClass: "text-left",
          thClass: "text-left",
          label: this.$t("device.description")
        },
        { key: "Actions", Class: "", label: this.$t("device.ACTIONS") }
      ],
      telParameters: [],
      oldTelParameters: [],
      perPage: 5,
      currentPage: 1,
      filter: "",
      totalProtocol: 0
    };
  },

  mounted() {
    this.getTelParameters();
  },
  props: [
    "value",
    "protocolParams",
    "protocolName",
    "closeModal",
    "isViewOnly",
    "sensorField",
    "parameter",
    "isPlanParameter",
    "sensorsData"
  ],
  watch: {
    filter() {
      this.filterSensors();
    },
    protocolParams() {
      this.getTelParameters();
    }
  },
  methods: {
    getTelParameters() {
      this.telParameters = this.protocolParams;
      let inputValue = "";
      if (this.value) {
        inputValue = this.value.replace(/\{(.*?)\}/g, (match, key) => {
          return key;
        });
      }
      this.telParameters = this.telParameters.map((e) => {
        if (e.property == inputValue) {
          e.selected = true;
        } else {
          e.selected = false;
        }
        return e;
      });

      let suggestedWords =
        (this.parameter && this.parameter.suggested_keyword) || [];
      suggestedWords = suggestedWords.map((e) => e.toLowerCase());
      // if (!suggestedWords || !suggestedWords.length) {
      //   suggestedWords = this.parameter.label
      //     ? [this.parameter.label]
      //     : this.parameter.name
      //     ? [this.parameter.name]
      //     : [];
      // }
      this.telParameters = this.sortAndPrioritize(
        this.telParameters,
        suggestedWords
      );
      this.oldTelParameters = this.telParameters;

      this.totalProtocol = this.telParameters.length;
      this.telParameters.sort(
        (a, b) => Number(b.selected) - Number(a.selected)
      );
      if (!this.value && this.telParameters && this.telParameters.length) {
        const suggestedWordsLower = suggestedWords.map((word) =>
          word.toLowerCase()
        );
        this.telParameters.map((e) => {
          e.isSuggestedWords = false;
          return e;
        });
        for (let i = 0; i < suggestedWordsLower.length; i++) {
          const element = suggestedWordsLower[i];
          const searchParam = element.trim();
          const search = new RegExp(searchParam, "i"); // prepare a regex object
          if (
            (this.telParameters[0].property &&
              search.test(this.telParameters[0].property)) ||
            (this.telParameters[0].name &&
              search.test(this.telParameters[0].name))
          ) {
            this.telParameters[0].isSuggestedWords = true;
            break;
          }
        }
      }
    },
    sortAndPrioritize(data, suggestedWords) {
      // Convert suggestedWords to lowercase for case-insensitive comparison
      const suggestedWordsLower = suggestedWords.map((word) =>
        word.toLowerCase()
      );

      // Sort the data
      data.sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();

        // Check if names contain any suggested word
        const aContainsSuggested = suggestedWordsLower.some((word) =>
          aName.includes(word)
        );
        const bContainsSuggested = suggestedWordsLower.some((word) =>
          bName.includes(word)
        );

        // If both contain suggested words or both do not, sort alphabetically
        if (aContainsSuggested && bContainsSuggested) {
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        } else if (aContainsSuggested) {
          return -1; // a comes first
        } else if (bContainsSuggested) {
          return 1; // b comes first
        } else {
          return aName < bName ? -1 : aName > bName ? 1 : 0;
        }
      });

      return data;
    },
    filterSensors() {
      if (
        this.filter === "" ||
        !this.filter ||
        (this.filter && !this.filter.trim())
      ) {
        this.telParameters = this.oldTelParameters; // lowecase
        this.totalProtocol = this.telParameters.length;
      } else {
        const searchParam = this.filter.trim();
        const search = new RegExp(searchParam, "i"); // prepare a regex object
        this.telParameters = this.oldTelParameters.filter(
          (item) =>
            search.test(item.property) ||
            search.test(item.name) ||
            search.test(item.type)
        );
        this.totalProtocol = this.telParameters.length;
      }
    },
    selectedTelParameters(data, param) {
      if (this.sensorField.is_required) {
        if (this.isPlanParameter) {
          this.$emit("input", `${data.property}`);
        } else {
          this.$emit("input", `{${data.property}}`);
        }
        this.closeModal();
        setTimeout(() => {
          this.telParameters = [];
          this.getTelParameters();
        }, 100);
      } else {
        this.telParameters = this.telParameters.map((element) => {
          if (element.property === data.property && param) {
            element.selected = true;
            if (this.isPlanParameter) {
              this.$emit("input", `${data.property}`);
            } else {
              this.$emit("input", `{${data.property}}`);
            }
          } else {
            element.selected = false;
            if (element.property === data.property) {
              this.$emit("input", "");
            }
          }
          return element;
        });
        this.oldTelParameters = [...this.telParameters];
        this.closeModal();
      }
      // this.$emit("input", data.property);

      // this.closeModal();
    }
  }
};
</script>
<style lang="scss">
.header-center-align thead tr th,
.header-center-align tbody tr td {
  text-align: center !important;
}
.header-center-align thead tr th.text-left,
.header-center-align tbody tr td.text-left {
  text-align: left !important;
}
.header-center-align thead tr th:first-child,
.header-center-align tbody tr td:first-child,
.header-center-align thead tr th:last-child,
.header-center-align tbody tr td:last-child {
  text-align: left !important;
}
.link-label {
  color: var(--primary);
  cursor: pointer;
}
.device-protocol {
  overflow-x: auto;
  height: calc(100vh - 440px);
}
</style>
