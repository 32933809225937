var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-add-filters","centered":"","no-close-on-esc":"","no-close-on-backdrop":"","size":"sm","modal-class":"no-header-modal ","hide-header":true}},[_c('b-card-text',[_c('div',{staticClass:"new-invite-modal-title"},[_vm._v(" "+_vm._s(_vm.filterInfoData && _vm.filterInfoData.action === "edit" ? _vm.$t("device.filters.EditFilter") : _vm.$t("device.filters.Add Filter"))+" ")]),_c('validation-observer',{ref:"selectForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)},"reset":function($event){$event.preventDefault();return _vm.closePopup($event)}}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('device.filters.SelectAlgorithm') + ' *'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.filters.SelectAlgorithm'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"device-algorithm","name":"SelectAlgorithm","options":_vm.algorithms,"clearable":false,"reduce":function (option) { return option.type; },"label":"label","height":"80"},model:{value:(_vm.filterInfo.type),callback:function ($$v) {_vm.$set(_vm.filterInfo, "type", $$v)},expression:"filterInfo.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('device.filters.SelectSensors') + ' *'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.filters.SelectSensors'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DeviceProtocolParametersModal',{staticClass:"mb-sensors",attrs:{"name":"SelectSensors","isPlanParameter":true,"protocolName":_vm.protocolName,"sensorField":{
                    field: 's',
                    data_type: 'number',
                    is_required: true
                  }},model:{value:(_vm.filterInfo.key),callback:function ($$v) {_vm.$set(_vm.filterInfo, "key", $$v)},expression:"filterInfo.key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),(_vm.filterInfo.type === 'median')?_c('div',{staticClass:"Median-data"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('device.filters.WindowSize') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.filters.WindowSize'),"rules":{
                    required: true,
                    min_value: 1,
                    max_value: 50,
                    multiple_value_one: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"windowSize","type":"number"},model:{value:(_vm.filterInfo.options.window_size),callback:function ($$v) {_vm.$set(_vm.filterInfo.options, "window_size", $$v)},expression:"filterInfo.options.window_size"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('device.filters.ResetTimeout') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.filters.ResetTimeout'),"rules":{
                    required: true,
                    min_value: 10,
                    max_value: 3600,
                    multiple_value_one: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"WindowReset","name":"WindowReset","type":"number"},model:{value:(_vm.filterInfo.options.window_reset),callback:function ($$v) {_vm.$set(_vm.filterInfo.options, "window_reset", $$v)},expression:"filterInfo.options.window_reset"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1):_vm._e(),(_vm.filterInfo.type === 'kalman')?_c('div',{staticClass:"Median-data"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('device.filters.NoiseLevel') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.filters.NoiseLevel'),"rules":{
                    required: true,
                    min_value: 0.0000001,
                    multiple_value_zero_one_up_to_six: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"NoiseLevel","type":"number"},model:{value:(_vm.filterInfo.options.noise_level),callback:function ($$v) {_vm.$set(_vm.filterInfo.options, "noise_level", $$v)},expression:"filterInfo.options.noise_level"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center mb-2"},[_c('b-button',{staticClass:"mt-1 mr-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.filterInfoData && _vm.filterInfoData.action === "edit" ? _vm.$t("device.filters.Update") : _vm.$t("device.filters.Add"))+" ")]),_c('b-button',{staticClass:"mt-1",attrs:{"variant":"outline-secondary","type":"button"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("user.Cancel"))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }