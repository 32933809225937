<template>
  <div>
    <div class="modal-info-track new-date-picker">
      <b-row class="flex-class">
        <b-col
          sm="12"
          v-for="(parameter, key) in parameters"
          :key="key"
          class=""
        >
          <span
            v-if="MAPPINGS[parameter.component] && parameter.source == 'config'"
          >
            <validation-provider
              :name="parameter.field"
              #default="validationContext"
              :rules="{
                required: parameter.is_required
              }"
              :ref="parameter.field"
            >
              <b-row
                v-if="
                  MAPPINGS[parameter.component] && parameter.source == 'config'
                "
                class="flex-class ml-10"
              >
                <b-col style="margin-top: 27px; padding-right: 10px" sm="3">
                  <span style="text-transform: capitalize">
                    {{
                      parameter.label
                        ? parameter.label
                        : parameter.field && formattedString(parameter.field)
                    }}
                    {{ parameter.is_required ? "* :" : " :" }}
                  </span>
                </b-col>
                <b-col sm="8" style="margin-top: 17px">
                  <component
                    :is="MAPPINGS[parameter.component]"
                    v-bind="{
                      ...parameter
                    }"
                    :state="getValidationState(validationContext)"
                    v-model="sensorInfo.params[parameter.field].value"
                    :isEditable="isEditable"
                  >
                  </component>
                </b-col>
              </b-row>

              <!-- <b-row
                v-if="
                  MAPPINGS[parameter.component] && parameter.source == 'config'
                "
                class="flex-class ml-10"
              >
                <b-col style="padding-right: 10px" sm="3"> </b-col>
                <b-col sm="8">
                  <div
                    class="hasErrorCss"
                    v-if="validationContext && validationContext.errors.length"
                  >
                    <small class="text-danger">{{
                      validationContext.errors[0]
                        ? validationContext.errors[0]
                        : ""
                    }}</small>
                  </div>
                </b-col>
              </b-row> -->
            </validation-provider>
          </span>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BIcon,
  BCol,
  BRow,
  BButton,
  BFormCheckbox,
  BFormInput,
  BFormInvalidFeedback
} from "bootstrap-vue";

import TimePicker from "./TimePicker.vue";
import DatePicker from "./DatePicker.vue";
import DateTimePicker from "./DateTimePicker.vue";
import GroupCheckbox from "./GroupCheckbox.vue";
import Checkbox from "./Checkbox.vue";
import Selection from "./Selection.vue";

import TextInputs from "./TextInputs.vue";
import ColorInput from "./ColorInput";
import TextArea from "./TextArea.vue";
import { ValidationProvider } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";

const PARAMETER_COMPONENTS = {
  TimePicker,
  Checkbox,
  Selection,

  TextInputs,
  ColorInput,
  DatePicker,
  DateTimePicker,
  TextArea,
  GroupCheckbox
};

export default {
  name: "parameterComponents",
  components: {
    BIcon,
    BCard,
    BCardBody,
    BCol,
    BRow,
    ...PARAMETER_COMPONENTS,
    BButton,

    BFormCheckbox,
    ValidationProvider,
    BFormInput,
    BFormInvalidFeedback
  },
  // props: ["messages", "message_time", "options"],
  props: {
    parameters: {
      type: Array,
      default: () => {
        return [];
      }
    },

    isEditable: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    protocolName: {
      type: String,
      default: () => {
        return null;
      }
    },
    sensorInfo: {
      type: Object
    }
  },
  computed() {
    //  this.$emit("input", this.sensorInfo);
  },

  watch: {
    parameters: {
      handler(val) {
        this.$emit("input", this.sensorInfo);
        val.forEach((parameter) => {
          try {
            this.$nextTick(async () => {
              const a = await this.$refs[parameter.field][0].validate();
              parameter.isValid = a.valid;
              if (!a.valid && a.errors && a.errors.length) {
                parameter.errors = a.errors[0];
              }
            });
            //  this.checkValidation(parameter.field);
          } catch (err) {
            console.log(err);
          }
        });
      },
      deep: true
    }
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      getValidationState,
      MAPPINGS: {
        timestamp: DateTimePicker,
        boolean: Checkbox,
        groupcheckbox: GroupCheckbox,
        multiselect: Selection,

        color: ColorInput,
        input: TextInputs,
        time: TimePicker,
        date: DatePicker,
        textarea: TextArea
      }
    };
  },
  methods: {
    formattedString(filed) {
      return this.formattedString(filed, "_", " ");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-info-track {
  text-align: left;
}
.flex-class {
  display: flex;
  flex-wrap: wrap;
  // width: 100%;
}
.mt-10-mb {
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}
.flex-class-item {
  display: flex;
  align-items: center;
  width: 50%;
}
.media {
  align-items: center !important;
  padding-top: 10px;
}
.report-time {
  text-align: center;
  width: 100%;
  padding: 13px;
  margin-top: 10px;
  padding-bottom: 0;
  h6 {
    font-style: italic;
    font-size: 10px;
    text-transform: initial;
  }
}
@media only screen and (max-width: 991px) {
  .flex-class-item {
    width: 100%;
    .mt-10-mb {
      margin-top: 0px;
    }
  }
}
</style>
<style lang="scss">
.new-date-picker {
  .flex-class-item {
    position: relative;
  }
  .calendars {
    flex-wrap: nowrap;
  }
  .daterangepicker.openscenter {
    left: 0 !important;
    transform: translate(-0%);
  }
}
@media screen and (max-width: 991px) {
  .row .flex-class-item {
    width: 100%;
  }
}
@media screen and (max-width: 556px) {
  .new-date-picker {
    .calendars {
      flex-wrap: wrap;
    }
    .daterangepicker.openscenter {
      left: 50% !important;
      transform: translate(-50%);
    }
  }
  .daterangepicker.show-calendar {
    .ranges {
      width: 100%;
    }
  }
  .row .flex-class-item {
    width: 100%;
  }
}
.hasErrorCss {
  margin-top: 10px;
}
</style>
