<template>
  <div class="device-connection ready">
    <b-card v-if="!message.message_time" class="connecting mt-36">
      <b-card-title class="heading">
        <!-- <feather-icon class="icon" icon="WifiIcon" size="45" /> -->
        <div class="icon">
          <div class="wifi-symbol">
            <div class="wifi-circle first"></div>
            <div class="wifi-circle second"></div>
            <div class="wifi-circle third"></div>
            <div class="wifi-circle fourth"></div>
          </div>
        </div>
      </b-card-title>
      <b-card-text class="text mt-20">
        <b-col md="12" class="text-protocol" v-if="protocol_info.port">
          <b-row class="d-flex justify-content-between mt-2">
            <b-col md="12" cols="12" class="mb-1">
              <span
                >{{ $t("device.Protocol") }} {{ protocol_info.protocol }}</span
              >
            </b-col>
            <b-col md="12" cols="12" class="mb-1">
              <span
                >{{ $t("device.ServerIP") }} {{ protocol_info.server_ip }}</span
              >
            </b-col>
            <b-col md="12" cols="12" class="mb-1">
              <span>{{ $t("device.Port") }} {{ protocol_info.port }}</span>
            </b-col>
          </b-row>
        </b-col>
        {{ $t("WaitingForDeviceto") }} <br />
        {{ $t("SendMessageToOur") }} <br />
        {{ $t("server") }}
      </b-card-text>
      <b-button
        variant="primary"
        size="md"
        class="mt-30"
        @click="$emit('onCancel')"
        >{{ cancelButtonLabel }}</b-button
      >
    </b-card>
    <b-card v-if="message.message_time" class="connected">
      <b-card-title class="heading">
        <div class="icon">
          <feather-icon class="icon" icon="CheckIcon" size="45" />
        </div>
      </b-card-title>
      <sensorInfo
        :deviceObj="{ id: device_id }"
        :unitObj="{ id: '' }"
        :messages="message.messages"
        :message_time="message.message_time"
        :options="{
          isLiveMode: false
        }"
      ></sensorInfo>

      <b-button
        variant="primary"
        size="md"
        class="back-button"
        @click="$emit('onOk')"
        >{{ okButtonLabel }}</b-button
      >
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BCardTitle,
  BCardText
} from "bootstrap-vue";
import SocketIOService from "@/libs/socket/sockets";
import SensorInfo from "../sensor-views/sensorInfo.vue";
export default {
  name: "DeviceConnectionStatus",
  props: {
    device_id: {
      required: true,
      type: String
    },
    cancelButtonLabel: {
      type: String,
      default: () => {
        return "Cancel";
      }
    },
    okButtonLabel: {
      type: String,
      default: () => {
        return "OK";
      }
    },
    protocol_info: {
      required: true,
      type: Object
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    SensorInfo
  },
  data() {
    return {
      message: {
        messages: null,
        message_time: null
      }
    };
  },

  watch: {
    device_id(newValue, oldValue) {
      if (newValue) {
        this.setupSocket(newValue);
      }
    }
  },
  async created() {
    if (this.device_id) {
      this.setupSocket(this.device_id);
    }
  },

  beforeDestroy() {
    this.closeSocket();
  },
  methods: {
    async setupSocket() {
      this.closeSocket();
      this.socket = new SocketIOService();
      await this.socket.setupSocketConnection();
      this.socket.subscribeEventWithFunction(`DM_${this.device_id}`, (data) => {
        this.message.messages = data.device.data;
        this.message.message_time = data.device.message_time;
      });
    },
    closeSocket() {
      if (this.socket) {
        this.socket.disconnect();
        this.socket = null;
        this.message = null;
      }
    }
  }
};
</script>

<style lang="scss">
.device-connection {
  .connecting.card {
    max-width: 340px;
    margin: 0 auto;
    margin-top: 65px;
    .card-body {
      .card-title {
        margin-top: -65px;
      }
    }
    .mt-20 {
      margin-top: 20px;
    }
    .mt-30 {
      margin-top: 30px;
    }
  }

  .card {
    .card-body {
      min-height: 271px;
      text-align: center;
    }
    .text {
      margin-top: 15px;
      font-size: 20px;
      color: #625f6e;
      width: 100%;
      display: block;
      font-weight: bold;
      .text-protocol {
        margin-top: 15px;
        font-size: 15px;
        color: #625f6e;
        width: 100%;
        display: block;
        font-weight: bold;
      }
    }
    .heading {
      display: block;
      text-align: center;
      margin-top: -75px;
      background: var(--rgb-primary-12);
      padding: 10px;
      border-radius: 50%;
      width: 97px;
      height: 97px;
      position: relative;
      margin: -58px auto auto;
      .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
        right: 0;
      }
    }
    &.connected {
      max-width: 340px;
      margin: 0 auto;
      margin-top: 65px;
      .heading {
        background: var(--primary) !important;
        color: #fff;
        margin-top: -65px;
        svg polyline {
          stroke: var(--white) !important;
        }
      }
    }
  }
  .connected .heading {
    margin-top: 0px;
  }
  .wifi-symbol {
    display: none;
    left: 95px;
    top: 6px;
    $size: 50px;

    [foo],
    & {
      display: block;
      position: absolute;
      display: inline-block;
      width: $size;
      height: $size;
      margin-top: 0 - $size - $size * 0.25;

      -ms-transform: rotate(-45deg) translate(-100px);
      -moz-transform: rotate(-45deg) translate(-100px);
      -o-transform: rotate(-45deg) translate(-100px);
      -webkit-transform: rotate(-45deg) translate(-100px);
      transform: rotate(-45deg) translate(-100px);
    }
    .wifi-circle {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      font-size: $size/7;
      position: absolute;
      bottom: 0;
      left: 0;
      border-color: var(--primary);
      border-style: solid;
      border-width: 1em 1em 0 0;
      -webkit-border-radius: 0 100% 0 0;
      border-radius: 0 100% 0 0;

      opacity: 0;
      -o-animation: wifianimation 3s infinite;
      -moz-animation: wifianimation 3s infinite;
      -webkit-animation: wifianimation 3s infinite;
      animation: wifianimation 3s infinite;

      &.first {
        -o-animation-delay: 800ms;
        -moz-animation-delay: 800ms;
        -webkit-animation-delay: 800ms;
        animation-delay: 800ms;
      }
      &.second {
        width: 5em;
        height: 5em;
        -o-animation-delay: 400ms;
        -moz-animation-delay: 400ms;
        -webkit-animation-delay: 400ms;
        animation-delay: 400ms;
      }
      &.third {
        width: 3em;
        height: 3em;
      }
      &.fourth {
        width: 1em;
        height: 1em;
        opacity: 1;
        background-color: var(--primary);
        -o-animation: none;
        -moz-animation: none;
        -webkit-animation: none;
        animation: none;
      }
    }
  }

  @-o-keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
  @-moz-keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
  @-webkit-keyframes wifianimation {
    0% {
      opacity: 0.4;
    }
    5% {
      opactiy: 1;
    }
    6% {
      opactiy: 0.1;
    }
    100% {
      opactiy: 0.1;
    }
  }
}
.dark-layout .device-connection .card {
  background-color: var(--dark-bg);
}
.ready{
  height: calc(100vh - 288px);
  overflow-y: auto;
  overflow-x: hidden;
}
@media only screen and (max-width: 600px) {
  .ready{
  height: calc(100vh - 248px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
}
</style>
