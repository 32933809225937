<template>
  <div>
    <date-range-picker
      v-model="dateRange"
      @update="updateValues"
      :auto-apply="true"
      :disabled="!isEditable"
      :timePicker="true"
      :ranges="ranges"
      :single-date-picker="true"
      :minDate="isMinDate ? getCurrentTime() : ''"
    >
      <template #input="picker">
        <span v-if="dateRange && dateRange.startDate">
          {{
            `${picker.startDate ? `${DateTimeConvert(picker.startDate)}` : ""}`
          }}
          <!-- {{
          `${picker.endDate ? ` - ${DateTimeConvert(picker.endDate)}` : " "}`
        }} -->
        </span>
        <span v-else class="place-holder-date">{{ $t("unit.SelectDate") }} </span>
      </template>
      <!--    date slot-->
      <template #date="data">
        <span class="small">{{ data.date | dateCell }}</span>
      </template>
      <!--    ranges (new slot syntax) -->
      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="(range, name) in ranges.ranges"
              :key="name"
              @click="ranges.clickRange(range)"
            >
              <b>{{ name }}</b>
            </li>
          </ul>
        </div>
      </template>
      <!--    footer slot-->
    </date-range-picker>
  </div>
</template>

<script>
import constants from "@/utils/constants";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: { DateRangePicker },
  data() {
    return {
      dateRange: {
        startDate: this.getCurrentTime(),
        endDate: this.getCurrentTime()
      },
      ranges: this.getRanges()
    };
  },

  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  props: [
    "name",
    "desc",
    "label",
    "data_type",
    "component",
    "value",
    "isEditable",
    "isRequired",
    "isMinDate"
  ],
  watch: {},
  mounted() {
    if (this.value) {
      this.dateRange = { startDate: this.value, endDate: this.value };
    }
  },
  methods: {
    updateValues(e) {
      const selectedDateRange = { startDate: e.startDate, endDate: e.endDate };
      this.$emit("input", selectedDateRange);
    },
    getRanges() {
      if (this.isMinDate) {
        return false;
      }
      return this.getCustomRanges();
    },

    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    }
  }
};
</script>

<style lang="scss">
.daterangepicker select.hourselect {
  background: transparent !important;
  padding-left: 10px;
}
.daterangepicker select.minuteselect {
  background: transparent !important;
  padding-left: 10px;
}
.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
// .reportrange-text {
//   background: transparent !important;
//   padding: 9px 10px !important;
//   border: 1px solid #d8d6de !important;
//   margin-bottom: 10px;
// }
// .daterangepicker.openscenter {
//   left: 23px !important;
// }
// @media only screen and (max-width: 1024px) {
//   .daterangepicker.openscenter {
//     left: 50% !important;
//     .calendars {
//       overflow-y: auto;
//       max-height: 40vh;
//     }
//   }
//   .daterangepicker .drp-selected {
//     font-size: 9px;
//   }
// }
</style>
