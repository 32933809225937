<template>
  <div
    class="under-line-title"
    :class="
      ['edit-device', 'create-device', 'view-device'].includes($route.name)
        ? $route.name === 'edit-device'
          ? 'FuelTheft-w'
          : $route.name === 'create-device'
          ? 'FuelTheft-s'
          : 'FuelTheft-v'
        : 'FuelTheft-h-w'
    "
  >
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h4 class="card-title mb-0">
          {{ $t("device.filters.GpsDataFilter") }}
        </h4>
      </div>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="
          (e) => {
            $router.push({ name: 'devices-list' });
          }
        "
        class="btn set-back-mobile v2-back"
      >
        <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
      </b-button>
    </div>
    <div class="filter-scroll mt-2">
      <validation-observer ref="GPSForm">
        <b-form @submit.prevent="saveFilterData">
          <b-row>
            <b-col sm="12" lg="4" style="height: 60px" class="mb-1">
              <b-form-group>
                <b-form-checkbox
                  v-model="filters.sensor[0].enable"
                  style="top: 30px"
                  :disabled="isViewOnly"
                  @change="toggleGPSData"
                  >{{ $t("device.filters.EnableGpsDataFilter") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="12" lg="4" style="height: 60px" class="mb-1">
              <b-form-group>
                <b-form-checkbox
                  v-model="filters.sensor[0].options.skip_invalid_coords"
                  style="top: 30px"
                  :disabled="isViewOnly || !filters.sensor[0].enable"
                  >{{ $t("device.filters.SkipInvalidCoordinates") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="4" sm="12">
              <b-form-group :label="$t('device.filters.MaxSpeed') + ' *'">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('device.filters.MaxSpeed')"
                  vid="MaxSpeed"
                  :rules="{
                    required: true,
                    min_value: 1,
                    multiple_value_one: true,
                    max_value: 9999
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      type="number"
                      id="MaxSpeed"
                      :disabled="isViewOnly || !filters.sensor[0].enable"
                      v-model="filters.sensor[0].options.max_speed"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="Max Speed"
                      :placeholder="$t('MaxSpeed')"
                    />
                  </b-input-group>
                  <br />
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group :label="$t('device.filters.MaxHDOP') + ' *'">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('device.filters.MaxHDOP')"
                  vid="MaxHODP"
                  :rules="{
                    required: true,
                    min_value: 1,
                    multiple_value_one: true,
                    max_value: 9999
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      type="number"
                      id="MaxHODP"
                      :disabled="isViewOnly || !filters.sensor[0].enable"
                      v-model="filters.sensor[0].options.max_hdop"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="Max HODP"
                      :placeholder="$t('MaxHODP')"
                    />
                  </b-input-group>
                  <br />
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col lg="4" sm="12">
              <b-form-group :label="$t('device.filters.MinSatellites') + ' *'">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('device.filters.MinSatellites')"
                  vid="min_threshold"
                  :rules="{
                    required: true,
                    min_value: 1,
                    max_value: 255,
                    multiple_value_one: true
                  }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      type="number"
                      id="min_threshold"
                      :disabled="isViewOnly || !filters.sensor[0].enable"
                      v-model="filters.sensor[0].options.min_s_count"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="name"
                      :placeholder="$t('MinSatellites')"
                    />
                  </b-input-group>
                  <br />
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" sm="12">
              <div class="vr mb-1"></div>
            </b-col>
          </b-row>
          <!-- <b-button
            type="button"
            variant="primary"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="invalid"
            class="mt-2 mr-1"
            v-if="$route.name === 'edit-device'"
            @click="save()"
          >
            {{ $t("profile.SaveChanges") }}
          </b-button> -->
        </b-form>
      </validation-observer>
      <b-row class="mt-1 filter-row">
        <b-col class="filter-col-1">
          <div class="d-flex justify-content-start align-items-center">
            <h4 class="card-title mb-0">
              {{ $t("device.filters.filters") }}
            </h4>
          </div>
        </b-col>
        <b-col class="filter-col-2">
          <div class="search-sensor">
            <div class="d-flex align-items-center justify-content-end">
              <div class="d-flex align-items-center desktop-hidden-search">
                <label class="search-label d-none d-sm-inline">{{
                  $t("device.search")
                }}</label>
                <b-input-group class="">
                  <b-form-input
                    v-model="filter"
                    class="d-inline-block search-bg"
                    :placeholder="$t('device.searchPlaceholder')"
                  ></b-form-input>
                  <b-input-group-append
                    class="input-prefix-img-filter search-clear-filter"
                    ><feather-icon
                      icon="XIcon"
                      size="24"
                      @click="
                        (e) => {
                          filter = '';
                        }
                      "
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>

              <b-button
                @click="addFilters()"
                variant="primary"
                class="ml-10 mr-1"
                v-if="!isViewOnly"
              >
                <feather-icon icon="PlusIcon" size="12" />
                <span class="d-none d-sm-inline">{{
                  $t("device.filters.AddFilters")
                }}</span>
              </b-button>
              <AddDeviceFilters
                :addFilterData="addFilterData"
                :protocolName="protocolName"
                :filterInfoData="selectedFilterInfo"
                :items="filters.pre"
              ></AddDeviceFilters>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mt-1">
        <b-table
          :items="filters.pre"
          striped
          hover
          :fields="tableColumns"
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          show-empty
        >
          <template #cell(type)="row"
            ><div>{{ row.item.type }}</div></template
          >
          <template #cell(key)="row"
            ><div>{{ row.item.key }}</div></template
          >
          <template #cell(options)="row">
            <ul class="mb-0 pl-1">
              <li v-for="(value, key) in row.item.options" :key="key">
                <span v-if="key === 'window_reset'"
                  >{{ handleError(key) }} : {{ `${value}(${$t("Sec")})` }}
                </span>
                <span v-else>{{ handleError(key) }} : {{ value }}</span>
              </li>
            </ul>
          </template>
          <template #cell(action)="row">
            <div class="text-left d-flex list-icon">
              <span v-if="!isViewOnly">
                <div @click="editItem(row.item)">
                  <feather-icon
                    icon="EditIcon"
                    class="text-primary action-icon"
                    size="18"
                    style="position: relative; cursor: pointer"
                  ></feather-icon>
                </div>
              </span>
              <span v-if="!isViewOnly">
                <feather-icon
                  icon="Trash2Icon"
                  class="text-danger action-icon"
                  size="18"
                  style="position: relative; cursor: pointer"
                  @click="deleteItem(row.item)"
                />
              </span>
            </div>
          </template>
        </b-table>
        <div class="mx-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              &nbsp;
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-if="totalFilters > 0"
                v-model="currentPage"
                :total-rows="totalFilters"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                :no-paging-nav="false"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-end"
      v-if="$route.name === 'edit-device'"
    >
      <b-button
        @click="saveFilterData"
        type="button"
        variant="primary"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :disabled="isDisabled"
        class="mt-2 mr-1"
      >
        {{ $t("profile.SaveChanges") }}
      </b-button>
    </div>
    <Loader :show="show" />
    <AreYouSureModal
      :data="{
        ...removeData,
        id: removeData.key,
        modelName: 'modal-sm-remove-filter-sensor',
        name: removeData.key
          ? removeData.key.replace('{', '').replace('}', '')
          : ''
      }"
      :onClose="onClose"
      :removedUser="removedUser"
    ></AreYouSureModal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BModal,
  BInputGroupAppend,
  BTable,
  BPagination
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max_value, min_value } from "@validations";
import constants from "@/utils/constants";
import Loader from "@/layouts/components/Loader.vue";
import AddDeviceFilters from "./AddDeviceFilters.vue";
import DeviceService from "@/libs/api/device-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import Search from "../Common/search.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BModal,
    BTable,
    BCard,
    BInputGroup,
    BButton,
    Loader,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormInvalidFeedback,
    BFormCheckbox,
    Search,
    AddDeviceFilters,
    BPagination,
    AreYouSureModal
  },
  data() {
    return {
      required,
      max_value,
      min_value,
      isDisabled: false,
      EnableGPSDataFilter: true,
      filters: {
        pre: [],
        sensor: [
          {
            key: "GPS",
            type: "GPS",
            enable: true,
            options: {
              max_speed: 150,
              max_hdop: 10,
              min_s_count: 4,
              skip_invalid_coords: true
            }
          }
        ]
      },
      defaultFilters: {
        pre: [],
        sensor: [
          {
            key: "GPS",
            type: "GPS",
            enable: true,
            options: {
              max_speed: 150,
              max_hdop: 10,
              min_s_count: 4,
              skip_invalid_coords: true
            }
          }
        ]
      },
      show: false,
      searchFiled: {},
      oldItems: [],
      oldOptions: {},
      operator: "ilike",
      filter: "",
      selectedFilterInfo: {
        key: "",
        type: "median",
        options: {
          window_size: "",
          window_reset: "",
          noise_level: ""
        }
      },
      showCol: false,
      searchColumn: "",
      currentPage: 1,
      perPage: 5,
      removeData: {},
      totalFilters: 0,
      //  tableColumns: ['key', 'type', 'window_size', 'window_reset', 'action'],
      tableColumns: [
        {
          key: "type",
          label: this.$t("device.filters.AlgorithmName"),
          tdClass: " label-w-a ",
          thClass: " label-w-a "
        },
        {
          key: "key",
          label: this.$t("device.filters.Sensors"),
          tdClass: " label-w-a ",
          thClass: " label-w-a "
        },
        {
          key: "options",
          label: this.$t("device.filters.Parameters"),
          tdClass: " label-w-a ",
          thClass: " label-w-a "
        },
        // {
        //   key: "window_reset",
        //   label: "Parameters"
        // },
        {
          key: "action",
          label: this.$t("device.filters.Action"),
          tdClass: " label-w-a ",
          thClass: " label-w-a "
        }
      ],
      items: []
      // items: [
      //     { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
      //     { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
      //     { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
      //     { age: 38, first_name: 'Jami', last_name: 'Carney' }
      //   ]
    };
  },
  directives: {
    Ripple
  },
  mounted() {
    if (this.device && this.device.id && this.device.uniqueIdentifier) {
      this.getFiltersData();
    } else {
      if (this.device && this.device.sensors) {
        const gpsSensors = this.device.sensors.find(
          (e) => e.type.toLowerCase() === "gps"
        );
        if (gpsSensors && gpsSensors.type) {
          this.filters.sensor[0].key = gpsSensors.name;
        }
      }
    }
  },
  computed() {},
  props: [
    "deviceData",
    "isViewOnly",
    "FuelTheftPropFun",
    "saveFuelTheft",
    "protocolName",
    "saveFiltersData",
    "device",
    "saveFilter",
    "activeTab",
    "filtersData",
    "activeTabTitle"
  ],
  watch: {
    filters: {
      deep: true,
      async handler(newOptions) {
        await this.validationFormFilter();
        this.saveFiltersData(newOptions);
        this.totalFilters =
          (this.filters && this.filters.pre && this.filters.pre.length) || 0;
      }
    },
    filter() {
      this.filterSensors();
    },
    activeTab() {
      if (this.$t("device.filters.filters") === this.activeTabTitle) {
        this.getFiltersData();
      }
      if (
        (this.$route.name === "edit-device" ||
          this.$route.name === "view-device") &&
        [4, "4"].includes(this.activeTab)
      ) {
        this.getFiltersData();
      }
      // this.filters.pre = JSON.parse(JSON.stringify(this.oldItems));
      // if (this.filters.sensor && this.filters.sensor.length) {
      //   this.filters.sensor[0].options = JSON.parse(
      //     JSON.stringify(this.oldOptions)
      //   );
      // }
    }
  },
  methods: {
    onClose() {
      this.$bvModal.hide("modal-sm-remove-filter-sensor");
    },
    async removedUser(item) {
      try {
        this.filters.pre = this.filters.pre.filter((e) => e.key !== item.id);
        this.$bvModal.hide("modal-sm-remove-filter-sensor");
      } catch (err) {}
    },
    handleError(error) {
      return this.formatUnderscore(error);
    },
    toggleGPSData() {},
    addFilters() {
      const Data = JSON.parse(JSON.stringify(this.selectedFilterInfo));
      this.selectedFilterInfo = {
        key: "",
        type: "median",
        options: {
          window_size: "",
          window_reset: "",
          noise_level: ""
        }
      };
      Data.action = "add";
      this.selectedFilterInfo = JSON.parse(JSON.stringify(Data));
      this.$bvModal.show("modal-add-filters");
    },
    addFilterData(props) {
      const alreadyAdded = this.filters.pre.find((e) => e.key == props.key);
      if (alreadyAdded && alreadyAdded.key) {
        this.filters.pre = this.filters.pre.map((element) => {
          if (element.key === props.key) {
            element = props;
          }
          return element;
        });
      } else {
        this.filters.pre.push(props);
      }
      setTimeout(() => {
        this.oldItems = this.filters.pre;
      }, 100);
    },
    redirectList() {},

    save() {
      this.filterInfo.push({
        key: this.filterInfo.key,
        type: this.filterInfo.type,
        window_size: parseFloat(this.filterInfo.options.window_size),
        window_reset: parseFloat(this.filterInfo.options.window_reset),
        noise_level: parseFloat(this.filterInfo.options.noise_level)
      });
    },
    editItem(item) {
      this.selectedFilterInfo = item;
      this.selectedFilterInfo.action = "edit";
      this.$bvModal.show("modal-add-filters");
    },
    deleteItem(item) {
      this.removeData = item;
      this.$bvModal.show("modal-sm-remove-filter-sensor");
    },
    filterSensors() {
      if (
        this.filter === "" ||
        !this.filter ||
        (this.filter && !this.filter.trim())
      ) {
        this.filters.pre = this.oldItems; // lowecase
      } else {
        const searchParam = this.filter.trim();
        const search = new RegExp(searchParam, "i"); // prepare a regex object
        this.filters.pre = this.oldItems.filter(
          (item) => search.test(item.key) || search.test(item.type)
        );
      }
      // if (this.removedFilterParams && this.removedFilterParams.length) {
      //   this.filters.pre =  this.filters.pre.filter(
      //     (e) => !this.removedFilterParams.includes(e.name)
      //   );
      // }
    },
    saveFilterData() {
      this.oldItems = JSON.parse(JSON.stringify(this.filters.pre));
      if (this.filters && this.filters.sensor && this.filters.sensor.length) {
        this.oldOptions = JSON.parse(
          JSON.stringify(this.filters.sensor[0].options)
        );
      }
      this.saveFilter();
    },
    async validationFormFilter() {
      const me = this;
      return new Promise((resolve, reject) => {
        this.$refs.GPSForm.validate().then((success) => {
          if (success) {
            me.isDisabled = false;
            resolve(true);
          } else {
            me.isDisabled = true;
            reject();
          }
        });
      });
    },

    async getFiltersData() {
      try {
        this.show = true;
        let response = await new DeviceService().getFilters({
          id: this.device.id,
          identifier: this.device.uniqueIdentifier || this.device.identifier
        });

        if (response && response.data) {
          this.filters =
            response.data.filters && Object.keys(response.data.filters).length
              ? response.data.filters
              : this.defaultFilters;
          this.oldItems = this.filters.pre;
          if (
            this.filters &&
            this.filters.sensor &&
            this.filters.sensor.length
          ) {
            this.oldOptions = JSON.parse(
              JSON.stringify(this.filters.sensor[0].options)
            );
          }
          this.show = false;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@media only screen and (max-width: 600px) {
  .FuelTheft-w,
  .FuelTheft-h-w {
    padding: 10px !important;
  }
}

.vr {
  width: 100%;
  height: 1.5px;
  background-color: #c8bebe;
  margin-top: 10px;
}

.mobile-search-ui {
  width: 200px !important;
}
.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.filter-title {
  width: 30%;
}
.input-prefix-img-filter {
  border: 1px solid var(--gray4);
  height: 36.5px;
  width: 40px;
  border-radius: 0px 7px 7px 0px;
  background: #d8d6de;
  text-align: center;
  svg {
    height: 32px;
    margin: auto;
    margin-left: auto;
  }
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 5px;
    }
  }
}
.search-clear-filter {
  padding: 0px 5px 0px 5px;
}
</style>
<style lang="scss">
.label-w-a {
  max-width: 170px;
  width: 170px;
  min-width: 170px;
  text-align: left !important;
}
.FuelTheft-h-w {
  /* .filter-scroll {
    height: calc(100vh - 330px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  } */
}
.filter-scroll {
  height: calc(100vh - 325px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.FuelTheft-w,
.FuelTheft-h-w .card-body {
  padding: 0 !important;
}
.FuelTheft-h-w {
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 667px) {
  .filter-row {
    flex-wrap: wrap;
    .filter-col-1 {
      min-width: 100%;
      margin-bottom: 10px;
    }
    .filter-col-2 {
      min-width: 100%;
      .search-sensor {
        .justify-content-end {
          justify-content: flex-start !important;
        }
      }
    }
  }
}
</style>
