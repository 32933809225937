var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"play-back-card",staticStyle:{"width":"100%"}},[_c('BottomArrow',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",value:({
      title: _vm.isOnlyPlayBack
        ? _vm.$t('Playback.Expanded')
        : _vm.$t('Playback.Collapsed'),
      customClass: 'tooltip-card'
    }),expression:"{\n      title: isOnlyPlayBack\n        ? $t('Playback.Expanded')\n        : $t('Playback.Collapsed'),\n      customClass: 'tooltip-card'\n    }",modifiers:{"hover":true,"v-primary":true}}],class:_vm.islarge ? 'large-card bottom-arrow-expand' : 'bottom-arrow-expand',on:{"click":function (e) {
        _vm.startPlayback(false);
        _vm.islarge = !_vm.islarge;
      }}}),_c('div',{staticClass:"slider-row"},[_c('div',{class:_vm.UnitInfo && _vm.UnitInfo.isLive ? 'col-10-space' : 'col-12-space'},[_c('vue-slider',{attrs:{"duration":0,"tooltip-formatter":function (v) {
            return new Date(v * 1000).toLocaleString();
          },"min":_vm.minTime,"max":_vm.maxTime},on:{"change":_vm.handleSliderSlide},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),(_vm.UnitInfo && _vm.UnitInfo.isLive)?_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",value:({
        title: _vm.isLiveUnit ? _vm.$t('Playback.Live') : _vm.$t('Playback.Recorded'),
        customClass: 'tooltip-card'
      }),expression:"{\n        title: isLiveUnit ? $t('Playback.Live') : $t('Playback.Recorded'),\n        customClass: 'tooltip-card'\n      }",modifiers:{"hover":true,"v-primary":true}}],staticClass:"col-2-space"},[_c('b-form-checkbox',{attrs:{"switch":"","variant":"danger"},on:{"change":function($event){return _vm.liveToggle()}},model:{value:(_vm.isLiveUnit),callback:function ($$v) {_vm.isLiveUnit=$$v},expression:"isLiveUnit"}})],1):_vm._e()]),_c('div',{staticClass:"play-back-control"},[_c('div',{staticClass:"play-back-left"},[(!_vm.isPaused)?_c('div',{staticClass:"ctr-1"},[_c('PauseIcon',{staticClass:"curserPointer",on:{"click":function (e) {
              _vm.isPaused = !_vm.isPaused;
              _vm.pauseTrack();
            }}})],1):_vm._e(),(_vm.isPaused)?_c('div',{staticClass:"ctr-1"},[_c('PlayIcon',{staticClass:"curserPointer",on:{"click":function (e) {
              _vm.isPaused = !_vm.isPaused;
              _vm.playTrack();
            }}})],1):_vm._e(),_c('div',{staticClass:"ctr-1"},[_c('StopIcon',{staticClass:"curserPointer",on:{"click":function (e) {
              _vm.isPaused = true;

              _vm.stopTrack();
            }}})],1),_c('div',{staticClass:"date-ctr"},[_vm._v(" "+_vm._s(_vm.DateTimeConvert(this.value * 1000, _vm.constants.DATE_TIME_FORMAT))+" ")])]),_c('div',{staticClass:"avg-ctr"},[_c('AvgMeterIcon'),_vm._v(" "+_vm._s(_vm.playTrackData.speed)+" km/h ")],1),_c('div',{staticClass:"loca-ctr"},[_c('LocationIcon'),_vm._v(" "+_vm._s(_vm.playTrackData.distance.toFixed(1))+" km ")],1),_c('div',{staticClass:"get-Speed"},[_c('v-select',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary.bottom",value:({
          title: _vm.$t('Playback.SpeedPlayBack'),
          customClass: 'tooltip-card'
        }),expression:"{\n          title: $t('Playback.SpeedPlayBack'),\n          customClass: 'tooltip-card'\n        }",modifiers:{"hover":true,"v-primary":true,"bottom":true}}],staticClass:"curserPointer no-border no-underline",attrs:{"options":_vm.getSpeed(),"reduce":function (option) { return option.value; },"label":"label","clearable":false,"searchable":false,"menu-props":{
          positionY: 'top',
          offsetY: true
        }},on:{"input":_vm.changeSpeed},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
        var label = ref.label;
return [_c('PlaySpeedIcon'),_c('span',{staticClass:"ml-50 text-body"},[_vm._v(_vm._s(label))])]}}]),model:{value:(_vm.playSpeed),callback:function ($$v) {_vm.playSpeed=$$v},expression:"playSpeed"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }