<template>
  <div>
    <span class="check-outer-dynamic" v-if="multi_select">
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="inputVal"
        :options="component_value"
        :disabled="!isEditable"
        @input="updateInputState"
        name="flavour-1"
      ></b-form-checkbox-group>
    </span>
    <span class="check-outer-dynamic" v-if="!multi_select">
      <b-form-radio-group
        id="checkbox-group-1"
        v-model="inputVal"
        :options="component_value"
        :disabled="!isEditable"
        @input="updateInputState"
        name="flavour-1"
      ></b-form-radio-group
    ></span>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { BFormGroup, BFormCheckboxGroup, BFormRadioGroup } from "bootstrap-vue";
export default {
  props: [
    "name",
    "desc",
    "label",
    "component_value",
    "data_type",
    "multi_select",
    "component",
    "value",
    "isEditable",
    "isRequired"
  ],
  components: { BFormGroup, BFormCheckboxGroup, BFormRadioGroup },
  data() {
    return {
      inputVal: this.multi_select ? [] : "",
      isIndeterminate: false
    };
  },

  mounted() {
    if (this.multi_select) {
      this.inputVal = this.value || [];
    } else {
      this.inputVal = this.value || "";
    }
  },
  methods: {
    updateInputState() {
      this.$emit("input", this.inputVal);
    }
  }
};
</script>
<style lang="scss">
.check-outer-dynamic {
  .custom-checkbox {
    margin-bottom: 10px;
  }
  .custom-checkbox:last-child {
    margin-bottom: 0px;
  }
}
</style>
