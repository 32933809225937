<template>
  <div>
    <b-container class="calculation-table">
      <h3 class="text-center">{{ $t("device.SensorTable") }}</h3>
      <br />
      <b-tabs pills>
        <b-tab
          :title="$t('device.XYPair')"
          @click="activeTab('XYPair')"
          :active="selected_tab === 'RangeTable'"
        >
          <b-row v-if="selected_tab === 'XYPair'">
            <b-col class="table-section" cols="6">
              <b-container class="no-padding-0">
                <b-row class="calculation-table-title-row">
                  <b-col cols="12"
                    ><h3
                      class="mobile-calculation-table"
                      style="text-align: right"
                    >
                      <span
                        class="text-primary"
                        v-b-tooltip.html.top="{
                          title: `<div id='help_popup_text' style='text-align: left'><span dir='auto'><b>Y = aX + b</b><br>${$t(
                            'device.where'
                          )}<br><b>X</b> ${$t(
                            'device.sensorInfo1'
                          )},<br><b>a</b> ${$t(
                            'device.sensorInfo2'
                          )},<br><b>b</b> ${$t(
                            'device.sensorInfo3'
                          )}.</span></div>`,
                          customClass: 'text-align-left'
                        }"
                      >
                        <feather-icon icon="InfoIcon" id="info" size="18" />
                      </span></h3
                  ></b-col>
                </b-row>
              </b-container>
              <b-container class="no-pd-mobbile">
                <b-row class="header-row">
                  <b-col class="column col-width-1"
                    ><b-form-input readonly value="X"
                  /></b-col>
                  <b-col class="column col-width-1"
                    ><b-form-input readonly value="A"
                  /></b-col>
                  <b-col class="column col-width-1"
                    ><b-form-input readonly value="B"
                  /></b-col>
                  <b-col
                    class="column align-items-center d-flex pl-1 mobile-delete-button"
                    cols="1"
                    ><span v-b-tooltip.hover.top="$t('tooTip.deleteAll')"
                      ><feather-icon
                        icon="Trash2Icon"
                        color="red"
                        v-if="!isViewOnly"
                        :class="
                          !tableInput.table.length
                            ? 'disabled'
                            : 'curserPointer'
                        "
                        size="22"
                        @click="() => removeAllRangesRecord()" /></span
                  ></b-col>
                </b-row>
              </b-container>

              <b-container class="table-rows np-padding-row">
                <b-row
                  class="value-row"
                  v-for="(range, index) of tableInput.table"
                  :key="index"
                >
                  <b-col class="column col-width-1"
                    ><b-form-input
                      type="number"
                      :disabled="isViewOnly"
                      autofocus
                      v-model="range.x"
                  /></b-col>
                  <b-col class="column col-width-1"
                    ><b-form-input
                      type="number"
                      v-model="range.a"
                      :disabled="isViewOnly"
                  /></b-col>
                  <b-col class="column col-width-1"
                    ><b-form-input
                      type="number"
                      v-model="range.b"
                      :disabled="isViewOnly"
                  /></b-col>
                  <b-col
                    class="column align-items-center d-flex pl-1 mobile-delete-button"
                    cols="1"
                    ><feather-icon
                      icon="Trash2Icon"
                      color="red"
                      v-if="!isViewOnly"
                      class="curserPointer"
                      size="22"
                      v-b-tooltip.hover.top="$t('tooTip.delete')"
                      @click="() => removeRangeRow(index)"
                  /></b-col>
                </b-row>
              </b-container>
              <b-row>
                <b-col cols="12">
                  <b-link
                    class="nav-link"
                    style="margin-top: 2px"
                    @click="this.addNewRangeRow"
                    v-if="!isViewOnly"
                    >{{ $t("device.AddPair") }}</b-link
                  >
                </b-col>
              </b-row>
            </b-col>
            <b-col class="table-section" cols="6">
              <b-container class="no-padding-0">
                <b-row class="calculation-table-title-row">
                  <b-col cols="">
                    <b-form-checkbox
                      v-model="tableInput.table_info.is_xy_pair"
                      name="is-menu-visible"
                      class="mr-0"
                      inline
                    >
                      {{ $t("XY Pairs") }}
                    </b-form-checkbox>
                  </b-col>
                  <b-col cols="2" se7lf-align="right" class="active">
                    <b-button
                      variant="outline-light"
                      size="sm"
                      class="custom-upload-button mobile-calculation-table"
                      :disabled="
                        isViewOnly || !tableInput.table_info.xy_pair.length
                      "
                      v-b-tooltip.hover.top="$t('device.Download CSV')"
                      @click="() => downloadCsv()"
                    >
                      <DownloadDark class="btn-active" />
                    </b-button>
                  </b-col>
                  <b-col cols="2" self-align="right" class="active">
                    <b-button
                      variant="outline-light"
                      size="sm"
                      :disabled="isViewOnly"
                      v-b-tooltip.hover.top="$t('device.Upload CSV')"
                      class="btn-active custom-upload-button"
                    >
                      <UploadDark />
                      <b-form-file
                        class="custom-file-input"
                        v-if="!isViewOnly"
                        v-model="inputCsvFile"
                        @input="() => importCsv()"
                      />
                    </b-button>
                  </b-col>
                  <b-col cols="1" self-align="right" class="active">
                    <span
                      class="text-primary"
                      v-b-tooltip.html.top="{
                        title: `<div id='help_popup_text' style='text-align: left'> ${$t(
                          'device.DeviceSensorInfo1'
                        )} <br/> ${$t('device.DeviceSensorInfo2')}<br/> ${$t(
                          'device.DeviceSensorInfo3'
                        )}</div>`,
                        customClass: 'text-align-left'
                      }"
                    >
                      <feather-icon
                        icon="InfoIcon"
                        id="info"
                        size="18"
                      /> </span
                  ></b-col>
                </b-row>
              </b-container>
              <b-container class="no-pd-mobbile">
                <b-row class="header-row">
                  <b-col class="column col-width"
                    ><b-form-input readonly value="X"
                  /></b-col>
                  <b-col class="column col-width"
                    ><b-form-input readonly value="Y"
                  /></b-col>
                  <b-col
                    class="column align-items-center d-flex pl-1 mobile-delete-button"
                    cols="1"
                  >
                    <span v-b-tooltip.hover.top="$t('tooTip.deleteAll')">
                      <feather-icon
                        icon="Trash2Icon"
                        color="red"
                        v-if="!isViewOnly"
                        size="22"
                        :class="
                          !tableInput.table_info.xy_pair.length ||
                          !tableInput.table_info.is_xy_pair ||
                          isViewOnly
                            ? 'disabled'
                            : 'curserPointer'
                        "
                        @click="() => removeAllRecord()" /></span
                  ></b-col>
                </b-row>
              </b-container>
              <b-container class="table-rows np-padding-row">
                <b-row
                  class="value-row"
                  v-for="(row, index) of tableInput.table_info.xy_pair"
                  :key="index"
                >
                  <b-col class="column col-width"
                    ><b-form-input
                      type="number"
                      :disabled="
                        !tableInput.table_info.is_xy_pair || isViewOnly
                      "
                      autofocus
                      v-model="row.x"
                      :state="row.x === '' ? null : !row.invalid"
                      v-b-tooltip.hover.top="
                        (row.invalid_reason_x && row.invalid_reason_x) || ''
                      "
                  /></b-col>
                  <b-col class="column col-width"
                    ><b-form-input
                      type="number"
                      v-model="row.y"
                      :disabled="
                        !tableInput.table_info.is_xy_pair || isViewOnly
                      "
                      :state="row.y === '' ? null : !row.invalid"
                      v-b-tooltip.hover.top="
                        (row.invalid_reason_y && row.invalid_reason_y) || ''
                      "
                  /></b-col>
                  <b-col
                    class="column align-items-center d-flex pl-1 mobile-delete-button"
                    cols="1"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      color="red"
                      :class="
                        !tableInput.table_info.is_xy_pair || isViewOnly
                          ? 'disabled'
                          : ''
                      "
                      v-if="!isViewOnly"
                      class="curserPointer"
                      size="22"
                      v-b-tooltip.hover.top="$t('tooTip.delete')"
                      @click="() => removeRow(index)"
                    />
                  </b-col>
                </b-row>
              </b-container>
              <b-row>
                <b-col cols="6">
                  <b-link
                    class="nav-link"
                    style="margin-top: -2px"
                    @click="this.addNewRow"
                    :disabled="!tableInput.table_info.is_xy_pair"
                    v-if="!isViewOnly"
                    >{{ $t("device.AddPair") }}</b-link
                  >
                </b-col>
                <b-col cols="6">
                  <b-button
                    class="mb-1"
                    :disabled="
                      !tableInput.table_info.is_xy_pair ||
                      !isValidXY ||
                      !tableInput.table_info.xy_pair.length
                    "
                    @click="
                      generateCalculationTable(tableInput.table_info.xy_pair)
                    "
                    variant="primary"
                    v-if="!isViewOnly"
                    >{{ $t("device.generate") }}</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
            <b-container class="bound-limits-row">
              <b-row>
                <b-col>
                  <div style="color: red">{{ err }}</div>
                </b-col></b-row
              ></b-container
            >

            <b-container class="bound-limits-row">
              <b-row>
                <b-col cols="1">
                  <div class="mt-2" style="text-align: center">
                    <div class="text-primary">
                      <feather-icon
                        v-b-tooltip.html.top="{
                          title: `<div style='text-align: left ;max-width:250px'>The lower and upper bounds can be used   to set a range of acceptable  values. If a value is out of range, it is considered invalid.These limits can be applied either to raw values (before calculation)<br> or to processed values (after calculation).</div>`
                        }"
                        icon="InfoIcon"
                        id="info"
                        size="18"
                      />
                    </div></div
                ></b-col>
                <b-col cols="4"
                  ><b-form-group
                    :label="$t('device.LowerBound')"
                    label-for="lower-bound-input"
                    description=""
                  >
                    <b-input-group>
                      <b-form-input
                        id="lower-bound-input"
                        v-model="tableInput.table_info.lower"
                        type="number"
                        :placeholder="$t('device.LowerBound')"
                        :disabled="isViewOnly"
                      ></b-form-input>
                      <b-input-group-append
                        class="input-prefix-img-filter search-clear-filter"
                        ><feather-icon
                          icon="XIcon"
                          size="24"
                          @click="
                            (e) => {
                              tableInput.table_info.lower = null;
                            }
                          "
                        />
                      </b-input-group-append> </b-input-group
                  ></b-form-group>
                </b-col>
                <b-col cols="4"
                  ><b-form-group
                    :label="$t('device.UpperBound')"
                    label-for="upper-bound-input"
                    description=""
                    ><b-input-group>
                      <b-form-input
                        id="upper-bound-input"
                        v-model="tableInput.table_info.upper"
                        type="number"
                        :placeholder="$t('device.UpperBound')"
                        :disabled="isViewOnly"
                      ></b-form-input>
                      <b-input-group-append
                        class="input-prefix-img-filter search-clear-filter"
                        ><feather-icon
                          icon="XIcon"
                          size="24"
                          @click="
                            (e) => {
                              tableInput.table_info.upper = null;
                            }
                          "
                        />
                      </b-input-group-append> </b-input-group
                  ></b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox
                    v-model="tableInput.table_info.is_post"
                    name="is-menu-visible"
                    class="mt-2"
                    inline
                  >
                    {{ $t("Apply after calculation") }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <!-- <b-row>
        <b-col cols="12">
          <b-alert show> {{ $t("device.SensorWarning") }} </b-alert>
        </b-col>
      </b-row> --> </b-container
            ><b-container class="bound-limits-row">
              <b-row>
                <b-col>
                  <div style="color: red">{{ err1 }}</div>
                </b-col></b-row
              ></b-container
            ></b-row
          ></b-tab
        >
        <b-tab
          :title="$t('device.chart')"
          @click="activeTab('chart')"
          :active="selected_tab === 'chart'"
          :disabled="chartDisable || !tableInput.table.length"
        >
          <b-row v-if="selected_tab !== 'XYPair'">
            <b-col class="table-section" cols="12">
              <vue-apex-charts
                :options="chartOptions"
                :series="series"
              ></vue-apex-charts></b-col></b-row></b-tab
      ></b-tabs>
    </b-container>
    <div class="apply-btn-outer">
      <b-button
        variant="primary"
        :disabled="!isValidTable"
        @click="apply"
        class="mt-2 mr-1"
        v-if="!isViewOnly"
      >
        {{ $t("device.Apply") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAlert,
  BButton,
  BFormFile,
  BCardText,
  BImg,
  VBTooltip,
  BTabs,
  BTab,
  BFormRadio,
  BLink,
  BInputGroupAppend,
  BFormCheckbox,
  BInputGroup
} from "bootstrap-vue";

import DownloadDark from "@/assets/images/new-icon/download-dark.svg";
import UploadDark from "@/assets/images/new-icon/upload-dark.svg";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "CalculationTable",
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
    BInputGroup,
    BAlert,
    BButton,
    BFormFile,
    BCardText,
    BImg,
    DownloadDark,
    UploadDark,
    VBTooltip,
    BTabs,
    BTab,
    BFormRadio,
    VueApexCharts,
    BFormCheckbox,
    BInputGroupAppend
  },
  computed: {},
  data() {
    return {
      series: [],
      isValidTable: false,
      isValidXY: false,
      err: "",
      err1: "",
      chartOptions: {
        chart: {
          height: 350,
          width: 300,
          type: "line"
        },
        stroke: {
          width: "4" // First series has width 2, second series has width 1
        },
        dataLabels: {
          enabled: false // Hide data labels on the chart
        },
        xaxis: {
          title: {},
          labels: {
            formatter: function (value) {
              if (value) {
                return Number(value).toFixed(2);
              } else {
                return Number(value).toFixed(2);
              }
            }
          }
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              if (val >= 1000) {
                return (val / 1000).toFixed(1) + "k";
              }
              return val.toString();
            }
          }
        },
        legend: {
          show: false
        },
        annotations: {}
      },
      inputCsvFile: [],
      upperBound: "",
      lowerBound: "",
      rows: [],
      selected_tab: "XYPair",
      ranges: [],
      chartDisable: true,
      tableInput: {
        table: [],
        table_info: {
          lower: 0,
          upper: 0,
          is_post: true,
          is_xy_pair: true,
          xy_pair: []
        }
      }
    };
  },
  mounted() {
    if (this.value) {
      this.tableInput.table = this.value.table || [];
      if (this.tableInput.table && this.tableInput.table.length) {
        this.validateInput(this.tableInput.table);
      }
      if (this.value.table_info) {
        this.tableInput.table_info = {
          lower: this.value.table_info.lower || null,
          upper: this.value.table_info.upper || null,
          is_post: this.value.table_info.is_post || false,
          is_xy_pair: this.value.table_info.is_xy_pair || false,
          xy_pair: this.value.table_info.xy_pair || []
        };
      }
    }
  },
  props: ["value", "isViewOnly"],
  directives: {
    "b-tooltip": VBTooltip
  },
  watch: {
    value: {
      immediate: true,
      handler(newProps) {
        if (this.value) {
          this.tableInput.table = this.value.table || [];
          if (this.tableInput.table && this.tableInput.table.length) {
            this.validateInput(this.tableInput.table);
          }
          if (this.value.table_info) {
            this.tableInput.table_info = {
              lower: this.value.table_info.lower || null,
              upper: this.value.table_info.upper || null,
              is_post: this.value.table_info.is_post || false,
              is_xy_pair: this.value.table_info.is_xy_pair || false,
              xy_pair: this.value.table_info.xy_pair || []
            };
          }
        }
      }
    },
    selected_tab() {
      this.$emit("radioButtonChanged", this.selected_tab);

      if (this.selected_tab === "XYPair") {
      } else {
        this.tableInput.table.sort((a, b) => a.x - b.x);
        this.generateSeries();
      }
    },
    "tableInput.table": {
      handler: function (newVal, oldVal) {
        if (!newVal || !newVal.length) {
          // this.isValidTable = false;
        } else {
          const table = newVal.map((e) => {
            e.x = e.x;
            e.a = e.a;
            e.b = e.b;
            return e;
          });
          this.validateInput(table);
        }
      },
      deep: true
    },
    "tableInput.table_info.xy_pair": {
      handler: function (newVal, oldVal) {
        const table = newVal.map((e) => {
          e.x = e.x;
          e.a = e.a;
          e.b = e.b;
          return e;
        });
        this.validateXYInput(newVal);
      },
      deep: true
    },

    "tableInput.table_info.lower": {
      handler: function (newVal, oldVal) {
        this.handleUpperLower();
      },
      deep: true
    },
    "tableInput.table_info.upper": {
      handler: function (newVal, oldVal) {
        this.handleUpperLower();
      },
      deep: true
    },
    "tableInput.table_info.is_post": {
      handler: function (newVal, oldVal) {
        this.generateSeries();
      },
      deep: true
    }
  },

  methods: {
    rangeTableSelected() {
      // console.log("the tab selected:", this.selected_tab);
      // if (this.selected_tab === "RangeTable" && !this.ranges.length) {
      //   this.addNewRangeRow();
      // }
    },
    clearBounds() {
      this.tableInput.table_info.lower = null;
    },
    apply() {
      this.tableInput.table_info.xy_pair =
        this.tableInput.table_info.xy_pair.filter((row) => {
          if (row.x !== "" && row.y !== "") {
            row.x = row.x;
            row.y = row.y;
            return row;
          }
        });
      this.tableInput.table = this.tableInput.table.filter((row) => {
        if (row.x !== "" && row.a !== "" && row.b !== "") {
          row.x = row.x;
          row.a = row.a;
          row.b = row.b;
          return row;
        }
      });
      this.$emit("closeCalculationModal", {
        ...this.value,
        ...this.tableInput
      });
    },
    validateInput(inputArray) {
      const xValues = new Set();
      this.isValidTable = inputArray.every((item, index) => {
        const isNullOrEmpty = (value) =>
          value === null || value === "" || value === undefined;

        if (
          (isNullOrEmpty(item.x) && !isNullOrEmpty(item.a)) ||
          (!isNullOrEmpty(item.x) && isNullOrEmpty(item.a)) ||
          (isNullOrEmpty(item.x) && !isNullOrEmpty(item.b)) ||
          (!isNullOrEmpty(item.x) && isNullOrEmpty(item.b))
        ) {
          return false;
        }

        if (
          xValues.has(item.x) &&
          !(item.x === null || item.x === "" || item.x === undefined)
        ) {
          this.err = `Duplicate x value found at line ${index + 1}: ${item.x}`;
          return false; // Duplicate x value found
        }
        this.err = "";
        xValues.add(item.x);
        return true;
      });

      if (this.isValidTable) {
        this.generateSeries();
      }
    },
    validateXYInput(inputArray) {
      const xValues = new Set();
      this.isValidXY = inputArray.every((item, index) => {
        const isNullOrEmpty = (value) =>
          value === null || value === "" || value === undefined;

        if (
          (isNullOrEmpty(item.x) && !isNullOrEmpty(item.y)) ||
          (!isNullOrEmpty(item.x) && isNullOrEmpty(item.y))
        ) {
          return false;
        }

        if (
          xValues.has(item.x) &&
          !(item.x === null || item.x === "" || item.x === undefined)
        ) {
          this.err = `Duplicate x value found at line ${index + 1}: ${item.x}`;
          return false; // Duplicate x value found
        }
        this.err = "";
        xValues.add(item.x);
        return true;
      });
    },
    calculateChartData(data) {
      if (!data || !data.length) {
        return;
      }
      // Function to calculate Y
      function calculateY(x, a, b) {
        return Number(a) * Number(x) + Number(b);
      }

      // Add the extra points at the beginning and the end
      const firstElement = data[0];
      const lastElement = data[data.length - 1];

      // Calculate step size based on the slope
      const stepStart = Math.abs(firstElement.x * 0.1); // Adjust the divisor to control step size
      const stepEnd = Math.abs(lastElement.x * 0.1); // Adjust the divisor to con   trol step size
      let extraStartX = Number(firstElement.x) - Number(stepStart);
      let extraEndX = Number(lastElement.x) + Number(stepEnd);
      const lowerBound =
        parseFloat(this.tableInput.table_info.lower) -
          parseFloat(this.tableInput.table_info.lower) * 0.1 || null;
      const upperBound =
        parseFloat(this.tableInput.table_info.upper) +
          parseFloat(this.tableInput.table_info.upper) * 0.1 || null;

      if (extraStartX > lowerBound && lowerBound != null) {
        extraStartX = lowerBound;
      }
      if (extraEndX < upperBound && upperBound != null) {
        extraEndX = upperBound;
      }
      this.chartOptions.yaxis.min = function (min) {
        return extraStartX;
      };
      this.chartOptions.xaxis.max = function (min) {
        return extraEndX;
      };

      // Adjust x values for extra points based on calculated step sizes
      const extraStart = {
        x: extraStartX,
        a: firstElement.a,
        b: firstElement.b
      };
      const extraEnd = {
        x: extraEndX,
        a: lastElement.a,
        b: lastElement.b
      };

      const extendedData1 = [extraStart, ...data, extraEnd];
      let extendedData = [];
      for (let i = 0; i < extendedData1.length; i++) {
        if (i > 0) {
          extendedData.push({
            x: Number(extendedData1[i].x) - 0.1,
            a: extendedData1[i - 1].a,
            b: extendedData1[i - 1].b
          });
        }
        extendedData.push(extendedData1[i]);
      }
      // extendedData.sort((a, b) => a.x - b.x);
      // Create a new array with x and calculated y values
      const results = extendedData.map((item) => {
        return { x: item.x, y: calculateY(item.x, item.a, item.b) };
      });

      return results;
    },
    removeAllRangesRecord() {
      this.tableInput.table = [];
    },
    removeAllRecord() {
      this.tableInput.table_info.xy_pair = [];
    },
    validateNumber(value) {
      // Assuming range.x is a string, convert it to a number
      let input = parseFloat(value);

      // Check if the value is a valid number
      if (!isNaN(input)) {
        // Limit the number of decimal places to 2
        let roundedValue = input;

        // Update the model with the rounded value
        return roundedValue;
      } else {
        return 0;
      }

      // Additional validation logic goes here
    },
    generateCalculationTable(rows) {
      this.tableInput.table_info.xy_pair = rows.filter((row) => {
        if (row.x !== "" && row.y !== "") {
          row.x = row.x;
          row.y = row.y;
          return row;
        }
      });
      this.tableInput.table_info.xy_pair.sort((a, b) => a.x - b.x);
      const xyPairs = this.tableInput.table_info.xy_pair;

      if (xyPairs.length == 1) {
        return [{ x: xyPairs[0].x, a: 0, b: xyPairs[0].y }];
      }
      const result = [];
      for (let i = 1; i < xyPairs.length; i++) {
        const x1 = xyPairs[i - 1].x;
        const y1 = xyPairs[i - 1].y;
        const x2 = xyPairs[i].x;
        const y2 = xyPairs[i].y;
        const a = (y2 - y1) / (x2 - x1);
        const b = y1 - a * x1;
        result.push({ x: x1, a, b });
      }
      this.tableInput.table = result;
      this.generateSeries();
    },
    sendDisabledFlag(disabled) {
      this.$emit("flagChanged", disabled);
    },
    handleUpperLower() {
      if (
        Number(this.tableInput.table_info.lower) >
        Number(this.tableInput.table_info.upper)
      ) {
        this.err1 = `Upper bound should be greater than lower bound`;
        this.chartDisable = true;

        this.isValidTable = false;
      } else {
        this.generateSeries();
        this.err1 = "";
        this.chartDisable = false;
        this.validateInput(this.tableInput.table);
      }
    },
    addNewRow() {
      this.tableInput.table_info.xy_pair.push({ x: "", y: "" });
      this.$emit(
        "rowsChanges",
        JSON.parse(JSON.stringify(this.tableInput.table_info.xy_pair))
      );
      this.tableInput.table_info.xy_pair = JSON.parse(
        JSON.stringify(this.tableInput.table_info.xy_pair)
      );
      this.sendDisabledFlag(true);
    },
    addNewRangeRow() {
      this.tableInput.table.push({ x: "", a: "", b: "" });
      this.$emit(
        "rangesChanges",
        JSON.parse(JSON.stringify(this.tableInput.table))
      );
      this.tableInput.table = JSON.parse(JSON.stringify(this.tableInput.table));
      this.sendDisabledFlag(true);
    },
    removeRangeRow(index) {
      this.tableInput.table.splice(index, 1);
    },
    removeRow(index) {
      this.tableInput.table_info.xy_pair.splice(index, 1);
      this.$emit(
        "rowsChanges",
        JSON.parse(JSON.stringify(this.tableInput.table_info.xy_pair))
      );
    },
    downloadRangeCsv() {
      let csvContent = "data:text/csv;charset=utf-8,";
      this.ranges.forEach((range) => {
        csvContent += `${range.x},${range.y},${range.z}` + "\n";
      });
      window.open(encodeURI(csvContent));
    },
    downloadCsv() {
      let csvContent = "data:text/csv;charset=utf-8,";
      this.tableInput.table_info.xy_pair.forEach((row) => {
        csvContent += `${row.x};${row.y}` + "\n";
      });
      window.open(encodeURI(csvContent));
    },
    parseCsv(content) {
      this.tableInput.table_info.xy_pair = content
        .split("\n")
        .map((row) => row.split(/[;,]/)) // Split on either ";" or ","
        .map((row) => {
          return {
            x: row[0] && row[0].trim(),
            y: row[1] !== undefined && row[1].trim()
          };
        })
        .filter((r) => r.x || r.y);
    },
    parseRangeCsv(content) {
      try {
        this.ranges = content
          .split("\n")
          .map((row) => row.split(/[;,]/)) // Split on either ";" or ","
          .map((row) => {
            return {
              x: row[0].trim(), // Trim the x value
              y: row[1] ? row[1].trim() : "", // Trim the y value if it exists
              z: row[2] ? row[2].trim() : "" // Trim the z value if it exists
            };
          })
          .filter((r) => r.x || r.y || r.z);
      } catch (err) {
        console.log(err);
      }
    },
    activeTab(tab) {
      this.tableInput.table = this.tableInput.table.filter((row) => {
        if (row.x !== "" && row.a !== "" && row.b !== "") {
          row.x = row.x;
          row.a = row.a;
          row.b = row.b;
          return row;
        }
      });
      if (
        this.tableInput.table_info.lower &&
        this.tableInput.table_info.upper &&
        Number(this.tableInput.table_info.lower) >
          Number(this.tableInput.table_info.upper)
      ) {
        this.err1 = `Upper bound should be greater than lower bound`;
      }
      this.selected_tab = tab;
      if (!this.tableInput.table || !this.tableInput.table.length) {
        this.selected_tab = "XYPair";
      }
    },
    importCsv(range = false) {
      // this.csvFile = this.$refs.doc.files[0];
      try {
        if (this.inputCsvFile && this.inputCsvFile.name.includes(".csv")) {
          if (this.tableInput && this.tableInput.table_info) {
            this.tableInput.table_info.is_xy_pair = true;
          }

          const reader = new FileReader();
          reader.onload = (res) => {
            range
              ? this.parseRangeCsv(res.target.result)
              : this.parseCsv(res.target.result);
          };
          reader.onerror = (err) => console.log(err);
          reader.readAsText(this.inputCsvFile);
        }
        setTimeout(() => {
          this.inputCsvFile = [];
        }, 100);
      } catch (err) {
        console.log(err);
      }
    },
    generateSeries() {
      const input = this.calculateChartData(this.tableInput.table);
      this.series = [{ data: input, name: "data", color: "#d8d6de" }];
      const lowerBound = parseFloat(this.tableInput.table_info.lower) || null;
      const upperBound = parseFloat(this.tableInput.table_info.upper) || null;
      if (!this.tableInput.table_info.is_post) {
        this.chartOptions.annotations = {
          xaxis: [
            {
              x: lowerBound,
              x2: upperBound,
              borderColor: "#90EE90",
              label: {
                borderColor: "#90EE90",
                style: {
                  color: "#fff",
                  background: "#90EE90"
                }
              },
              fillColor: "#90EE90"
            }
          ]
        };
      } else {
        this.chartOptions.annotations = {
          yaxis: [
            {
              y: lowerBound,
              y2: upperBound,
              borderColor: "#90EE90",
              label: {
                borderColor: "#90EE90",
                style: {
                  color: "#fff",
                  background: "#90EE90"
                }
              },
              fillColor: "#90EE90"
            }
          ]
        };
      }
      return;
    }
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/core/colors/palette-variables.scss";
.calculation-table {
  padding: 10px;
  .table-rows.container {
    max-height: 315px;
    min-height: 315px;
    overflow: auto;
  }
  .bound-limits-row {
    border-top: solid 1px #ebe9f1;
    border-bottom: solid 1px #ebe9f1;
    padding: 20px 0 0;
    .alert {
      padding: 15px;
    }
  }
  .graph-section {
    border-left: 1px #dee2e6 solid;
  }
  .header-row {
    text-align: center;
    .column {
      padding: 5px;
      input {
        cursor: default;
        border: none;
        background: #e9e9e9;
        text-align: center;
      }
    }
  }
  .value-row {
    .column {
      padding: 5px;
    }
  }
  .add-new-line {
    cursor: pointer;
    margin: 12px;
    color: var(--primary);
  }
  // .feather {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 8px;
  // }
}
.custom-upload-button {
  position: relative;
  cursor: pointer;
  .custom-file-input.b-form-file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: $percent_100;
    height: $percent_100;
    opacity: 0;
    cursor: pointer;
  }
  .custom-file-label {
    cursor: pointer;
  }
}
.align-right-file-name {
  text-align: right;
  padding-right: 0;
}
.btn-active:hover {
  svg,
  path {
    fill: var(--primary);
  }
}
.form-radio-class > div {
  display: flex !important;
}
.form-radio-class .custom-radio {
  margin: 10px;
}
.col-width {
  width: 49%;
}
.col-width-1 {
  width: 49%;
}
.calculation-table-title-row {
  margin-bottom: 6px;
}
.nav-item {
  a {
    pointer-events: auto !important;
  }
}
.search-clear-filter {
  padding: 0px 5px 0px 5px;
}
.input-prefix-img-filter {
  border: 1px solid var(--gray4);
  height: 36.5px;
  width: 40px;
  border-radius: 0px 7px 7px 0px;
  background: #d8d6de;
  text-align: center;
  svg {
    height: 32px;
    margin: auto;
    margin-left: auto;
  }
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 5px;
    }
  }
}
</style>
