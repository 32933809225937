<template>
  <div>
    <b-button
      variant="outline-primary"
      class="mt-2 min-w-160"
      @click="callMyApi"
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      v-b-modal.allocate
      >{{ $t("device.DeviceAllocation") }}</b-button
    >
    <b-modal
      id="allocate"
      centered
      size="lg"
      modal-class="no-header-modal device-allocation-modal"
    >
      <b-card-text>
        <div v-if="device.isDeviceEditable">
          <h4 class="text-center">{{ $t("device.deviceallocation") }}</h4>
          <validation-provider #default="validationContext" name="ReallocateTo">
            <b-form-group
              :label="
                deviceOwnerId
                  ? $t('device.ReallocateTo')
                  : $t('device.AllocateTo')
              "
              :state="getValidationState(validationContext)"
            >
              <treeselect
                v-model="deviceOwnerId"
                :multiple="false"
                :options="getAllAccountOptions"
                :searchable="true"
                ref="treeselect"
                :clearable="false"
                :disabled="isViewOnly"
                @open="handleMenuOpen"
                @search-change="searchAccount"
              >
                <div
                  slot="option-label"
                  class="tree-select-opction"
                  slot-scope="{ node }"
                  :title="node.label"
                >
                  {{ node.label }}
                </div>
              </treeselect>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
            <div class="d-flex justify-content-center mb-64 mtt-50">
              <b-button
                variant="primary"
                :disabled="isViewOnly"
                class="mr-1"
                @click="checkDeviceAllocated"
                >{{ $t("device.Allocate") }}</b-button
              >
              <b-button
                variant="outline-secondary"
                :disabled="isViewOnly"
                @click="resetPrev"
                >{{ $t("user.Cancel") }}</b-button
              >
            </div>
          </validation-provider>
          <hr />
        </div>
        <div class="mt-1">
          <h6 class="text-center">{{ $t("device.history") }}</h6>
          <DeviceHistory
            :deviceData="device"
            component_key="allocation"
          ></DeviceHistory>
        </div>
      </b-card-text>
    </b-modal>

    <b-modal
      id="is-device-allocated-unit"
      centered
      size="sm"
      modal-class="no-header-modal"
    >
      <p>{{ modelText }}</p>
      <div class="d-flex justify-content-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          v-b-modal.unit-allocate
          @click="reallocateDevice"
        >
          {{ $t("unit.Yes") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          @click="onCloseModel"
          class="mt-2"
        >
          {{ $t("unit.No") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInvalidFeedback,
  BDropdown,
  BDropdownItem
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import AccountService from "@/libs/api/account-service";
import DeviceService from "@/libs/api/device-service";
import UnitService from "@/libs/api/unit-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import constants from "@/utils/constants";
import formValidation from "@core/comp-functions/forms/form-validation";
import Loader from "@/layouts/components/Loader.vue";
import flatPickr from "vue-flatpickr-component";
import * as moment from "moment";
import DeviceHistory from "./DeviceHistory.vue";
export default {
  components: {
    BButton,
    BForm,

    BFormGroup,
    BFormInput,
    BRow,
    BCol,

    BCard,
    BCardText,

    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    Loader,
    BDropdown,
    BDropdownItem,
    flatPickr,
    Treeselect,
    DeviceHistory
  },
  directives: {
    Ripple
  },

  computed: {},
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      hasErr: "",
      modelText: null,
      device: {
        isDeviceEditable: false,
        deviceName: null,
        version: "",
        SIMNumber1: null,
        SIMOperator1: null,
        SIMNumber2: null,
        SIMOperator2: null,
        model: null,
        status: null,
        uniqueIdentifier: null,
        account: null
      },
      manufacturerId: "",
      manufacturerIdOld: "",
      modelId: "",
      prevRoute: null,
      manufacturers: [],
      oldDeviceOwnerId: null,
      deviceOwnerId: null,
      showLoading: false,
      allocationFlag: false,
      uniqueHandlError: false,
      initialDevice: null,
      profileFile: null,
      statusOption: ["ACTIVE", "INACTIVE", "DAMAGED", "BLOCKED"],
      accountOptions: [],
      getAllAccountOptions: [],
      allocationHistory: [],
      singleHistory: [],
      modelOptions: [],
      required,
      constants,
      refFormObserver,
      isDeviceReallocation: false,
      isEditable: true,
      getValidationState,
      startDate: null,
      endDate: null,
      currentPage: 1,
      totalDevices: null,
      perPage: 5,
      allocationData: null,
      c1: null,
      status: false,
      filterAccount: "",
      previousEndDate: null,
      previousStartDate: null,
      identifierError: false,
      simCartError: false
    };
  },
  props: ["deviceData", "getAllAccountOp", "isViewOnly"],
  mounted() {
    if (this.getAllAccountOp) {
      this.getAllAccountOptions = this.getAllAccountOp;
      this.isDeviceReallocation = true;
    } else {
      this.getChildAccounts();
    }
    this.device = this.deviceData;
    this.oldDeviceOwnerId = this.device.account;
    this.deviceOwnerId = this.device.account;
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  watch: {
    deviceData(old, newVal) {
      this.device = this.deviceData;
      this.oldDeviceOwnerId = this.device.account;
      this.deviceOwnerId = this.device.account;
    },
    getAllAccountOp: {
      immediate: true,
      handler(newProps) {
        this.getAllAccountOptions = newProps;
        this.isDeviceReallocation = true;
      }
    }
  },
  beforeDestroy() {
    removeEventListener("scroll", (event) => {});
  },
  methods: {
    searchAccount(filterAccount) {
      this.getChildAccounts(filterAccount);
    },
    handleMenuOpen() {
      this.$nextTick(() => {
        const menu = this.$refs.treeselect.getMenu();
        const hasReachedEnd =
          menu.scrollHeight - menu.scrollTop <= menu.clientHeight * 0.25;
        menu.addEventListener("scroll", () => {
          if (hasReachedEnd) this.fetchOptions();
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    async fetchOptions() {
      if (this.isFetchingOptions) return;
      this.isFetchingOptions = true;
      this.showLoading = true;
      let response = await new AccountService().getChildAccounts({
        isChild: true,
        page: this.currentPage,
        page_size: parseInt(this.perPage),
        filterAccount: this.filterAccount || undefined
      });
      this.showLoading = false;
      if (response && response.data) {
        this.getAllAccountOptions = response.data;
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      this.isFetchingOptions = false;
    },

    clearStartEndDate() {
      if (this.endDate || this.startDate) {
        this.endDate = this.startDate = null;
        this.callMyApi();
      }
    },
    callMyApiCheck() {
      if (
        this.startDate == this.previousStartDate &&
        this.endDate == this.previousEndDate
      ) {
        return;
      } else {
        this.callMyApi();
      }
    },
    DateTimeConvert(date) {
      let formattedDate = date ? date : getCurrentTime();
      return this.formattedDateTime(formattedDate, constants.DATE_TIME_FORMAT);
    },
    async callMyApi() {
      try {
        this.showLoading = true;
        this.allocationHistory = [];

        const startDate = this.startDate
          ? this.convertDatePerTimezone(this.startDate)
          : null;
        const endDate = this.endDate
          ? this.convertDatePerTimezone(this.endDate)
          : null;

        let response =
          this.$route.params.id &&
          (await new DeviceService().deviceAllocationHistory({
            device_id: this.$route.params.id,
            start_date: startDate,
            end_date: endDate,
            page: this.currentPage,
            page_size: parseInt(this.perPage)
          }));
        this.previousEndDate = this.endDate;
        this.previousStartDate = this.startDate;
        this.showLoading = false;
        if (
          response &&
          response.data &&
          response.data.deviceAllocationHistory &&
          response.data.deviceAllocationHistory.length
        ) {
          response.data.deviceAllocationHistory.map((h) => {
            let history = {
              account: h.account,
              start_date: this.DateTimeConvert(h.start_date),
              end_date: h.end_date ? this.DateTimeConvert(h.end_date) : ""
            };
            this.allocationHistory.push(history);
          });
          // this.allocationHistory =
          //   (response.data.history && response.data.history[0]) || [];
          this.singleHistory =
            (Array.isArray(this.allocationHistory) &&
              this.allocationHistory.length && [this.allocationHistory[0]]) ||
            [];
          this.deviceOwnerId =
            response.data.deviceAllocationHistory[0].account_id || null;
          this.totalDevices = (response.data.count && response.data.count) || 0;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    callMyApiPart(data) {
      this.perPage = data.perPage || 5;
      this.currentPage = data.currentPage || 1;
      this.callMyApi();
    },

    resetPrev() {
      this.deviceOwnerId = this.oldDeviceOwnerId;
    },

    async checkDeviceAllocated() {
      try {
        if (!this.deviceOwnerId) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.PleaseSelectAccount"),
              icon: "EditIcon",
              variant: "danger"
            }
          });
          return;
        }
        if (
          this.deviceOwnerId &&
          this.device.account &&
          this.deviceOwnerId === this.device.account
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("unit.alreadyAllocatedSameAccount"),
              icon: "EditIcon",
              variant: "danger"
            }
          });
          return;
        }
        this.showLoading = true;
        const isDeviceAllocatedToUnit =
          await new UnitService().checkDeviceAllocated({
            device_id: this.$route.params.id
          });
        this.showLoading = false;
        if (isDeviceAllocatedToUnit && isDeviceAllocatedToUnit.error) {
          this.modelText = this.getMessage(
            isDeviceAllocatedToUnit.error.message
          );
          this.$bvModal.show("is-device-allocated-unit");
        } else if (
          isDeviceAllocatedToUnit &&
          isDeviceAllocatedToUnit.data &&
          isDeviceAllocatedToUnit.data.success
        ) {
          this.reallocateDevice();
        }
        // else if (response && response.error && response.error.message) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: response.error.title,
        //       text: response.error.message,
        //       icon: "InfoIcon",
        //       variant: "danger"
        //     }
        //   });
        // }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    isComplete() {
      return this.device.status !== "ACTIVE";
    },

    onCloseModel() {
      this.$bvModal.hide("is-device-allocated-unit");
    },
    async reallocateDevice(id) {
      try {
        this.showLoading = true;
        let response =
          this.deviceOwnerId != this.device.account
            ? await new DeviceService().reallocateDevice({
                device_id: this.$route.params.id || undefined,
                account_id: this.deviceOwnerId,
                old_device_owner_id: this.device.account
              })
            : null;
        this.showLoading = false;
        if (response && response.data) {
          this.oldDeviceOwnerId = this.deviceOwnerId;
          this.device.account = this.deviceOwnerId;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("device.AllocatedSuccessfully"),
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.$bvModal.hide("allocate");
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.onCloseModel();
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
      response.error.message;
    },

    async getChildAccounts(filterAccount) {
      try {
        this.showLoading = true;
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || undefined,
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });
        this.showLoading = false;
        if (response && response.data) {
          if (this.$route.params.id) {
            function recursive(data) {
              if (data && data.type.toUpperCase() == "CONSUMER") return true;

              if (data && data.children) {
                for (let i = 0; i < data.children.length; i++) {
                  if (recursive(data.children[i])) {
                    // delete data.children[i];
                    data.children[i]["isDisabled"] = true;
                  }
                }
              }
              return false;
            }
            recursive(response.data[0]);
            this.getAllAccountOptions = response.data;
            this.isDeviceReallocation = true;
          } else {
            return;
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    getMessage(e) {
      const delimiter = "Correlation ID: ";
      const parts = e.split(delimiter);
      if (parts.length) {
        return parts[0];
      } else {
        return e;
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@media only screen and (max-width: 667px) {
  .device-allocation-modal {
    .card.add-device-align {
      padding: 0;
      .card-body {
        padding: 0;
      }
    }
  }
}
</style>
