<template>
  <div class="add-device-align">
    <div class="d-flex justify-content-between align-items-center mb-1">
      <div>
        <h4 class="card-title mb-0">{{ $t("device.General") }}</h4>
      </div>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="
          (e) => {
            $router.push({ name: 'devices-list' });
          }
        "
        class="btn set-back-mobile v2-back"
      >
        <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
      </b-button>
    </div>
    <div class="d-create">
      <b-row>
        <b-col sm="6">
          <validation-provider
            #default="validationContext"
            name="Device Name"
            :rules="{
              required: true,
              min: constants.MIN_DEVICE_NAME,
              max: constants.MAX_DEVICE_NAME
            }"
          >
            <b-form-group :label="$t('device.DeviceName*')">
              <b-form-input
                @input="deviceFun(device)"
                :placeholder="$t('device.DeviceName')"
                name="deviceName"
                :disabled="!isEditable"
                v-model="device.deviceName"
                trim
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider
            #default="validationContext"
            name="Device Version"
            :rules="{
              max: constants.MAX_DEVICE_VERSION
            }"
          >
            <b-form-group :label="$t('device.Version')">
              <b-form-input
                @input="deviceFun(device)"
                name="deviceVersion"
                :placeholder="$t('device.Version')"
                v-model="device.version"
                :disabled="!isEditable"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="3">
          <validation-provider
            #default="validationContext"
            name="SIM number"
            :rules="{
              required: true,
              min: constants.MIN_SIM_NUMBER,
              max: constants.MAX_SIM_NUMBER,
              regex: constants.PHONE_PATTERN
            }"
          >
            <b-form-group :label="$t('device.SIMNumber*')">
              <b-form-input
                @input="deviceFun(device)"
                name="SIM number1"
                type="number"
                :placeholder="$t('device.SIMNumber1')"
                v-model="device.SIMNumber1"
                :disabled="!isEditable"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="3">
          <validation-provider
            #default="validationContext"
            name="SIM operator"
            :rules="{
              required: true,
              min: constants.MIN_SIM_OPERATOR,
              max: constants.MAX_SIM_OPERATOR
            }"
          >
            <b-form-group :label="$t('device.SIMOperator*')">
              <b-form-input
                @input="deviceFun(device)"
                name="SIM Operator1"
                :placeholder="$t('device.SIMOperator1')"
                v-model="device.SIMOperator1"
                :disabled="!isEditable"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="3">
          <validation-provider
            #default="validationContext"
            name="SIM Number"
            :rules="{
              min: constants.MIN_SIM_NUMBER,
              max: constants.MAX_SIM_NUMBER,
              regex: constants.PHONE_PATTERN
            }"
          >
            <b-form-group :label="$t('device.SIMNumber2')">
              <b-form-input
                @input="deviceFun(device)"
                name="SIMNumber2"
                type="number"
                :placeholder="$t('device.SIMNumber2')"
                v-model="device.SIMNumber2"
                :disabled="!isEditable"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="3">
          <validation-provider
            #default="validationContext"
            name="SIM Operator"
            :rules="{
              min: constants.MIN_SIM_OPERATOR,
              max: constants.MAX_SIM_OPERATOR
            }"
          >
            <b-form-group :label="$t('device.SIMOperator2')">
              <b-form-input
                @input="deviceFun(device)"
                name="SIMOperator"
                :disabled="!isEditable"
                :placeholder="$t('device.SIMOperator2')"
                v-model="device.SIMOperator2"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Device Manufacture"
            rules="required"
          >
            <b-form-group
              :label="$t('device.Manufacture*')"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="vue-select"
                v-model="manufacturerId"
                @input="
                  changedManufacture();
                  deviceFun(device);
                "
                :disabled="!isEditable"
                :placeholder="$t('device.SelectManufacture')"
                :options="manufacturers"
                :reduce="(option) => option.id"
                label="name"
              />

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="Device Model"
            rules="required"
          >
            <b-form-group
              :label="$t('device.Model*')"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="vue-select"
                v-model="deviceModel"
                :disabled="!isEditable"
                @input="changedDeviceModel()"
                :placeholder="$t('device.SelectdeviceModel')"
                :options="modelOptions"
                :reduce="(option) => option.id"
                label="model_name"
                menu-props="auto"
              >
                <template #option="{ model_name, model_icon_url }">
                  <b-img
                    :src="model_icon_url"
                    height="14px"
                    width="14px"
                    :alt="model_name"
                  />
                  <span class="ml-50 text-body">{{ model_name }}</span>
                </template>
                <template #selected-option="{ model_icon_url, model_name }">
                  <div class="d-flex align-items-center">
                    <b-img
                      :src="model_icon_url"
                      height="14px"
                      width="14px"
                      :alt="model_name"
                    />
                    <span class="ml-50 text-body">{{ model_name }}</span>
                  </div>
                </template>
              </v-select>

              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            name="status"
            rules="required"
          >
            <b-form-group
              :label="$t('device.Status*')"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="vue-select"
                @input="deviceFun(device)"
                v-model="device.status"
                :disabled="!isEditable"
                :placeholder="$t('device.Selectdevicestatus')"
                :options="statusOption"
                :reduce="(option) => option.key"
                label="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider
            #default="validationContext"
            name="Unique Identifier"
            :rules="{
              required: true,
              min: constants.MIN_UNIQUE_IDENTIFIER,
              max: constants.MAX_UNIQUE_IDENTIFIER
            }"
          >
            <b-form-group :label="$t('device.UniqueIdentifier*')">
              <b-form-input
                @input="deviceFun(device)"
                name="uniqueIdentifier"
                :disabled="!isEditable"
                v-on:change="veriyUniueIdentifier"
                :placeholder="$t('device.UniqueIdentifier')"
                v-model="device.uniqueIdentifier"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
              <div v-if="uniqueHandlError" style="margin-top: 10px">
                <small class="text-danger">{{
                  $t("device.UniqueIdentifierSapce")
                }}</small>
              </div>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="tag-select-disable"
          sm="6"
          v-if="
            checkAbility({
              action: constants.PERMISSIONS_ACTION.READ,
              subject: constants.PERMISSIONS_MODEL.LABELS
            })
          "
        >
          <b-form-group :label="$t('unit.Labels')" :disabled="!isEditable">
            <TagMultiSelect
              v-model="device.labels"
              @input="deviceFun(device)"
              :class="!isEditable ? 'disabled' : ''"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="validationContext"
            :name="$t('profile.TimeZone')"
            rules="required"
          >
            <b-form-group
              :state="getValidationState(validationContext)"
              :label="$t('profile.TimeZone') + '*'"
              class="is-valid timezone-input"
            >
              <v-select
                id="vue-select"
                class="timesize"
                :disabled="!isEditable"
                :class="!isEditable ? 'disabled' : ''"
                v-model="device.timezone"
                @input="deviceFun(device)"
                :options="timeZone.option"
                :reduce="(option) => option.value"
                label="label"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{
                  validationContext.errors[0]
                    ? handleError(validationContext.errors[0])
                    : ""
                }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="6" v-show="protocol_info.port && deviceModel">
          <b-row class="d-flex justify-content-between mt-2">
            <b-col md="4" cols="12" class="mb-1">
              <span
                >{{ $t("device.Protocol") }} {{ protocol_info.protocol }}</span
              >
            </b-col>
            <b-col md="4" cols="12" class="mb-1">
              <span
                >{{ $t("device.ServerIP") }} {{ protocol_info.server_ip }}</span
              >
            </b-col>
            <b-col md="4" cols="12" class="mb-1">
              <span>{{ $t("device.Port") }} {{ protocol_info.port }}</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <Loader :show="showLoading" />
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import vSelect from "vue-select";
import TagMultiSelect from "@/layouts/components/TagMultiSelect.vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import formValidation from "@core/comp-functions/forms/form-validation";
import AccountService from "@/libs/api/account-service";
import DeviceService from "@/libs/api/device-service";
import Loader from "@/layouts/components/Loader.vue";
import constants from "@/utils/constants";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BMedia,
  BFormInvalidFeedback,
  BFormFile,
  BImg,
  BAvatar,
  BButton,
  BOverlay,
  BForm,
  BCard
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    vSelect,
    BOverlay,
    BMedia,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    BImg,
    BForm,
    BCardCode,
    BCard,
    Loader,
    TagMultiSelect
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      required,
      roleData: {},
      allPermissions: {},
      labels: {},
      show: false,
      isAddRole: false,

      nextBtn: "Save & Configure",

      getValidationState,

      hasErr: "",
      modelText: null,
      manufacturerId: "",
      manufacturerIdOld: "",
      modelId: "",
      prevRoute: null,
      manufacturers: [],
      oldDeviceOwnerId: null,
      deviceOwnerId: null,
      showLoading: false,
      allocationFlag: false,
      uniqueHandlError: false,
      timeZone: {
        option: constants.TZStrings
      },
      device: {
        timezone: JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"))
          ? JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS")).timezone
          : "UTC",
        deviceName: null,
        version: "",
        SIMNumber1: null,
        SIMOperator1: null,
        SIMNumber2: null,
        SIMOperator2: null,
        model: null,
        status: "ACTIVE",
        uniqueIdentifier: null,
        account: null,
        labels: null
      },
      initialDevice: null,
      statusOption: [
        {
          label: this.$t("device.StatusList.ACTIVE"),
          key: "ACTIVE"
        },
        {
          label: this.$t("device.StatusList.INACTIVE"),
          key: "INACTIVE"
        },
        {
          label: this.$t("device.StatusList.DAMAGED"),
          key: "DAMAGED"
        },
        {
          label: this.$t("device.StatusList.BLOCKED"),
          key: "BLOCKED"
        }
      ],
      accountOptions: [],
      getAllAccountOptions: [],
      modelOptions: [],
      required,
      constants,
      refFormObserver,
      isDeviceReallocation: false,
      allModels: [],
      isEditable: true,
      getValidationState,
      deviceModel: "",
      c1: null,
      status: false,
      filterAccount: "",
      protocol_info: {
        port: "",
        protocol: "",
        server_ip: ""
      },
      timeoutId: null
    };
  },
  mounted() {
    this.getAllManufacturer();

    if (this.getAllAccountOp) {
      this.getAllAccountOptions = this.getAllAccountOp;
      this.isDeviceReallocation = true;
    } else {
      this.getChildAccounts();
    }

    if (
      this.$route.name === "edit-device" ||
      this.$route.name === "view-device" ||
      this.$route.name === "add-new-device-id"
    ) {
      if (this.$route.params.id) {
        // this.getOneDevice(this.$route.params.id);
      } else {
        this.$router.go(-1);
      }
    }
    this.device = this.deviceData;
  },
  props: [
    "deviceData",
    "deviceFun",
    "isDiscardDevice",
    "oldManufacturerData",
    "activeTab",
    "updateModel",
    "getAllAccountOp"
  ],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
    this.device;
  },
  watch: {
    manufacturerId(newValue, oldValue) {
      // this.protocol_info = { port: "", protocol: "", server_ip: "" };
    },

    device(newValue, oldValue) {
      this.deviceFun(newValue);
    },

    activeTab(newTab, oldTab) {
      if (Number(oldTab) === 0) {
        if (this.deviceData) {
          this.setDevice();
        } else this.debounce(500);
        // this.getOneDevice(this.$route.params.id);
      }
    },
    isDiscardDevice() {
      this.device = this.deviceData;
      this.manufacturerId = this.oldManufacturerData;
      this.changedManufacture();
    },
    getAllAccountOp: {
      immediate: true,
      handler(newProps) {
        this.getAllAccountOptions = newProps;
        this.isDeviceReallocation = true;
      }
    },
    $route(to, from) {
      if (this.$route.params.id && Number(this.$route.query.tab) == 0) {
        this.getOneDevice(this.$route.params.id);
      }
    }
    // deviceModel() {
    //   if (!this.model_id) {
    //     this.protocol_info = { port: "", protocol: "", server_ip: "" };
    //   }
    // }
  },
  methods: {
    debounce(func, delay) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getOneDevice(this.$route.params.id);
      }, delay);
    },
    changedManufacture() {
      if (this.manufacturerId) {
        this.onChangeManufacture(this.manufacturerId);
      }
    },
    async parserModelData(data) {
      this.showLoading = true;
      const response = await new DeviceService().parserModelData({
        model_id: data.id
      });
      this.showLoading = false;
      if (response && response.data) {
        // const parserModel = {
        //   port: response.data.port,
        //   protocol: response.data.protocol,
        //   server_ip: response.data.public_ip
        // };
        // this.protocol_info = JSON.parse(JSON.stringify(parserModel));
        this.protocol_info.port = response.data.port;
        this.protocol_info.protocol = response.data.protocol;
        this.protocol_info.server_ip = response.data.public_ip;
      } else if (response && response.error && response.error.message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    changedDeviceModel() {
      this.device.model = this.deviceModel;
      this.deviceFun(this.device);
      this.allModels.find;
      if (this.device && this.device.model) {
        const selectedModel = this.modelOptions.find(
          (e) => e.id == this.device.model
        );
        this.updateModel(selectedModel);
        selectedModel &&
          selectedModel.default_protocol &&
          this.parserModelData(selectedModel);
      }
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    searchAccount(filterAccount) {
      this.getChildAccounts(filterAccount);
    },
    setDevice() {
      try {
        this.device = this.deviceData;
        this.manufacturerId = this.deviceData.manufacturerId;
        this.protocol_info.port = this.deviceData.port;
        this.protocol_info.protocol = this.deviceData.protocol;
        this.protocol_info.server_ip =
          this.deviceData.public_ip || this.deviceData.server_ip;
        this.deviceModel = this.deviceData.model;
        this.getAllModel({ manufacturer_id: this.manufacturerId });

        this.$route.meta.breadcrumb = [
          ...constants.DEVICES_ROUTE_META_BREADCRUMB
        ];

        if (
          ((this.$route.name === "edit-device" ||
            this.$route.name === "add-new-device-id") &&
            this.checkAbility({
              action: constants.PERMISSIONS_ACTION.UPDATE,
              subject: constants.PERMISSIONS_MODEL.DEVICES
            })) ||
          (this.checkAbility({
            action: constants.PERMISSIONS_ACTION.ADD,
            subject: constants.PERMISSIONS_MODEL.DEVICES
          }) &&
            this.deviceData.isDeviceEditable)
        ) {
          this.isEditable = true;

          this.$route.meta.breadcrumb.push(
            ...[
              {
                to: { name: "edit-device", params: { id: this.deviceData.id } },
                text: this.deviceData.deviceName
              },
              {
                text: "Breadcrumb_Edit",
                active: true
              }
            ]
          );
        } else {
          this.$route.meta.breadcrumb.push({
            text: this.deviceData.deviceName
          });
          this.isEditable = false;
        }
        if (
          this.$route.name == "view-device" &&
          this.$route.params &&
          this.$route.params.id
        ) {
          this.isEditable = false;
          this.$route.meta.breadcrumb = [
            ...constants.DEVICES_ROUTE_META_BREADCRUMB,
            {
              text: this.deviceData.deviceName,
              active: true
            }
          ];
        }

        // this.oldDeviceOwnerId = response.data.owner_account_id;
        // this.deviceOwnerId = response.data.owner_account_id;
        this.initialDevice = Object.assign({}, this.device);
        this.allocationFlag = true;
        this.deviceFun({
          ...this.device,
          manufacturerIdOld: this.manufacturerId
        });
      } catch (error) {
        this.getOneDevice(this.$route.params.id);
      }
    },
    wrapValuesInBraces(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (value && typeof value === "object") {
            this.wrapValuesInBraces(value);
          } else if (
            key === "value" &&
            obj.source === "input" &&
            value &&
            typeof value === "string" &&
            !(value.startsWith("{") && value.endsWith("}"))
          ) {
            obj[key] = `{${value}}`;
            // console.log(obj);
          }
        }
      }
    },

    transformArray(arr) {
      return arr.map((item) => {
        const sensor = item;
        if (sensor) {
          //  this.wrapValuesInBraces(sensor.fields);
        }
        return item;
      });
    },
    async getOneDevice(id) {
      try {
        if (!id) {
          return true;
        }
        this.showLoading = true;
        let response = await new DeviceService().getOneDevice({ id });
        this.showLoading = false;
        if (response && response.data) {
          this.manufacturerId = response.data.manufacturer_id;
          this.getAllModel({ manufacturer_id: this.manufacturerId });
          this.manufacturerIdOld = response.data.manufacturer_id;
          let simCards =
            response.data.sim_cards &&
            response.data.sim_cards._arr &&
            response.data.sim_cards._arr.length
              ? response.data.sim_cards._arr
              : [];
          // await this.parserModelData({ id: response.data.model_id });
          this.protocol_info.port = response.data.protocol_info.port;
          this.protocol_info.protocol = response.data.protocol_info.protocol;
          this.protocol_info.server_ip = response.data.protocol_info.public_ip;
          this.device = {
            deviceId: id,
            timezone: response.data.timezone,
            isDeviceEditable: response.data.isDeviceEditable,
            account: response.data.account,
            deviceName: response.data.device_name,
            labels: response.data.labels,
            version: response.data.version,
            SIMNumber1:
              (simCards && simCards.length && simCards[0].sim_number) || "",
            SIMOperator1:
              (simCards && simCards.length && simCards[0].sim_operator) || "",
            SIMNumber2:
              (simCards && simCards.length >= 2 && simCards[1].sim_number) ||
              "",
            SIMOperator2:
              (simCards && simCards.length >= 2 && simCards[1].sim_operator) ||
              "",
            model: response.data.model_id,
            status: response.data.status,
            uniqueIdentifier: response.data.identifier,

            //account: response.data.owner_account_id,
            sensors:
              response.data.sensors && response.data.sensors.length
                ? this.transformArray(response.data.sensors)
                : [],
            config: response.data.config,
            commands:
              response.data.commands && response.data.commands.length
                ? response.data.commands
                : [],

            config: response.data.config,
            default_protocol: response.data.default_protocol,
            id
          };
          this.deviceModel = response.data.model_id;
          this.$route.meta.breadcrumb = [
            ...constants.DEVICES_ROUTE_META_BREADCRUMB
          ];

          if (
            ((this.$route.name === "edit-device" ||
              this.$route.name === "add-new-device-id") &&
              this.checkAbility({
                action: constants.PERMISSIONS_ACTION.UPDATE,
                subject: constants.PERMISSIONS_MODEL.DEVICES
              })) ||
            (this.checkAbility({
              action: constants.PERMISSIONS_ACTION.ADD,
              subject: constants.PERMISSIONS_MODEL.DEVICES
            }) &&
              response.data.isDeviceEditable)
          ) {
            this.isEditable = true;

            this.$route.meta.breadcrumb.push(
              ...[
                {
                  to: { name: "edit-device", params: { id: response.data.id } },
                  text: response.data.device_name
                },
                {
                  text: "Breadcrumb_Edit",
                  active: true
                }
              ]
            );
          } else {
            this.$route.meta.breadcrumb.push({
              text: response.data.device_name
            });
            this.isEditable = false;
          }
          if (
            this.$route.name == "view-device" &&
            this.$route.params &&
            this.$route.params.id
          ) {
            this.isEditable = false;
            this.$route.meta.breadcrumb = [
              ...constants.DEVICES_ROUTE_META_BREADCRUMB,
              {
                text: response.data.device_name,
                active: true
              }
            ];
          }

          // this.oldDeviceOwnerId = response.data.owner_account_id;
          // this.deviceOwnerId = response.data.owner_account_id;
          this.initialDevice = Object.assign({}, this.device);
          this.allocationFlag = true;
          this.deviceFun({
            ...this.device,
            manufacturerIdOld: this.manufacturerId
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    veriyUniueIdentifier(e) {
      this.uniqueHandlError = false;
      for (let c of e) {
        if (c == " ") {
          this.uniqueHandlError = true;
          break;
        }
      }
    },

    async getAllModel(data) {
      try {
        this.showLoading = true;

        let response = await new DeviceService().getAllModel(data);
        this.showLoading = false;
        if (response && response.data) {
          this.modelOptions = response.data.models.rows;
          this.allModels = this.modelOptions;
          if (this.device && this.device.model) {
            const selectedModel = this.modelOptions.find(
              (e) => e.id == this.device.model
            );
            this.deviceModel = (selectedModel && selectedModel.id) || "";
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getAllManufacturer() {
      try {
        this.showLoading = true;
        let response = await new DeviceService().getAllManufacturer({});
        this.showLoading = false;
        if (response && response.data) {
          this.manufacturers = response.data.List;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async onChangeManufacture() {
      this.getAllModel({ manufacturer_id: this.manufacturerId });
    },
    async getChildAccounts(filterAccount) {
      try {
        this.showLoading = true;
        let response = await new AccountService().getChildAccounts({
          isChild: true,
          filterAccount: filterAccount || undefined,
          page: this.currentPage,
          page_size: parseInt(this.perPage)
        });
        this.showLoading = false;
        if (response && response.data) {
          if (this.$route.params.id) {
            function recursive(data) {
              if (data && data.type.toUpperCase() == "CONSUMER") return true;

              if (data && data.children) {
                for (let i = 0; i < data.children.length; i++) {
                  if (recursive(data.children[i])) {
                    // delete data.children[i];
                    data.children[i]["isDisabled"] = true;
                  }
                }
              }
              return false;
            }
            recursive(response.data[0]);
            this.getAllAccountOptions = response.data;
            this.isDeviceReallocation = true;
          } else {
            return;
          }
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.map-outer {
  width: 100%;
}
.dark-layout .tag-select-disable {
  .tag-select.disabled {
    .vs__dropdown-toggle {
      background-color: #283046 !important;
    }
  }
}
.add-device-align {
  .form-group {
    margin-bottom: 1rem !important;
  }
}
@media screen and (max-width: 991px) {
  .add-device-align {
    padding: 10px;
  }
}
.d-create {
  height: calc(100vh - 330px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
<style lang="scss">
.timezone-input {
  .vs__selected-options {
    .vs__search {
      display: none !important;
    }
  }
}
</style>
