var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":['string', 'number'].includes(_vm.data_type.toLowerCase()) &&
        _vm.value == '0' &&
        _vm.field == 'camera_type' &&
        _vm.selectedSensor === 'VIDEO_STREAM' &&
        !_vm.streamUrl
          ? 8
          : 12}},[_c('div',{staticClass:"d-flex"},[_c('v-select',{staticClass:"w-100",attrs:{"disabled":!_vm.isEditable,"options":_vm.dropValue,"reduce":function (option) { return option.value; },"clearable":!_vm.is_required,"loading":_vm.loading,"multiple":_vm.multi_select ? true : false,"label":"text"},on:{"input":_vm.updateInputState,"search":_vm.searchFilter},model:{value:(_vm.inputVal),callback:function ($$v) {_vm.inputVal=$$v},expression:"inputVal"}}),(
            ['string', 'number'].includes(_vm.data_type.toLowerCase()) &&
            _vm.value == '0' &&
            _vm.field == 'camera_type' &&
            _vm.selectedSensor === 'VIDEO_STREAM'
          )?_c('b-button',{staticClass:"ml-1 getURL",attrs:{"disabled":_vm.loading || !_vm.isEditable,"variant":"primary"},on:{"click":_vm.createLiveVideoStream}},[_vm._v(_vm._s(_vm.$t("device.GetURL"))+" "),(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1):_vm._e()],1)])],1),(
      ['string', 'number'].includes(_vm.data_type.toLowerCase()) &&
      _vm.value == '0' &&
      _vm.field == 'camera_type' &&
      _vm.selectedSensor === 'VIDEO_STREAM'
    )?_c('b-row',[_c('b-col',[_c('div',{staticClass:"d-flex flex-columns copy-element"},[(
            _vm.streamUrl &&
            ['string', 'number'].includes(_vm.data_type.toLowerCase()) &&
            _vm.value == '0' &&
            _vm.field == 'camera_type' &&
            _vm.selectedSensor === 'VIDEO_STREAM'
          )?_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.$t("device.Hostname"))+" "),_c('span',{staticClass:"text-primary",attrs:{"id":"textToCopyHostname"}},[_vm._v(_vm._s(_vm.streamUrl))]),_c('feather-icon',{staticClass:"text-primary action-icon",staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"icon":"CopyIcon","size":"18"},on:{"click":function($event){return _vm.copyText(true)}}})],1):_vm._e(),(
            _vm.streamId &&
            ['string', 'number'].includes(_vm.data_type.toLowerCase()) &&
            _vm.value == '0' &&
            _vm.field == 'camera_type' &&
            _vm.selectedSensor === 'VIDEO_STREAM'
          )?_c('span',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.$t("device.StreamKey"))+" "),_c('span',{staticClass:"text-primary",attrs:{"id":"textToCopyStreamId"}},[_vm._v(" "+_vm._s(_vm.streamId))]),_c('feather-icon',{staticClass:"text-primary action-icon",staticStyle:{"position":"relative","cursor":"pointer"},attrs:{"icon":"CopyIcon","size":"18"},on:{"click":function($event){return _vm.copyText()}}})],1):_vm._e()])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }