<template>
  <b-modal id="confirmSensors" centered size="sm" modal-class="no-header-modal">
    <b-card-text>
      <div class="d-flex justify-content-center">
        <b-avatar variant="danger" size="96" class="size-96">
          <feather-icon
            size="56"
            icon="InfoIcon
            "
          />
        </b-avatar>
      </div>

      <div class="new-invite-title">{{ $t("device.Warning") }}</div>
      <div class="d-flex">
        <div class="you-invite">
          {{ $t("device.YouWantSensor") }}
        </div>
      </div>

      <div class="d-flex justify-content-center mb-64 mtt-50">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          class="mt-2 mr-1"
          @click="saveConfirm(data)"
        >
          {{ $t("Ok") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          @click="closeConform()"
          class="mt-2"
        >
          {{ $t("user.Cancel") }}
        </b-button>
      </div>
    </b-card-text>
  </b-modal>
</template>
<script>
import { BCard, BButton, BAvatar, BCardText } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
export default {
  name: "AreYouSureModal",
  components: {
    BCard,
    BButton,
    BAvatar,
    BCardText
  },

  directives: {
    Ripple
  },
  watch: {},
  props: ["data", "closeConform", "saveConfirm"],
  data() {
    return {
      // status: false
    };
  }
};
</script>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.new-invite-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
  margin-top: 32px;
}
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.invite-name {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  font-weight: 600;
  word-break: break-all;
}
</style>
