var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"under-line-title",class:['edit-device', 'create-device', 'view-device'].includes(_vm.$route.name)
        ? _vm.$route.name === 'edit-device'
          ? 'trip-detector-h'
          : _vm.$route.name === 'create-device'
          ? 'trip-detector-s'
          : 'trip-detector-v'
        : 'trip-detector-h-w'},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1"},[_c('div',[_c('h4',{staticClass:"card-title mb-0"},[_vm._v(" "+_vm._s(_vm.$t("device.TripDetector.TripDetectorName"))+" ")])]),_c('b-button',{staticClass:"btn set-back-mobile v2-back",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function (e) {
            _vm.$router.push({ name: 'devices-list' });
          }}},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"ArrowLeftIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("back.back")))])],1)],1),_c('div',[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('b-form',{on:{"reset":function($event){$event.preventDefault();return _vm.tripDetectorReset($event)}}},[_c('div',{class:['edit-device', 'create-device', 'view-device'].includes(
                _vm.$route.name
              )
                ? _vm.$route.name === 'edit-device'
                  ? 'trip-create'
                  : _vm.$route.name === 'create-device'
                  ? 'trip-create-v'
                  : 'trip-create-h'
                : 'trip-create-c'},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.TripDetector.MovementDetection') + '  *'}},[_c('validation-provider',{attrs:{"name":"Country","rules":{
                      required: true
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"placeholder":_vm.$t('device.TripDetector.MovementDetection'),"options":_vm.tripDetectorSource,"reduce":function (option) { return option.value; },"disabled":_vm.isViewOnly,"label":"label"},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.TripDetector.MinMovingSpeed') + '  *'}},[_c('validation-provider',{attrs:{"name":"Min. Moving Speed","vid":"min_m_speed","rules":{
                      required: true,
                      min: 0
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"min_m_speed","state":errors.length > 0 ? false : null,"disabled":_vm.isViewOnly,"name":"name","placeholder":_vm.$t('device.TripDetector.MinMovingSpeed')},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.detector.min_m_speed),callback:function ($$v) {_vm.$set(_vm.detector, "min_m_speed", $$v)},expression:"detector.min_m_speed"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.TripDetector.MinParkingTime') + '  *'}},[_c('validation-provider',{attrs:{"name":"Min. Parking time","vid":"min_park_time","rules":{
                      required: true,
                      min: 0
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"min_park_time","state":errors.length > 0 ? false : null,"name":"name","disabled":_vm.isViewOnly,"placeholder":_vm.$t('device.TripDetector.MinParkingTimePlace')},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.detector.min_park_time),callback:function ($$v) {_vm.$set(_vm.detector, "min_park_time", $$v)},expression:"detector.min_park_time"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.TripDetector.MaxDistanceBetweenMessages') +
                    '  *'}},[_c('validation-provider',{attrs:{"name":"Max . Distance Between Messages","vid":"max_dist_in_msgs","rules":{
                      required: true,
                      min: 0
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"max_dist_in_msgs","disabled":_vm.isViewOnly,"state":errors.length > 0 ? false : null,"name":"name","placeholder":_vm.$t('device.TripDetector.MaxDistanceBetweenMessages')},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.detector.max_dist_in_msgs),callback:function ($$v) {_vm.$set(_vm.detector, "max_dist_in_msgs", $$v)},expression:"detector.max_dist_in_msgs"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.TripDetector.MinNumberOfSatellites') + '  *'}},[_c('validation-provider',{attrs:{"name":"Min. Number Of Satellites","vid":"min_satellites","rules":{
                      required: true,
                      min: 0
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"min_satellites","disabled":_vm.isViewOnly,"state":errors.length > 0 ? false : null,"name":"name","placeholder":_vm.$t('device.TripDetector.MinNumberOfSatellites')},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.detector.min_satellites),callback:function ($$v) {_vm.$set(_vm.detector, "min_satellites", $$v)},expression:"detector.min_satellites"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.TripDetector.MinTripDistance') + '  *'}},[_c('validation-provider',{attrs:{"name":"Min. Trip Distance","vid":"min_trip_dist","rules":{
                      required: true,
                      min: 0
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"min_trip_dist","disabled":_vm.isViewOnly,"state":errors.length > 0 ? false : null,"name":"name","placeholder":_vm.$t('device.TripDetector.MinTripDistance')},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.detector.min_trip_dist),callback:function ($$v) {_vm.$set(_vm.detector, "min_trip_dist", $$v)},expression:"detector.min_trip_dist"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.TripDetector.MaxIntervalInMessages') + '  *'}},[_c('validation-provider',{attrs:{"name":"Max. Interval In Messages","vid":"max_intrl_in_msgs","rules":{
                      required: true,
                      min: 0
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"type":"number","id":"max_intrl_in_msgs","disabled":_vm.isViewOnly,"state":errors.length > 0 ? false : null,"name":"name","placeholder":_vm.$t('device.TripDetector.MaxIntervalInMessages')},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.detector.max_intrl_in_msgs),callback:function ($$v) {_vm.$set(_vm.detector, "max_intrl_in_msgs", $$v)},expression:"detector.max_intrl_in_msgs"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.TripDetector.MinTripTime') + '  *'}},[_c('validation-provider',{attrs:{"name":"Min. Trip Time","vid":"min_trip_time","rules":{
                      min: 0,
                      required: true
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"min_trip_time","disabled":_vm.isViewOnly,"state":errors.length > 0 ? false : null,"name":"name","type":"number","placeholder":_vm.$t('device.TripDetector.MinTripTime')},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.detector.min_trip_time),callback:function ($$v) {_vm.$set(_vm.detector, "min_trip_time", $$v)},expression:"detector.min_trip_time"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{style:(!_vm.detector.gps_correct ? 'height: 79px' : ''),attrs:{"sm":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"form-top",attrs:{"disabled":_vm.isViewOnly},model:{value:(_vm.detector.gps_correct),callback:function ($$v) {_vm.$set(_vm.detector, "gps_correct", $$v)},expression:"detector.gps_correct"}},[_vm._v(_vm._s(_vm.$t("device.TripDetector.AllowGPSCorrection"))+" ")]),_c('b-form-invalid-feedback')],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.$route.name === 'edit-device')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 ml-1",attrs:{"type":"button","variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("profile.SaveChanges"))+" ")]):_vm._e()],1)])]}}])})],1),_c('Loader',{attrs:{"show":_vm.show}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }