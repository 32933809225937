<template>
  <div style="margin-top: 10px">
    <b-form-checkbox
      v-model="isChecked"
      @input="updateCheckboxState"
      :disabled="!isEditable"
    >
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  props: [
    "name",
    "desc",
    "label",
    "type",
    "component",
    "value",
    "isEditable",
    "isRequired"
  ],
  components: { BFormCheckbox },
  data() {
    return {
      isChecked: false,
      isIndeterminate: false
    };
  },
  mounted() {
    this.isChecked = this.value;
    setTimeout(() => {
      this.$emit("input", this.isChecked || false);
    }, 100);
  },
  methods: {
    updateCheckboxState() {
      this.$emit("input", this.isChecked);
    }
  }
};
</script>
