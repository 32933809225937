<template>
  <b-modal
    id="modal-execute-command"
    centered
    scrollable
    size="lg"
    :hide-header="true"
    no-close-on-esc
    no-close-on-backdrop
    modal-class=" no-header-modal"
  >
    <validation-observer ref="sensorsRef" tag="form" #default="{ invalid }">
      <div>
        <div class="slect-account-title-center">
          {{ $t("device.ExecuteCommand") }}
        </div>

        <div>
          <b-row>
            <b-col sm="12">
              <validation-provider
                #default="validationContext"
                name="command"
                rules="required"
              >
                <b-form-group :label="`${$t('device.CommandCode')}*`">
                  <v-select
                    id="vue-select"
                    label="label"
                    :clearable="false"
                    @input="handleSelectionChange"
                    :placeholder="$t('device.SelectCommands')"
                    :options="commandsList"
                    v-model="command"
                    :reduce="(option) => option.code"
                    :disabled="!isDisabled"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{
                    validationContext.errors[0]
                      ? handleError(validationContext.errors[0])
                      : ""
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col sm="12">
              <ParameterCommandsComponents
                v-if="test"
                :parameters="parameters"
                :isEditable="!isViewOnly && isDisabled"
                :sensorInfo="sensorCommand"
                v-model="sensorCommand"
                @validationError="showValidationError"
              />
            </b-col>
          </b-row>
        </div>
      </div>

      <div class="d-flex justify-content-center save-btn-footer">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          v-if="!isViewOnly && isDisabled"
          type="button"
          :disabled="invalid || !enabledCommand || !enabledCommand.code"
          @click="executeCommand(sensorCommand)"
        >
          {{ $t("device.Execute") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="button"
          class="mt-2"
          @click="close()"
        >
          {{ $t("device.Cancel") }}
        </b-button>
      </div>
      <span style="color: red">
        {{
          !enabledCommand || !enabledCommand.code
            ? command &&
              $t("device.CommandDisableInfo", {
                code: command
              })
            : ""
        }}</span
      >
    </validation-observer>
    <Loader :show="showLoading"></Loader>
  </b-modal>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import CommandService from "@/libs/api/command-service";
import formValidation from "@core/comp-functions/forms/form-validation";
import Loader from "@/layouts/components/Loader.vue";
import ParameterCommandsComponents from "@/views/DynamicComponents/ParameterCommandsComponents.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BAvatar,
  BButton,
  BForm,
  BTab,
  BTabs,
  BCard,
  BMedia,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormCheckbox,
  BTable
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,

    BButton,
    BFormGroup,
    BFormInput,
    Loader,
    BForm,
    BCardCode,
    BTab,
    BTabs,
    BCard,
    BMedia,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormCheckbox,
    vSelect,

    ParameterCommandsComponents
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      getValidationState,
      showLoading: false,
      parameters: [],
      command: "",
      isDisabled: true,
      enabledCommand: false,
      test: true,
      sensorCommand: {
        code: "",

        params: {}
      },
      timeoutId: null
    };
  },
  directives: {
    Ripple
  },

  mounted() {
    this.sensorCommand = this.commandProps
      ? this.commandProps
      : this.sensorCommand;
    this.command = this.sensorCommand && this.sensorCommand.code;
    this.selectedCommandFun(false);
  },
  props: [
    "commandProps",
    "tab",
    "saveDeviceAddCommand",
    "onCloseDeviceAddCommand",
    "deviceData",
    "isViewOnly",
    "commandsList",
    "savedCommands",
    "selectedCommandData"
  ],
  watch: {
    tab() {
      this.selectedCommandFun(false);
    },
    commandProps(val) {
      this.sensorCommand = val;
    },
    selectedCommandData(val) {
      if (val.command) {
        this.command = val.command;
        this.sensorCommand = {
          code: val.command,
          params: val.params,
          command: val.command
        };
        this.isDisabled = !val.isviewOnly;
        this.selectedCommandFun();
      } else {
        this.isDisabled = true;
      }

      // this.command = val.command;
      // this.parameters.forEach((e) => {
      //   e.field.value = val.params[e.field].value;
      // });
    }
  },
  methods: {
    close() {
      this.sensorCommand = {
        code: "",

        params: {}
      };
      this.command = "";
      this.parameters = [];
      this.$bvModal.hide("modal-execute-command");
    },
    async executeCommand() {
      try {
        const filter = {
          device_id: this.deviceData.id,
          command: this.command,
          params: this.sensorCommand.params
        };
        this.showLoading = true;
        let response = await new CommandService().executeDeviceCommand(filter);

        if (response && response.data) {
          this.showLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "",
              text: this.$t("device.CommandExecuteSuccessfully"),
              icon: "InfoIcon",
              variant: "success"
            }
          });
          this.close();
        } else if (response && response.error && response.error.message) {
          this.showLoading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },

    showValidationError(error) {
      // this.validationError = error;
    },

    handleError(error) {
      return this.formattedErrorMsg(error);
    },

    handleSelectionChange() {
      this.test = false;
      const a = this.command;
      this.sensorCommand = {
        code: "",
        params: {}
      };
      this.command = "";
      this.parameters = [];
      this.command = a;
      this.selectedCommandFun(true);
      setTimeout(() => {
        this.test = true;
      }, 100);
    },
    selectedCommandFun(isClearFiled) {
      const selectedCommand = this.commandsList.find(
        (e) => e.code == this.command
      );
      this.enabledCommand = this.savedCommands.find(
        (e) => selectedCommand && selectedCommand.code === e.code && e.enable
      );

      this.parameters = [];
      if (selectedCommand) {
        if (this.sensorCommand) {
          this.sensorCommand.command = this.command;
        }

        this.parameters = selectedCommand.params;
        if (isClearFiled) {
          // this.sensorCommand.params = {};
        }
        this.sensorCommand.code = this.command;

        this.sensorCommand.params = this.sensorCommand.params
          ? this.sensorCommand.params
          : {};
        this.parameters.forEach((e) => {
          this.sensorCommand.params[e.field] = {
            value:
              (this.sensorCommand.params[e.field] &&
                this.sensorCommand.params[e.field].value) ||
              ""
          };
          if (e.data_type.toLowerCase() === "datetime") {
            this.sensorCommand.params[e.field] = e.data_type;
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
