<template>
  <div>
    <!-- Table Container Card -->
    <div no-body class="mb-0">
      <div class="m-2-br-bottm device-sensor">
        <b-row v-if="!isPreview">
          <!-- Per Page -->

          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile-50"
            ><h4 class="card-title">{{ $t("device.Sensors") }}</h4>
          </b-col>

          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <div class="search-sensor mr-1">
              <div class="d-flex align-items-center justify-content-end">
                <div class="d-flex align-items-center desktop-hidden-search">
                  <label class="search-label d-none d-sm-inline">{{
                    $t("device.search")
                  }}</label>
                  <b-input-group class="">
                    <b-form-input
                      v-model="filter"
                      ref="name_input"
                      debounce="500"
                      class="d-inline-block search-bg"
                      :placeholder="$t('device.searchPlaceholder')"
                    ></b-form-input>
                    <b-input-group-append class="input-prefix-img search-clear"
                      ><feather-icon
                        icon="XIcon"
                        size="24"
                        @click="
                          (e) => {
                            filter = '';
                          }
                        "
                      />
                    </b-input-group-append>
                  </b-input-group>
                </div>

                <DeviceSensorPreviewModal
                  v-if="items && items.length && isPreview"
                  class="ml-5"
                  style="width: 56%"
                  :unitId="unit && unit.id"
                  :unitName="unit && unit.name"
                  :devices="deviceData && deviceData.id ? [deviceData.id] : []"
                />
                <div class="active ml-1">
                  <b-button
                    variant="outline-light"
                    size="sm"
                    :disabled="!items || !items.length"
                    class="custom-sensor-upload-button mobile-calculation-table"
                    v-b-tooltip.hover.top="$t('device.Download JSON')"
                    @click="() => downloadJson()"
                  >
                    <DownloadDark class="btn-active" />
                  </b-button>
                </div>
                <div class="active ml-1 mr-1" v-if="!isViewOnly">
                  <b-button
                    variant="outline-light"
                    size="sm"
                    :disabled="isViewOnly"
                    v-b-tooltip.hover.top="$t('device.Upload JSON')"
                    class="btn-active custom-sensor-upload-button"
                    @click="triggerFileInput"
                  >
                    <UploadDark />
                  </b-button>
                  <input
                    ref="fileInput"
                    type="file"
                    class="d-none"
                    :disabled="isViewOnly"
                    @change="handleFileChange"
                  />
                </div>
              </div>
            </div>
            <b-button
              @click="addSensor(null, 'add')"
              variant="primary"
              v-if="!isViewOnly"
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span class="d-none d-sm-inline">{{
                $t("device.AddSensors")
              }}</span>
            </b-button>
            <DeviceSensorPreviewModal
              v-if="items && items.length"
              class="ml-10"
              :devices="deviceData.id ? [deviceData.id] : []"
              :unitName="unit && unit.name"
              :unitId="unit && unit.id"
            />
            <b-button
              variant="outline-primary"
              size="sm"
              @click="
                (e) => {
                  $router.push({ name: 'devices-list' });
                }
              "
              class="btn set-back-mobile v2-back ml-10"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
            </b-button>
          </b-col>
          <div
            class="d-flex align-items-center justify-content-end w-100 p-1 mobile-hidden-search"
          >
            <label class="search-label d-none d-sm-inline">{{
              $t("device.search")
            }}</label>
            <b-input-group class="">
              <b-form-input
                v-model="filter"
                ref="name_input"
                debounce="500"
                class="d-inline-block search-bg"
                :placeholder="$t('device.searchPlaceholder')"
              ></b-form-input>
              <b-input-group-append class="input-prefix-img search-clear"
                ><feather-icon
                  icon="XIcon"
                  size="24"
                  @click="
                    (e) => {
                      filter = '';
                    }
                  "
                />
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-row>
      </div>
      <div class="m-2 m-2-2 device-list-mr" v-if="isPreview">
        <!-- Table Top -->
        <b-row class="justify-content-end">
          <!-- Per Page -->

          <!-- Search -->
          <b-col cols="12" md="12" lg="12">
            <div
              v-if="isPreview"
              class="d-flex align-items-center justify-content-end"
            >
              <div class="d-flex align-items-center justify-content-end">
                <label class="search-label">{{ $t("device.search") }}</label>
                <b-input-group class="">
                  <b-form-input
                    v-model="filter"
                    ref="name_input"
                    debounce="500"
                    class="d-inline-block search-bg"
                    :placeholder="$t('device.searchPlaceholder')"
                  ></b-form-input>
                  <b-input-group-append class="input-prefix-img search-clear"
                    ><feather-icon
                      icon="XIcon"
                      size="24"
                      @click="
                        (e) => {
                          filter = '';
                        }
                      "
                    />
                  </b-input-group-append>
                </b-input-group>
              </div>
              <DeviceSensorPreviewModal
                v-if="items && items.length && isPreview"
                class="ml-1"
                :unitName="unit && unit.name"
                :unitId="unit && unit.id"
                :devices="deviceData && deviceData.id ? [deviceData.id] : []"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <div
        :class="
          ['view-device', 'edit-device'].includes($route.name)
            ? 'edit-table-responsive'
            : 'table-responsive'
        "
        v-if="show"
      >
        <b-skeleton-table
          v-if="show"
          :rows="2"
          :columns="!isPreview ? 5 : 3"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table-simple
        v-if="!show"
        responsive
        :class="
          ['view-device', 'edit-device'].includes($route.name)
            ? 'edit-table-responsive'
            : 'table-responsive table position-relative'
        "
      >
        <b-thead head-variant="danger">
          <b-tr>
            <b-th
              :class="param.tdClass ? param.tdClass : ''"
              scope="col"
              :key="index"
              v-for="(param, index) in tableColumns"
              >{{ param.label }}</b-th
            >
          </b-tr>
        </b-thead>
        <draggable
          v-model="items"
          tag="tbody"
          handle=".draggable-task-handle"
          class="todo-task-list media-list d-sensor d-icon-sensor"
          @end="onDragEnd(true)"
        >
          <b-tr v-for="item in items" :key="item.name">
            <b-td>
              <span>
                <span class="d-flex">
                  <!-- <feather-icon
                    icon="MoreVerticalIcon"
                    
                  /> -->
                  <span
                    class="draggable-task-handle d-inline"
                    v-if="
                      !isViewOnly ||
                      checkAbility({
                        action: constants.PERMISSIONS_ACTION.CARD_ORDERING,
                        subject: constants.PERMISSIONS_MODEL.DEVICES
                      })
                    "
                  >
                    <DragDrop></DragDrop
                  ></span>
                  <span class="ml-10">{{ item.name }}</span>
                </span>
              </span>
            </b-td>
            <b-td v-if="!isPreview">
              <div class="mb-0 sim_number-col">
                <ul v-if="item.parameter_label" class="sim_number">
                  <li
                    :key="index"
                    v-for="(param, index) in item.parameter_label"
                  >
                    {{ param ? handleError(param) : "" }}
                  </li>
                </ul>
              </div></b-td
            >
            <b-td v-if="isPreview">{{ item.type }}</b-td>
            <b-td class="wrap-log-text" v-if="!isPreview">{{
              item.description
            }}</b-td>
            <b-td>
              <b-form-checkbox
                v-model="item.visible"
                @change="onDragEnd(false)"
                :disabled="isViewOnly"
              ></b-form-checkbox>
              <!-- {{
                item.visible ? $t("device.Visible") : $t("device.Invisible")
              }} -->
            </b-td>

            <!-- <b-td
              ><b-badge
                class="badge-cust"
                :variant="resolveFilterBadgeColor(item.status)"
              >
                {{ `${item.status ? `${item.status}` : " "}` }}
              </b-badge></b-td
            > -->
            <b-td v-if="!isPreview" class="cmd-action-width-device-sensor">
              <div class="text-right min-80 table-icon-d">
                <b-row class="justify-content-end">
                  <b-col
                    cols="3"
                    md="3"
                    v-if="isAllowMultiSensors(item.type) && !isViewOnly"
                  >
                    <feather-icon
                      icon="CopyIcon"
                      v-b-tooltip.hover="{
                        title: $t('tooTip.clone'),
                        customClass: 'device-tooltip-class'
                      }"
                      @click="addSensor(item, 'clone')"
                      class="text-primary action-icon"
                      size="18"
                      style="
                        position: relative;
                        margin-right: 10px;
                        cursor: pointer;
                      "
                    />
                  </b-col>
                  <b-col cols="3" md="3" v-if="isViewOnly">
                    <div
                      class="text-primary action-icon"
                      @click="addSensor(item, 'edit')"
                      v-b-tooltip.hover="{
                        title: $t('tooTip.view'),
                        customClass: 'device-tooltip-class'
                      }"
                    >
                      <feather-icon
                        icon="EyeIcon"
                        size="18"
                        style="
                          position: relative;
                          margin-right: 10px;
                          cursor: pointer;
                        "
                      />
                    </div>
                  </b-col>
                  <b-col cols="3" md="3" v-if="!isViewOnly">
                    <feather-icon
                      icon="EditIcon"
                      style="
                        position: relative;
                        margin-right: 10px;
                        cursor: pointer;
                      "
                      class="text-primary action-icon"
                      size="18"
                      @click="addSensor(item, 'edit')"
                      v-b-tooltip.hover="{
                        title: $t('tooTip.update'),
                        customClass: 'device-tooltip-class'
                      }"
                    />
                  </b-col>
                  <b-col cols="3" md="3" v-if="!isViewOnly">
                    <feather-icon
                      v-b-tooltip.hover="{
                        title: $t('tooTip.delete'),
                        customClass: 'device-tooltip-class'
                      }"
                      icon="Trash2Icon"
                      class="text-danger action-icon"
                      size="18"
                      @click="onClickClose(item)"
                      style="position: relative; cursor: pointer"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-td>
          </b-tr>
        </draggable>
      </b-table-simple>
      <!-- <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalSensors"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            ></b-pagination>
          </b-col>
        </b-row>
      </div> -->
    </div>
    <AddDeviceSensor
      :sensorProp="sensorProp"
      v-if="isAddDeviceSensors"
      :sensorsData="sensors"
      :onCloseDeviceAddSensors="onCloseDeviceAddSensors"
      :saveDeviceAddSensors="saveDeviceAddSensors"
      :refreshModal="refreshModal"
      :tab="tab"
      :deviceDataUpdated="deviceDataUpdated"
      :protocolName="protocolName"
      :device="deviceData"
      :isViewOnly="isViewOnly"
      @uploadSensor="importJson"
    ></AddDeviceSensor>
    <AreYouSureModal
      :data="deleteSensor"
      :onClose="onClose"
      :removedUser="removeSensors"
    />
    <ConfirmSensorUpload
      :data="uploadSensors"
      :closeConform="closeConform"
      :saveConfirm="saveConfirm"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BModal,
  BForm,
  BImg,
  BTooltip,
  VBTooltip,
  BSkeletonTable,
  BFormCheckbox,
  BTableSimple,
  BTr,
  BTd,
  BTh,
  BFormFile,
  BThead
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import DeviceService from "@/libs/api/device-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import ConfirmSensorUpload from "./ConfirmSensorUpload.vue";
import Loader from "@/layouts/components/Loader.vue";
import draggable from "vuedraggable";
import AddDeviceSensor from "./AddDeviceSensor.vue";
import DragDrop from "@/assets/images/icons/drag-drop.svg";
import DeviceSensorPreviewModal from "./DeviceSensorPreviewModal.vue";
import DownloadDark from "@/assets/images/new-icon/download-dark.svg";
import UploadDark from "@/assets/images/new-icon/upload-dark.svg";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    Loader,
    vSelect,
    show: false,
    BFormGroup,
    BModal,
    BForm,
    BImg,
    BTooltip,
    VBTooltip,
    BSkeletonTable,
    AddDeviceSensor,
    AreYouSureModal,
    ConfirmSensorUpload,
    BFormCheckbox,
    draggable,
    BTableSimple,
    BTr,
    BTd,
    BTh,
    DragDrop,
    BThead,
    DeviceSensorPreviewModal,
    DownloadDark,
    UploadDark,
    BFormFile
  },
  data() {
    return {
      tableColumns: !this.isPreview
        ? [
            {
              key: "name",
              label: this.$t("device.sensorTableColumn.name"),
              tdClass: "text-left"
            },
            {
              key: "parameter",
              label: this.$t("device.sensorTableColumn.parameter"),
              tdClass: "text-left"
            },
            {
              key: "description",
              label: this.$t("device.sensorTableColumn.description"),
              tdClass: "text-left"
            },
            {
              key: "visible",
              label: this.$t("device.sensorTableColumn.visible"),
              tdClass: "text-left"
            },
            {
              key: "ACTIONS",
              thClass: "cmd-action-width-device-sensor text-right",
              tdClass: "cmd-action-width-device-sensor text-right",
              label: this.$t("device.sensorTableColumn.actions")
            }
          ]
        : [
            {
              key: "name",
              label: this.$t("device.sensorTableColumn.name"),
              tdClass: "text-left"
            },
            {
              key: "type",
              label: this.$t("device.sensorTableColumn.type"),
              tdClass: "text-left"
            },
            {
              key: "visible",
              label: this.$t("device.sensorTableColumn.visible"),
              tdClass: "text-left"
            }
          ],
      showFiltermenu: false,
      tab: "add",
      inputJsonFile: [],
      uploadSensors: [],
      sensorProp: {},
      items: [],
      show: false,
      sensors: [],
      filter: "",
      perPage: 10,
      currentPage: 1,
      refreshModal: "",
      isAddDeviceSensors: false,
      deleteSensor: {},
      deviceDataUpdated: "",
      showLoading: false,
      removedSensors: [],
      totalSensors: 0,
      oldSensorProp: {},
      oldFiled: [],
      uploadJsonSensor: ""
    };
  },
  props: [
    "isViewOnly",
    "deviceData",
    "activeTripDetector",
    "activeFuelTheft",
    "DeviceSensorFun",
    "saveSensors",
    "refreshSensorsModal",
    "protocolName",
    "activeTab",
    "isEditable",
    "isPreview",
    "unit"
  ],
  directives: {
    "b-tooltip": VBTooltip
  },
  watch: {
    filter() {
      this.filterSensors();
    },
    deviceData() {
      const sensorInfo = (this.deviceData && this.deviceData.sensors) || [];
      this.getAllSensorsList(sensorInfo);
    },
    refreshSensorsModal() {
      const sensorInfo = (this.deviceData && this.deviceData.sensors) || [];
      this.getAllSensorsList(sensorInfo);
      this.removedSensors = [];
    },
    activeTab() {
      this.focusSearch();
    },
    protocolName(old, newVal) {
      if (old && old !== newVal) {
        this.getProtocolParams(true);
      }
    }
  },
  mounted() {
    this.getAllSensors(null);
    this.show = true;
    // setTimeout(() => {

    // }, 2000);
  },
  methods: {
    async getAllSensorsList(sensorInfo) {
      try {
        this.deviceDataUpdated = this.deviceData;

        this.items = sensorInfo || [];
        this.items = this.items.map((e, i) => {
          if (e.fields) {
            e.parameter = Object.keys(e.fields);
            const sensor = this.sensors.find((s) => s.type === e.type);

            e.parameter_label = [];
            if (sensor && sensor.fields && sensor.type !== "CUSTOM") {
              e.parameter_label = sensor.fields.map((e) => e.label);
              const fields = {};
              for (let i = 0; i < sensor.fields.length; i++) {
                const element = sensor.fields[i];

                fields[element.field] = {};
                fields[element.field].source = element.source;
                fields[element.field].table =
                  e.fields?.[element.field]?.table || [];
                fields[element.field].table_info =
                  e.fields?.[element.field]?.table_info || {};
                if (e.fields[element.field] && e.fields[element.field].source) {
                  fields[element.field].value = e.fields[element.field].value;
                }
              }
              e.fields = fields;
            } else if (sensor && sensor.type === "CUSTOM") {
              Object.keys(e.fields).forEach((param) => {
                e.parameter_label.push(e.fields[param].label);
              });
            }

            e.parameter_label = e.parameter_label.filter((e) => e);
          }
          e.index = i + 1;
          return e;
        });
        this.oldItems = this.items;
        const gpsSensors = this.items.find(
          (e) => e.type.toLowerCase() === "gps"
        );
        if (gpsSensors && gpsSensors.type) {
          this.activeTripDetector(true);
        }
        const fuelSensors = this.items.find(
          (e) => e.type.toLowerCase() === "fuel"
        );
        if (fuelSensors && fuelSensors.type) {
          this.activeFuelTheft(true);
        }
        this.totalSensors = this.items.length;
        this.focusSearch();
        this.show = false;
        setTimeout(() => {
          this.isAddDeviceSensors = true;
        }, 1000);
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    validateRows(rows) {
      let i = rows.length - 1;
      if (i === -1) {
        return true;
      }
      for (let z = 0; z <= i; z++) {
        delete rows[z].invalid;
        delete rows[z].invalid_reason_x;
        delete rows[z].invalid_reason_y;
        rows[z].x =
          (rows[z].x !== "" && Number(this.validateNumber(rows[z].x))) ||
          rows[z].x;
        rows[z].y =
          (rows[z].y !== "" && Number(this.validateNumber(rows[z].y))) ||
          rows[z].y;
      }
      for (let j = i; j >= 0; j--) {
        if (rows[j].x === "" || rows[j].x === false) {
          rows[j].invalid = true;
          rows[j].invalid_reason_x = this.$t("device.InvalidValue");
          return true;
          return;
        }
        if (rows[j].y === "" || rows[j].y === false) {
          rows[j].invalid = true;

          rows[j].invalid_reason_y = this.$t("device.InvalidValue");
          return true;
        }
        if (j - 1 >= 0 && rows[j].x <= rows[j - 1].x) {
          rows[j].invalid = true;
          rows[j].invalid_reason_x = this.$t("device.XGreaterThan");
          return true;
        }
        if (j - 1 >= 0 && rows[j].y <= rows[j - 1].y) {
          rows[j].invalid = true;
          rows[j].invalid_reason_y = this.$t("device.YGreaterThan");
          return true;
        }
      }
      // this.$emit("input", rows);

      return false;
    },
    validateNumber(value) {
      // Assuming range.x is a string, convert it to a number
      let input = parseFloat(value);

      // Check if the value is a valid number
      if (!isNaN(input)) {
        // Limit the number of decimal places to 2
        let roundedValue = parseFloat(input.toFixed(2));

        // Update the model with the rounded value
        return roundedValue;
      } else {
        return 0;
      }

      // Additional validation logic goes here
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    focusSearch() {
      if (this.$refs && this.$refs.name_input && window.innerWidth >= 768) {
        this.$refs.name_input.focus();
      }
    },
    filterSensors() {
      if (
        this.filter === "" ||
        !this.filter ||
        (this.filter && !this.filter.trim())
      ) {
        this.items = this.oldItems; // lowecase
      } else {
        const searchParam = this.filter.trim();
        const search = new RegExp(searchParam, "i"); // prepare a regex object
        this.items = this.oldItems.filter((item) => search.test(item.name));
      }
      if (this.removedSensors && this.removedSensors.length) {
        this.items = this.items.filter(
          (e) => !this.removedSensors.includes(e.name)
        );
      }
      this.totalSensors = this.items.length;
    },
    async getAllSensors(search) {
      try {
        this.showLoading = true;
        const filter = search ? { search_sensor: search } : {};
        let response = await new DeviceService().getAllSensors(filter);

        this.showLoading = false;
        if (response && response.data) {
          this.sensors = response.data.sensors;
          const sensorInfo = (this.deviceData && this.deviceData.sensors) || [];
          this.getAllSensorsList(sensorInfo);
          this.focusSearch();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    addSensor(item, page) {
      this.sensorProp = { ...item };

      this.tab = page;
      if (page === "edit") {
        this.sensorProp.isEditable = true;
      }
      if (page === "clone") {
        this.sensorProp.name = this.sensorProp.name + "clone";
        if (this.sensorProp && this.sensorProp.index) {
          this.sensorProp.index = null;
        }
      }
      this.oldSensorProp = Object.assign({}, this.sensorProp);
      if (page === "edit" || page === "clone") {
        this.oldFiled = Object.assign({}, this.sensorProp.fields);
      }
      this.isAddDeviceSensors = true;
      this.refreshModal = new Date() + "";

      this.$bvModal.show("modal-add-sensors");
    },
    onCloseDeviceAddSensors() {
      this.sensorProp = {};
      // this.sensorProp = this.oldSensorProp;
      // this.sensorProp["fields"] = this.oldFiled;
      this.refreshModal = new Date() + "";
      this.$bvModal.hide("modal-add-sensors");
      this.focusSearch();
    },
    async onDragEnd(flag) {
      const resp = await this.DeviceSensorFun(this.items);
      if (!resp) {
        this.items = this.oldItems;
      }
      if (
        this.$route.name === "view-device" ||
        this.$route.name === "edit-device" ||
        this.$route.name === "add-new-device-id"
      ) {
        this.saveSensors("edit");
      }
    },
    async saveDeviceAddSensors(sensorData) {
      this.uploadJsonSensor = "";
      const data = JSON.parse(JSON.stringify(sensorData));
      data.parameter =
        (sensorData && sensorData.fields && Object.keys(sensorData.fields)) ||
        [];
      let removeCustom = "";
      data.parameter.forEach((e) => {
        if (data.fields[e].isHide) {
          removeCustom = e;
        }
        delete data.fields[e]["isHide"];
        if (
          data.fields[e].data_type &&
          data.fields[e].data_type.toLowerCase() === "datetime" &&
          data.fields[e].value
        ) {
          data.fields[e].value = new Date(data.fields[e].value);
        }

        if (data && data.type !== "CUSTOM") {
          delete data.fields[e]["label"];
        }
        // if (data.fields[e].data_type) {
        //   delete data.fields[e]["data_type"];
        // }
      });
      if (removeCustom && data.fields[removeCustom]) {
        delete data.fields[removeCustom];
      }
      const checkDuplicate = this.items.filter(
        (e) =>
          e.name &&
          data.name &&
          e.name.toLowerCase() === data.name.toLowerCase()
      );

      const checkDuplicateType = this.items.filter(
        (e) =>
          e.type &&
          data.type &&
          e.type.toLowerCase() === data.type.toLowerCase()
      );

      if (
        checkDuplicate &&
        checkDuplicate.length > 0 &&
        data &&
        data["index"] == "" &&
        this.tab != "edit"
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            text: "",
            title: "Sensor Name Already Exists ",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      } else if (
        (checkDuplicate &&
          checkDuplicate.length > 0 &&
          data &&
          data["index"] != checkDuplicate[0].index) ||
        (checkDuplicate && checkDuplicate.length > 1)
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            text: "",
            title: "Sensor Name Already Exists ",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      } else if (
        (data.multi_sensors !== true &&
          checkDuplicateType &&
          checkDuplicateType.length > 0 &&
          data &&
          data["index"] != checkDuplicateType[0].index) ||
        (data.multi_sensors !== true &&
          checkDuplicateType &&
          checkDuplicateType.length > 1)
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Not allowed multiple ${data.type}  sensors`,
            text: "",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return false;
      } else if (
        checkDuplicateType &&
        checkDuplicateType.length > 0 &&
        data &&
        data["index"] == "" &&
        this.tab != "edit" &&
        data.multi_sensors !== true
      ) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Not allowed multiple ${data.type}  sensors`,
            text: "",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        return false;
      } else {
        const findIndex = this.items.filter(
          (e) => e.index === data.index && data.index !== ""
        );
        if (findIndex && findIndex.length) {
          this.items = this.items.map((e) => {
            if (e.index === data.index) {
              e = data;
            }
            return e;
          });
        } else if (this.tab != "edit") {
          const index = Math.max(...this.items.map((o) => o.index));
          data.index = index + 1;
          this.items.push(data);
        }
        this.DeviceSensorFun(this.items);
        return new Promise((resolve) => {
          setTimeout(async () => {
            let resp = false;
            if (
              this.$route.name === "edit-device" ||
              this.$route.name === "add-new-device-id"
            ) {
              this.removedSensors = [];
              resp = await this.saveSensors(this.tab, false, data.type);
            }
            if (resp) {
              this.$bvModal.hide("modal-add-sensors");
              this.focusSearch();
            }
            resolve(resp);
          }, 200);
        });
      }
      this.focusSearch();
    },
    removeSensors(data) {
      this.items = this.items.filter(
        (e) => e.name.toLowerCase() !== data.name.toLowerCase()
      );
      this.removedSensors.push(data.name);
      this.DeviceSensorFun(this.items);
      setTimeout(() => {
        this.saveSensors("remove");
      }, 100);
      this.$bvModal.hide("modal-sm-remove");
      this.$bvModal.hide("modal-add-sensors");
      this.focusSearch();
      // this.getAllSensorsList();
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove");
      this.focusSearch();
    },
    onClickClose(data) {
      this.deleteSensor = data;
      setTimeout(() => {
        this.$bvModal.show("modal-sm-remove");
      }, 100);

      this.focusSearch();
    },
    isAllowMultiSensors(type) {
      const SensorsData = this.sensors.find((e) => e.type === type);
      if (SensorsData && SensorsData.multi_sensors) {
        return true;
      } else {
        return false;
      }
    },
    importJson(range = false) {
      if (range?.uploadJson == "uploadJson") {
        this.inputJsonFile = range.inputJsonFile;
        this.uploadJsonSensor = range.uploadJson;
      }

      if (this.inputJsonFile && this.inputJsonFile.name.includes(".json")) {
        const reader = new FileReader();
        reader.onload = (res) => {
          this.parseRangeJson(res.target.result);
        };
        reader.onerror = (err) => console.log(err);
        reader.readAsText(this.inputJsonFile);
      }
    },
    triggerFileInput() {
      if (!this.isViewOnly) {
        this.$refs.fileInput.click();
      }
    },
    handleFileChange(event) {
      this.inputJsonFile = event.target.files[0];
      this.importJson(true);
    },
    async getProtocolParams(updateProtocol) {
      try {
        if (!this.protocolName) {
          this.protocolParamsList = [];
          return;
        }
        const protocolParamsStore = this.$store.state.device.parameters;
        if (
          protocolParamsStore &&
          protocolParamsStore.length &&
          !updateProtocol
        ) {
          this.protocolParamsList = protocolParamsStore;
          return;
        }
        this.oldProtocolName = this.protocolName;

        this.showLoading = true;
        let response = await new DeviceService().getProtocolParams({
          protocol: this.protocolName
        });

        this.showLoading = false;
        if (response && response.data) {
          this.protocolParamsList = response.data;
          store.commit("device/setProtocolList", this.protocolParamsList);
          // this.setProtocolList({ ...this.protocolParamsList });
        } else if (response && response.error && response.error.message) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: response.error.title,
          //     text: response.error.message,
          //     icon: "InfoIcon",
          //     variant: "danger"
          //   }
          // });
        }
      } catch (err) {
        this.showLoading = false;
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: err.message,
        //     icon: "InfoIcon",
        //     variant: "danger"
        //   }
        // });
      }
    },
    async parseRangeJson(content) {
      try {
        let sensorsData = JSON.parse(content);
        if (!Array.isArray(sensorsData)) {
          sensorsData = [sensorsData];
        }
        if (
          !sensorsData ||
          !sensorsData.length ||
          !Array.isArray(sensorsData)
        ) {
          throw {
            message: this.$t("device.InvalidSensorUpload")
          };
        }
        const filterParams = sensorsData.flatMap((filter) => filter.type);
        const allSensors = this.sensors.map((e) => e.type);
        filterParams.forEach((e) => {
          if (!allSensors.includes(e)) {
            this.inputJsonFile = [];
            throw {
              message: this.$t("device.SensorTypeNotAvailable")
            };
          }
        });
        const filteredArray = this.sensors.filter((sensor) => {
          return filterParams && filterParams.includes(sensor.type);
        });
        let counts = {};
        let duplicates = [];

        if (filterParams) {
          for (let i = 0; i < filterParams.length; i++) {
            let element = filterParams[i];
            if (counts[element] === undefined) {
              counts[element] = 1;
            } else {
              counts[element]++;
            }
            if (counts[element] === 2) {
              duplicates.push(element);
            }
          }
          try {
            sensorsData.map((d) => {
              filteredArray &&
                filteredArray.map((s) => {
                  if (!d.name || !d.name.trim().length) {
                    throw {
                      message: this.$t("device.SensorNameRequired")
                    };
                  } else if (d.name && d.name.trim().length <= 1) {
                    throw {
                      message: this.$t("device.SensorNameLength")
                    };
                  } else if (!d.type || !d.type.trim().length) {
                    throw {
                      message: this.$t("device.SensorTypeRequired")
                    };
                  } else if (d.type && d.type.trim().length <= 1) {
                    throw {
                      message: this.$t("device.SensorTypeLength")
                    };
                  } else if (typeof d.visible !== "boolean") {
                    throw {
                      message: this.$t("device.SensorVisibleType")
                    };
                  } else if (
                    typeof d.fields !== "object" ||
                    Array.isArray(d.fields) ||
                    d.fields === null
                  ) {
                    throw {
                      message: this.$t("device.SensorFiledType")
                    };
                  }
                  if (s.type == d.type) {
                    if (s.multi_sensors == false) {
                      let arr = duplicates && duplicates.includes(d.type);
                      if (arr) {
                        throw {
                          message: this.$t("device.MultipleSensorsNotAllowed", {
                            type: d.type
                          })
                        };
                      }
                    }
                    const requiredFields = s.fields
                      .filter(
                        (field) =>
                          field.is_required && typeof field.field === "string"
                      )
                      .map((field) => field.field);
                    const uploadedFields = Object.keys(d.fields);
                    const missingField = requiredFields.filter(
                      (element) => !uploadedFields.includes(element)
                    );
                    if (missingField.length > 0) {
                      throw {
                        message: this.$t("device.RequiredField", {
                          type: missingField[0]
                        })
                      };
                    }
                    s.fields.map((f) => {
                      for (const key in d.fields) {
                        if (d.type.toUpperCase() == "CUSTOM") {
                          continue;
                        }
                        if (
                          d.type != "CUSTOM" &&
                          !s.fields.map((e) => e.field).includes(key)
                        ) {
                          throw {
                            message: this.$t("device.SensorParamInValidFiled", {
                              field: key,
                              name: d.name
                            })
                          };
                        }
                        if (
                          !["config", "input", "calc"].includes(
                            d.fields[key].source
                          )
                        ) {
                          throw {
                            message: this.$t("device.InvalidSource", {
                              field: key,
                              name: d.name,
                              source: d.fields[key].source
                            })
                          };
                        }
                        if (f.field == key) {
                          let dataType = typeof d.fields[key].value;
                          if (
                            f.data_type.toLowerCase() === "number" &&
                            dataType.toLowerCase() !== "number"
                          ) {
                            if (Number(d.fields[key].value) >= 0) {
                              d.fields[key].value = Number(d.fields[key].value);
                              dataType = "number";
                            }
                          }
                          if (
                            f.data_type.toLowerCase() === "string" &&
                            !isNaN(Number(d.fields[key].value))
                          ) {
                            throw {
                              message: this.$t("device.InValidDataType", {
                                field: key,
                                name: d.type,
                                dataType: f.data_type.toLowerCase()
                              })
                            };
                          }

                          if (
                            f.is_required &&
                            d.fields[key].value == "" &&
                            !(d.type == "CUSTOM" && key == "params")
                          ) {
                            throw {
                              message: this.$t(
                                "device.SensorParamFiledRequired",
                                {
                                  field: key,
                                  name: d.name
                                }
                              )
                            };
                          } else if (
                            f.source == "config" &&
                            f.field == key &&
                            d.fields[key].value &&
                            f.is_required === true
                          ) {
                            if (
                              dataType.toLowerCase() !==
                              f.data_type.toLowerCase()
                            ) {
                              throw {
                                message: this.$t("device.InValidDataType", {
                                  field: key,
                                  name: d.type,
                                  dataType: f.data_type.toLowerCase()
                                })
                              };
                            }
                          } else if (
                            f.source == "config" &&
                            f.field == key &&
                            d.fields[key].value
                          ) {
                            if (
                              f.component_value &&
                              !f.component_value
                                .map((e) => e.value)
                                .includes(d.fields[key].value)
                            ) {
                              throw {
                                message: this.$t(
                                  "device.SensorParamInValidValue",
                                  {
                                    field: key,
                                    name: d.name
                                  }
                                )
                              };
                            }
                          } else if (
                            f.source == "input" &&
                            f.field == key &&
                            d.fields[key].value
                          ) {
                            // console.log(this.protocolParamsList);
                            // const checkInputType = this.protocolParamsList.find(
                            //   (e) =>
                            //     e.type &&
                            //     e.type.toUpperCase() ==
                            //       f.data_type.toUpperCase() &&
                            //     e.property === d.fields[key].value
                            // );
                            // if (!checkInputType || !checkInputType.property) {
                            //   throw {
                            //     message: this.$t(
                            //       "device.SensorParamFiledDataType",
                            //       {
                            //         field: key,
                            //         name: d.name
                            //       }
                            //     )
                            //   };
                            // }
                          }
                        }
                      }
                    });
                  }
                });
            });
            const valueArr = sensorsData.map(function (item) {
              return item.name;
            });
            const duplicateNames = [];
            const isDuplicateName = valueArr.some(function (name, idx) {
              if (
                valueArr.indexOf(name) !== idx &&
                !duplicateNames.includes(name)
              ) {
                duplicateNames.push(name);
                return true;
              }
              return false;
            });
            if (isDuplicateName) {
              throw {
                message: this.$t("device.SensorDuplicateParamsName", {
                  field: duplicateNames
                })
              };
            }
            this.uploadSensors = JSON.parse(content);
            if (this.uploadJsonSensor == "uploadJson") {
              this.addSensor(sensorsData[0], "add");
              this.uploadJsonSensor = "";
              this.inputJsonFile = [];
            } else {
              this.$bvModal.show("confirmSensors");
            }
            this.inputJsonFile = [];
          } catch (err) {
            this.inputJsonFile = [];
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.message,
                icon: "InfoIcon",
                variant: "danger"
              }
            });
          }
        }
      } catch (err) {
        this.inputJsonFile = [];
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    validateRangeRows(ranges) {
      if (ranges.length === 0) {
        return true;
      }
      for (let i = ranges.length - 1; i >= 0; i--) {
        const isFirst = i == 0;
        delete ranges[i].invalid;
        delete ranges[i].invalid_reason_x;
        delete ranges[i].invalid_reason_y;
        delete ranges[i].invalid_reason_z;
        ranges[i].x =
          (ranges[i].x !== "" && Number(this.validateNumber(ranges[i].x))) ||
          ranges[i].x;
        ranges[i].y =
          (ranges[i].y !== "" && Number(this.validateNumber(ranges[i].y))) ||
          ranges[i].y;
        ranges[i].z =
          (ranges[i].z !== "" && Number(this.validateNumber(ranges[i].z))) ||
          ranges[i].z;
        if (ranges[i].x > ranges[i].y) {
          ranges[i].invalid = true;
          ranges[i].invalid_reason_x = this.$t("device.XlessThanY");
          return true;
        }
        if (ranges[i].x === "" || ranges[i].x === false) {
          ranges[i].invalid = true;
          ranges[i].invalid_reason_x = this.$t("device.InvalidValue");
          return true;
        }
        if (ranges[i].y === "" || ranges[i].y === false) {
          ranges[i].invalid = true;
          ranges[i].invalid_reason_y = this.$t("device.InvalidValue");
          return true;
        }
        if (ranges[i].z === "" || ranges[i].z === false) {
          ranges[i].invalid = true;
          ranges[i].invalid_reason_z = this.$t("device.InvalidValue");
          return true;
        }
        for (let j = i - 1; j >= 0; j--) {
          if (
            (ranges[i].x <= ranges[j].x && ranges[i].y >= ranges[j].y) ||
            (ranges[i].x >= ranges[j].x && ranges[i].y <= ranges[j].y) ||
            (ranges[i].x <= ranges[j].x && ranges[i].y >= ranges[j].x) ||
            (ranges[i].x <= ranges[j].y && ranges[i].y >= ranges[j].y)
          ) {
            ranges[i].invalid = true;
            ranges[i].invalid_reason_x = ranges[i].invalid_reason_y = this.$t(
              "device.Overlapping",
              { name: j + 1 }
            );
            ranges[j].invalid = true;
            ranges[j].invalid_reason_x = ranges[j].invalid_reason_y = this.$t(
              "device.Overlapping",
              { name: i + 1 }
            );
            return true;
          }
        }
        if (ranges[i].z === "") {
          return true;
        }
      }
      return false;
    },
    closeConform() {
      this.$bvModal.hide("confirmSensors");
    },
    async saveConfirm(sensorsData) {
      const resp = await this.DeviceSensorFun(sensorsData);
      if (!resp) {
        this.items = this.oldItems;
      }
      if (
        this.$route.name === "view-device" ||
        this.$route.name === "edit-device" ||
        this.$route.name === "add-new-device-id"
      ) {
        this.saveSensors("edit");
      }
      this.$bvModal.hide("confirmSensors");
      this.getAllSensorsList(sensorsData);
    },
    downloadJson() {
      let filterData = this.items;
      filterData = filterData.map((e) => {
        const param = {
          name: e.name,
          type: e.type,
          fields: e.fields,
          visible: e.visible || false,
          description: e.description
        };
        return param;
      });
      const blob = new Blob([JSON.stringify(filterData)], {
        type: "application/json"
      });
      const link = document.createElement("a");
      link.download = `sensor_${new Date().getTime()}.json`;
      link.href = window.URL.createObjectURL(blob);
      link.click();
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-sensor-upload-container {
  display: inline-block;
  position: relative;
}

.custom-sensor-upload-button {
  cursor: pointer;
}
.min-80 {
  min-height: 95px;
  display: flex;
  align-items: center;
  .col-md-3 {
    min-width: 28px;
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
.disabled-check-box {
  pointer-events: none;
}
.wrap-log-text {
  overflow-wrap: anywhere;
}
.cmd-action-width-device-sensor {
  width: 190px;
  min-width: 190px;
  max-width: 190px;
}
.add-icon-s {
  vertical-align: middle !important;
  margin-right: 5px;
}
@media only screen and (max-width: 600px) {
  .device-sensor {
    padding: 10px !important;
  }
}
.custom-sensor-upload-button {
  padding: 0.3rem 1.5rem;
  position: relative;
  cursor: pointer;
  .custom-file-input.b-form-file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: $percent_100;
    height: $percent_100;
    //opacity: 0;
    cursor: pointer;
  }
  .custom-file-label {
    cursor: pointer;
  }
}
@media only screen and (max-width: 440px) {
  .desktop-hidden-search {
    display: none !important;
  }
}
@media only screen and (min-width: 440px) {
  .mobile-hidden-search {
    display: none !important;
  }
}
.d-sensor {
  overflow-y: auto;
  max-height: calc(100vh - 340px) !important;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
.edit-table-responsive {
  overflow-y: auto;
  height: calc(100vh - 250px) !important;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
.table-responsive {
  height: calc(100vh - 340px);
}
@media only screen and (max-width: 600px) {
  .edit-table-responsive {
    overflow-y: auto;
    height: calc(100vh - 370px) !important;
  }
  .table-responsive {
    height: calc(100vh - 421px);
  }
}
.table-icon-d {
  display: grid !important;
}
.d-icon-sensor {
  ul {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
}
.custom-sensor-upload-button .custom-file-input.b-form-file {
  opacity: 0.1;
}
</style>
