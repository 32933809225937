var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dynamic-parameter"},[_vm._l((_vm.inputVal),function(parameter,index){return _c('b-row',{key:index,staticClass:"value-row mb-1"},[_c('b-col',{staticClass:"column",attrs:{"cols":"12"}},[_c('b-row',{staticClass:"flex-class"},[_c('b-col',{staticClass:"dy-m-40 dy-pr-10",attrs:{"sm":"3"}},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(parameter.label ? parameter.label : parameter.field && _vm.formattedString(parameter.field))+" ")])]),_c('b-col',{staticClass:"dy-m-27 dy-pr-10",attrs:{"sm":"5"}},[_c('DeviceProtocolParametersModal',{attrs:{"isViewOnly":!_vm.isEditable,"protocolName":_vm.protocolName,"parameter":parameter,"sensorField":{
              field: parameter.field,
              data_type: parameter.data_type,
              is_required: false
            }},model:{value:(parameter.value),callback:function ($$v) {_vm.$set(parameter, "value", $$v)},expression:"parameter.value"}})],1),_c('b-col',{staticClass:"dy-m-27 dy-pr-10",attrs:{"sm":"1"}},[(
              parameter.data_type &&
              parameter.data_type.toLowerCase() == 'number'
            )?_c('div',{staticClass:"sensor-check ml-1"},[_c('b-form-checkbox',{staticClass:"disabled-check-box",attrs:{"checked":parameter.table && parameter.table.length ? true : false}})],1):_vm._e()]),_c('b-col',{staticClass:"dy-m-10 dy-pr-10 apply-table",attrs:{"sm":"3"}},[_c('div',{staticClass:"calculationTable"},[_c('CalculationTableModal',{attrs:{"isViewOnly":!_vm.isEditable,"sensorField":{
                field: parameter.field,
                data_type: parameter.data_type
              },"keyField":parameter.field},model:{value:(parameter.table),callback:function ($$v) {_vm.$set(parameter, "table", $$v)},expression:"parameter.table"}})],1)])],1)],1)],1)}),_c('CustomComponentsModal',{attrs:{"protocolName":_vm.protocolName,"sensors":_vm.value,"sensorsData":_vm.sensorsData,"sensorInfo":_vm.sensorInfo},on:{"addCustomSensor":_vm.addCustomSensor}}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(true)?_c('a',{staticClass:"add-new-line",on:{"click":this.addNewRow}},[_vm._v("+ "+_vm._s(_vm.$t("device.AddCustomSensor")))]):_vm._e()])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }