var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:['edit-device', 'create-device', 'view-device'].includes(_vm.$route.name)
        ? _vm.$route.name === 'edit-device'
          ? 'FuelTheft-w'
          : _vm.$route.name === 'create-device'
          ? 'FuelTheft-s'
          : 'FuelTheft-v'
        : 'FuelTheft-h-w'},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1"},[_c('div',[_c('h4',{staticClass:"card-title mb-0"},[_vm._v(" "+_vm._s(_vm.$t("device.fuelTheft.FuelConfig"))+" ")])]),_c('b-button',{staticClass:"btn set-back-mobile v2-back",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function (e) {
            _vm.$router.push({ name: 'devices-list' });
          }}},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"ArrowLeftIcon","size":"16"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v(_vm._s(_vm.$t("back.back")))])],1)],1),_c('div',[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('b-form',{on:{"reset":function($event){$event.preventDefault();return _vm.fuelConfigReset($event)}}},[_c('b-row',{staticClass:"fuel-con"},[_c('b-col',{staticClass:"mb-1 desktop-60-height",style:(!_vm.fuelConfig.fuel_theft.enable ? 'height: 79px' : ''),attrs:{"sm":"12","lg":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"desktop-30-top",attrs:{"disabled":_vm.isViewOnly},model:{value:(_vm.fuelConfig.fuel_theft.enable),callback:function ($$v) {_vm.$set(_vm.fuelConfig.fuel_theft, "enable", $$v)},expression:"fuelConfig.fuel_theft.enable"}},[_vm._v(_vm._s(_vm.$t("device.fuelTheft.Enable"))+" ")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.fuelTheft.KML') + ' *'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.fuelTheft.KML'),"vid":"km_per_liter","rules":{
                    required: _vm.fuelConfig.fuel_theft.enable,
                    min_value: 0.01,
                    multiple_value_zero_one: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"type":"number","id":"km_per_liter","disabled":_vm.isViewOnly || !_vm.fuelConfig.fuel_theft.enable,"state":errors.length > 0 ? false : null,"name":"name","min":"0"},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.fuelConfig.fuel_theft.km_per_liter),callback:function ($$v) {_vm.$set(_vm.fuelConfig.fuel_theft, "km_per_liter", $$v)},expression:"fuelConfig.fuel_theft.km_per_liter"}}),_vm._v(" "+_vm._s(_vm.$t("device.fuelTheft.KMLDesc"))+" "),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.fuelTheft.KMHour') + ' *'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.fuelTheft.KMHour'),"vid":"hour_per_liter","rules":{
                    required: _vm.fuelConfig.fuel_theft.enable,
                    min_value: 0.01,
                    multiple_value_zero_one: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"type":"number","id":"hour_per_liter","disabled":_vm.isViewOnly || !_vm.fuelConfig.fuel_theft.enable,"state":errors.length > 0 ? false : null,"name":"name","min":"0"},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.fuelConfig.fuel_theft.hour_per_liter),callback:function ($$v) {_vm.$set(_vm.fuelConfig.fuel_theft, "hour_per_liter", $$v)},expression:"fuelConfig.fuel_theft.hour_per_liter"}}),_vm._v(" "+_vm._s(_vm.$t("device.fuelTheft.KMHourDesc"))+" "),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.fuelTheft.MinTheftVolume') + ' *'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.fuelTheft.MinTheftVolume'),"vid":"min_theft","rules":{
                    required: _vm.fuelConfig.fuel_theft.enable,
                    min_value: 1,
                    multiple_value_zero_one: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"type":"number","id":"min_theft","disabled":_vm.isViewOnly || !_vm.fuelConfig.fuel_theft.enable,"state":errors.length > 0 ? false : null,"name":"name","min":"0"},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.fuelConfig.fuel_theft.min_theft),callback:function ($$v) {_vm.$set(_vm.fuelConfig.fuel_theft, "min_theft", $$v)},expression:"fuelConfig.fuel_theft.min_theft"}}),_vm._v(" "+_vm._s(_vm.$t("device.fuelTheft.MinTheftVolumeDesc"))+" "),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.fuelTheft.ObservationTime') + ' *'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.fuelTheft.ObservationTime'),"vid":"observe_time","rules":{
                    required: _vm.fuelConfig.fuel_theft.enable,
                    min_value: 30,
                    multiple_value_one: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"type":"number","id":"observe_time","disabled":_vm.isViewOnly || !_vm.fuelConfig.fuel_theft.enable,"state":errors.length > 0 ? false : null,"name":"name","min":"0"},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.fuelConfig.fuel_theft.observe_time),callback:function ($$v) {_vm.$set(_vm.fuelConfig.fuel_theft, "observe_time", $$v)},expression:"fuelConfig.fuel_theft.observe_time"}}),_vm._v(" "+_vm._s(_vm.$t("device.fuelTheft.ObservationTimeDesc"))+" "),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('div',{staticClass:"vr"})]),_c('b-col',{staticClass:"mb-1 desktop-60-height",style:(!_vm.fuelConfig.enable ? 'height: 79px' : ''),attrs:{"sm":"12","lg":"12"}},[_c('b-form-group',[_c('b-form-checkbox',{staticClass:"desktop-30-top",attrs:{"disabled":true},model:{value:(_vm.fuelConfig.fuel_refill.enable),callback:function ($$v) {_vm.$set(_vm.fuelConfig.fuel_refill, "enable", $$v)},expression:"fuelConfig.fuel_refill.enable"}},[_vm._v(_vm._s(_vm.$t("device.FuelRefill.Enable"))+" ")])],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.FuelRefill.MinRefillVolume') + ' *'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.FuelRefill.MinRefillVolume'),"vid":"min_threshold","rules":{
                    required: true,
                    min_value: 1,
                    multiple_value_zero_one: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"type":"number","id":"min_threshold","disabled":_vm.isViewOnly,"state":errors.length > 0 ? false : null,"name":"name","min_value":"0"},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.fuelConfig.fuel_refill.min_refill),callback:function ($$v) {_vm.$set(_vm.fuelConfig.fuel_refill, "min_refill", $$v)},expression:"fuelConfig.fuel_refill.min_refill"}}),_vm._v(" "+_vm._s(_vm.$t("device.FuelRefill.MinRefillVolumeDesc"))+" "),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{class:_vm.fuelConfig.fuel_refill.enable,attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.FuelRefill.ObservationTime') + ' *'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.FuelRefill.ObservationTime'),"vid":"max_threshold","rules":{
                    required: true,
                    min_value: 30,
                    multiple_value_one: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"type":"number","id":"max_threshold","disabled":_vm.isViewOnly,"state":errors.length > 0 ? false : null,"name":"name","min":"0"},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.fuelConfig.fuel_refill.observe_time),callback:function ($$v) {_vm.$set(_vm.fuelConfig.fuel_refill, "observe_time", $$v)},expression:"fuelConfig.fuel_refill.observe_time"}}),_vm._v(" "+_vm._s(_vm.$t("device.FuelRefill.ObservationTimeDesc"))+" "),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('device.FuelRefill.minChange') + ' *'}},[_c('validation-provider',{attrs:{"name":_vm.$t('device.FuelRefill.minChange'),"vid":"min_change","rules":{
                    required: true,
                    min_value: 1,
                    multiple_value_one: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",class:errors.length > 0 ? 'is-invalid' : null,attrs:{"type":"number","id":"min_change","disabled":_vm.isViewOnly,"state":errors.length > 0 ? false : null,"name":"name","min_value":"0"},on:{"input":function($event){return _vm.detectorChange()}},model:{value:(_vm.fuelConfig.fuel_refill.min_change),callback:function ($$v) {_vm.$set(_vm.fuelConfig.fuel_refill, "min_change", $$v)},expression:"fuelConfig.fuel_refill.min_change"}}),_vm._v(" "+_vm._s(_vm.$t("device.FuelRefill.minChangeDesc"))+" "),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end"},[(_vm.$route.name === 'edit-device')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"type":"button","variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("profile.SaveChanges"))+" ")]):_vm._e()],1)],1)],1)]}}])})],1),_c('Loader',{attrs:{"show":_vm.show}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }