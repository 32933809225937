<template>
  <b-modal
    id="modal-add-command"
    centered
    scrollable
    size="lg"
    :hide-header="true"
    no-close-on-esc
    no-close-on-backdrop
    modal-class=" no-header-modal"
  >
    <validation-observer ref="sensorsRef" tag="form" #default="{ invalid }">
      <div>
        <div class="slect-account-title-center">
          {{
            tab == "add" || tab == "clone"
              ? $t("device.NewCommand")
              : isViewOnly
              ? $t("device.ViewCommand")
              : $t("device.UpdateCommand")
          }}
        </div>
        <div>
          <b-row>
            <b-col sm="12">
              <validation-provider
                #default="validationContext"
                name="command"
                rules="required"
              >
                <span>{{ $t("device.CommandCode") }} *</span>
                <b-form-group class="mt-1">
                  <v-select
                    :class="tab === 'update' || isViewOnly ? 'disabled' : ''"
                    id="vue-select"
                    :disabled="isViewOnly"
                    label="label"
                    :clearable="false"
                    @input="handleSelectionChange"
                    @search="searchCommands"
                    :placeholder="$t('device.SelectCommands')"
                    :options="commandsList"
                    v-model="command"
                    :reduce="(option) => option.code"
                  />
                </b-form-group>
                <b-form-invalid-feedback>
                  {{
                    validationContext.errors[0]
                      ? handleError(validationContext.errors[0])
                      : ""
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col sm="12">
              <b-form-group :label="`${$t('device.Description')}`">
                <b-form-textarea
                  id="textarea"
                  rows="1"
                  :disabled="isViewOnly"
                  v-model="sensorCommand.description"
                  max-rows="2"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col sm="12" class="check-btm-area mt-1">
              <b-form-checkbox
                v-model="sensorCommand.enable"
                :disabled="isViewOnly"
                >{{ $t("device.Enabled") }}</b-form-checkbox
              >
            </b-col>
            <b-col sm="12">
              <ParameterCommandsComponents
                :parameters="parameters"
                :isEditable="!isViewOnly"
                :sensorInfo="sensorCommand"
                v-model="sensorCommand"
                @validationError="showValidationError"
              />
            </b-col>
            <!-- <b-col
              sm="12"
              v-for="sensorField in parameters"
              :key="sensorField.field"
            >
              <b-row
                v-if="
                  sensorField.source == 'config' &&
                  [
                    'number',
                    'string',
                    'boolean',
                    'timestamp',
                    'datetime'
                  ].includes(sensorField.data_type.toLowerCase())
                "
              >
                <b-col sm="3" class="tip-27-device pr-0">
                  <span>
                    {{ sensorField.label }}
                   
                  </span>
                </b-col>
                <b-col sm="9" class="tip-17-device">
              
                  <b-form-group>
                    <b-form-input
                      v-if="
                        ['number', 'string'].includes(
                          sensorField.data_type.toLowerCase()
                        )
                      "
                      :disabled="isViewOnly"
                      v-model="sensorCommand['params'][sensorField.field].value"
                      class="d-inline-block open-tel-parama"
                      :type="
                        sensorField.data_type.toLowerCase() == 'number'
                          ? 'number'
                          : 'text'
                      "
                    />
                    <b-form-checkbox
                      v-if="sensorField.data_type.toLowerCase() == 'boolean'"
                      :disabled="isViewOnly"
                      v-model="sensorCommand['params'][sensorField.field].value"
                      style="top: 10px"
                    >
                    </b-form-checkbox>
                    <div
                      class="flat-picker-date"
                      v-if="
                        ['timestamp', 'datetime'].includes(
                          sensorField.data_type.toLowerCase()
                        )
                      "
                    >
                      <flat-pickr
                        :disabled="isViewOnly"
                        v-model="
                          sensorCommand['params'][sensorField.field].value
                        "
                        @on-close="
                          DateTimeFormat(
                            sensorField.field,
                            sensorCommand['params'][sensorField.field].value
                          )
                        "
                        :ref="`dateTime_${sensorField.field}`"
                        class="form-control date-time-input"
                        :config="{
                          enableTime: true,
                          time_24hr: true
                        }"
                      />
                      <div class="flat-picker-icon">
                        <a
                          ><feather-icon
                            icon="XIcon"
                            size="24"
                            @click="clearDate(sensorField.field)"
                        /></a>
                      </div>
                    </div>
                  </b-form-group>
                
                </b-col>
              </b-row>
            </b-col> -->
          </b-row>
        </div>
      </div>
      <div class="d-flex justify-content-center save-btn-footer">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          v-if="!isViewOnly"
          type="button"
          :disabled="invalid"
          @click="saveDeviceAddCommand(sensorCommand)"
        >
          {{ $t("device.Save") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="button"
          class="mt-2"
          @click="onCloseDeviceAddCommand()"
        >
          {{ $t("device.Cancel") }}
        </b-button>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";
import DeviceService from "@/libs/api/device-service";
import formValidation from "@core/comp-functions/forms/form-validation";
import ParameterCommandsComponents from "@/views/DynamicComponents/ParameterCommandsComponents.vue";
import flatPickr from "vue-flatpickr-component";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BAvatar,
  BButton,
  BForm,
  BTab,
  BTabs,
  BCard,
  BMedia,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormCheckbox,
  BTable
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BImg,
    BForm,
    BCardCode,
    BTab,
    BTabs,
    BCard,
    BMedia,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BTable,
    ParameterCommandsComponents
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      getValidationState,
      // commandsList: [],

      parameters: [],
      command: "",
      sensorCommand: {
        code: "",
        description: "",
        enable: true,
        mapping: "",
        index: "",
        params: {}
      },
      timeoutId: null
    };
  },
  directives: {
    Ripple
  },

  mounted() {
    // this.getAllCommands(null);
    this.sensorCommand = this.commandProps
      ? this.commandProps
      : this.sensorCommand;
    this.command = this.sensorCommand && this.sensorCommand.code;
    this.selectedCommandFun(false);
  },
  props: [
    "commandProps",
    "tab",
    "saveDeviceAddCommand",
    "onCloseDeviceAddCommand",
    "deviceData",
    "isViewOnly",
    "commandsList"
  ],
  watch: {
    deviceData() {
      this.debounce(500);
    },
    tab() {
      this.debounce(500);
      this.getCommandInfo();
      this.selectedCommandFun(false);
    },
    commandProps(val) {
      this.sensorCommand = val;
      this.getCommandInfo();
    }
  },
  methods: {
    debounce(func, delay) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllCommands("allList");
      }, delay);
    },
    getCommandInfo() {
      // let arr = [];
      // this.commandProps.params.forEach((e) => {
      //   const data = {};
      //   data[e["field"]] = { value: e.value || "" };
      //   arr.push(data);
      // });
      const commandPropsData = JSON.parse(JSON.stringify(this.commandProps));
      this.sensorCommand = {
        code: (commandPropsData && commandPropsData.code) || "",
        description: (commandPropsData && commandPropsData.description) || "",
        enable: commandPropsData && commandPropsData.enable,
        mapping: (commandPropsData && commandPropsData.mapping) || "",
        index: commandPropsData && commandPropsData.index,
        params: (commandPropsData && commandPropsData.params) || ""
      };
      this.command = this.sensorCommand.code;
      this.selectedCommandFun(false);
    },
    showValidationError(error) {
      // this.validationError = error;
    },
    clearDate(field) {
      if (
        this.$refs["dateTime_" + field] &&
        this.$refs["dateTime_" + field].length
      ) {
        this.$refs["dateTime_" + field][0].fp.clear();
      }
    },
    searchCommands(search) {
      this.getAllCommands(search);
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.unitRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    onCloseDeviceAddSensors() {
      this.parameters = [];
      this.$bvModal.hide("modal-add-command");
    },

    async getAllCommands(search) {
      return;
      try {
        if (search === "allList") {
          search = "";
        }

        this.showLoading = true;

        let modelId;
        if (
          this.deviceData &&
          (this.deviceData.model || this.deviceData.model_id)
        ) {
          modelId = this.deviceData.model || this.deviceData.model_id;
        } else {
          return;
        }
        const filter = search
          ? { search_sensor: search, id: modelId }
          : { id: modelId };
        let response = await new DeviceService().getAllCommands(filter);
        this.showLoading = false;
        if (response && response.data) {
          this.commandsList = response.data.commands;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    saveDeviceAddSensors() {
      this.$bvModal.hide("modal-add-command");
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    DateTimeFormat(filed, value) {
      this.sensorCommand["params"][filed].value = new Date(value);
    },
    handleSelectionChange() {
      this.selectedCommandFun(true);
    },
    selectedCommandFun(isClearFiled) {
      const selectedCommand = this.commandsList.find(
        (e) => e.code == this.command
      );
      this.parameters = [];
      if (selectedCommand) {
        if (this.sensorCommand) {
          this.sensorCommand.command = this.command;
        }

        this.parameters = selectedCommand.params;
        if (isClearFiled) {
          this.sensorCommand.params = {};
        }
        this.sensorCommand.code = this.command;

        this.sensorCommand.params = this.sensorCommand.params
          ? this.sensorCommand.params
          : {};
        this.parameters.forEach((e) => {
          this.sensorCommand.params[e.field] = {
            value:
              (this.sensorCommand.params[e.field] &&
                this.sensorCommand.params[e.field].value) ||
              ""
          };
          if (e.data_type.toLowerCase() === "datetime") {
            this.sensorCommand.params[e.field] = e.data_type;
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.flat-picker-date {
  display: flex;
  justify-content: center;
  align-items: center;
  .form-control {
    border-radius: 0.357rem 0rem 0rem 0.357rem;
  }
}
.flat-picker-icon {
  border-right: 1px solid var(--gray4);
  border-top: 1px solid var(--gray4);
  border-bottom: 1px solid var(--gray4);
  border-radius: 0rem 0.357rem 0.357rem 0rem;
  padding: 0.438rem 1rem;
}
</style>
