<template>
  <div>
    <div
      :class="
        ['edit-device', 'create-device', 'view-device'].includes($route.name)
          ? $route.name === 'edit-device'
            ? 'FuelTheft-w'
            : $route.name === 'create-device'
            ? 'FuelTheft-s'
            : 'FuelTheft-v'
          : 'FuelTheft-h-w'
      "
    >
      <div class="d-flex justify-content-between align-items-center mb-1">
        <div>
          <h4 class="card-title mb-0">
            {{ $t("device.fuelTheft.FuelConfig") }}
          </h4>
        </div>

        <b-button
          variant="outline-primary"
          size="sm"
          @click="
            (e) => {
              $router.push({ name: 'devices-list' });
            }
          "
          class="btn set-back-mobile v2-back"
        >
          <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
        </b-button>
      </div>
      <div>
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form @reset.prevent="fuelConfigReset">
            <b-row class="fuel-con">
              <b-col
                sm="12"
                lg="12"
                :style="!fuelConfig.fuel_theft.enable ? 'height: 79px' : ''"
                class="mb-1 desktop-60-height"
              >
                <b-form-group>
                  <b-form-checkbox
                    :disabled="isViewOnly"
                    v-model="fuelConfig.fuel_theft.enable"
                    class="desktop-30-top"
                    >{{ $t("device.fuelTheft.Enable") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group :label="$t('device.fuelTheft.KML') + ' *'">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.fuelTheft.KML')"
                    vid="km_per_liter"
                    :rules="{
                      required: fuelConfig.fuel_theft.enable,
                      min_value: 0.01,
                      multiple_value_zero_one: true
                    }"
                  >
                    <b-form-input
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      type="number"
                      @input="detectorChange()"
                      id="km_per_liter"
                      :disabled="isViewOnly || !fuelConfig.fuel_theft.enable"
                      v-model="fuelConfig.fuel_theft.km_per_liter"
                      :state="errors.length > 0 ? false : null"
                      class="form-control"
                      name="name"
                      min="0"
                    />

                    {{ $t("device.fuelTheft.KMLDesc") }}
                    <br />
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group :label="$t('device.fuelTheft.KMHour') + ' *'">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.fuelTheft.KMHour')"
                    vid="hour_per_liter"
                    :rules="{
                      required: fuelConfig.fuel_theft.enable,
                      min_value: 0.01,
                      multiple_value_zero_one: true
                    }"
                  >
                    <b-form-input
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      type="number"
                      @input="detectorChange()"
                      id="hour_per_liter"
                      :disabled="isViewOnly || !fuelConfig.fuel_theft.enable"
                      v-model="fuelConfig.fuel_theft.hour_per_liter"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="name"
                      min="0"
                    />

                    {{ $t("device.fuelTheft.KMHourDesc") }}
                    <br />
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  :label="$t('device.fuelTheft.MinTheftVolume') + ' *'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.fuelTheft.MinTheftVolume')"
                    vid="min_theft"
                    :rules="{
                      required: fuelConfig.fuel_theft.enable,
                      min_value: 1,
                      multiple_value_zero_one: true
                    }"
                  >
                    <b-form-input
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      type="number"
                      @input="detectorChange()"
                      id="min_theft"
                      :disabled="isViewOnly || !fuelConfig.fuel_theft.enable"
                      v-model="fuelConfig.fuel_theft.min_theft"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="name"
                      min="0"
                    />
                    {{ $t("device.fuelTheft.MinTheftVolumeDesc") }}
                    <br />
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  :label="$t('device.fuelTheft.ObservationTime') + ' *'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.fuelTheft.ObservationTime')"
                    vid="observe_time"
                    :rules="{
                      required: fuelConfig.fuel_theft.enable,
                      min_value: 30,
                      multiple_value_one: true
                    }"
                  >
                    <b-form-input
                      type="number"
                      @input="detectorChange()"
                      id="observe_time"
                      :disabled="isViewOnly || !fuelConfig.fuel_theft.enable"
                      v-model="fuelConfig.fuel_theft.observe_time"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="name"
                      min="0"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    />

                    {{ $t("device.fuelTheft.ObservationTimeDesc") }}
                    <br />
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="12" sm="12">
                <div class="vr"></div>
              </b-col>
              <b-col
                sm="12"
                lg="12"
                :style="!fuelConfig.enable ? 'height: 79px' : ''"
                class="mb-1 desktop-60-height"
              >
                <b-form-group>
                  <b-form-checkbox
                    v-model="fuelConfig.fuel_refill.enable"
                    class="desktop-30-top"
                    :disabled="true"
                    >{{ $t("device.FuelRefill.Enable") }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  :label="$t('device.FuelRefill.MinRefillVolume') + ' *'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.FuelRefill.MinRefillVolume')"
                    vid="min_threshold"
                    :rules="{
                      required: true,
                      min_value: 1,
                      multiple_value_zero_one: true
                    }"
                  >
                    <b-form-input
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      type="number"
                      @input="detectorChange()"
                      id="min_threshold"
                      :disabled="isViewOnly"
                      v-model="fuelConfig.fuel_refill.min_refill"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="name"
                      min_value="0"
                    />
                    {{ $t("device.FuelRefill.MinRefillVolumeDesc") }}
                    <br />
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6" :class="fuelConfig.fuel_refill.enable">
                <b-form-group
                  :label="$t('device.FuelRefill.ObservationTime') + ' *'"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.FuelRefill.ObservationTime')"
                    vid="max_threshold"
                    :rules="{
                      required: true,
                      min_value: 30,
                      multiple_value_one: true
                    }"
                  >
                    <b-form-input
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      type="number"
                      @input="detectorChange()"
                      id="max_threshold"
                      :disabled="isViewOnly"
                      v-model="fuelConfig.fuel_refill.observe_time"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="name"
                      min="0"
                    />

                    {{ $t("device.FuelRefill.ObservationTimeDesc") }}
                    <br />
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group :label="$t('device.FuelRefill.minChange') + ' *'">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.FuelRefill.minChange')"
                    vid="min_change"
                    :rules="{
                      required: true,
                      min_value: 1,
                      multiple_value_one: true
                    }"
                  >
                    <b-form-input
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      type="number"
                      @input="detectorChange()"
                      id="min_change"
                      :disabled="isViewOnly"
                      v-model="fuelConfig.fuel_refill.min_change"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="name"
                      min_value="0"
                    />
                    {{ $t("device.FuelRefill.minChangeDesc") }}
                    <br />
                    <small class="text-danger">{{
                      errors[0] ? handleError(errors[0]) : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="d-flex justify-content-end">
                <b-button
                  type="button"
                  variant="primary"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :disabled="invalid"
                  class="mt-2"
                  v-if="$route.name === 'edit-device'"
                  @click="save()"
                >
                  {{ $t("profile.SaveChanges") }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <Loader :show="show" />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import constants from "@/utils/constants";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,

    BCard,
    BInputGroup,
    BButton,
    Loader,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormInvalidFeedback,
    BFormCheckbox
  },
  data() {
    return {
      show: false,
      fuelConfig: JSON.parse(
        JSON.stringify(constants.FUEL_THEFT_DEFAULT_VALUE_NEW)
      ),
      showCol: false
    };
  },
  directives: {
    Ripple
  },
  mounted() {
    this.fuelConfig.fuel_theft =
      this.deviceData &&
      this.deviceData.config &&
      this.deviceData.config.fuel_theft &&
      Object.keys(this.deviceData.config.fuel_theft).length
        ? this.deviceData.config.fuel_theft
        : this.fuelConfig.fuel_theft;
    this.fuelConfig.fuel_refill =
      this.deviceData &&
      this.deviceData.config &&
      this.deviceData.config.fuel_refill &&
      Object.keys(this.deviceData.config.fuel_refill).length
        ? this.deviceData.config.fuel_refill
        : this.fuelConfig.fuel_refill;

    this.FuelTheftPropFun(this.fuelConfig);
  },
  computed() {},
  props: ["deviceData", "isViewOnly", "FuelTheftPropFun", "saveFuelTheft"],
  watch: {
    deviceData() {
      this.fuelConfig.fuel_theft =
        this.deviceData &&
        this.deviceData.config &&
        this.deviceData.config.fuel_theft &&
        Object.keys(this.deviceData.config.fuel_theft).length
          ? this.deviceData.config.fuel_theft
          : this.fuelConfig.fuel_theft;
      this.fuelConfig.fuel_refill =
        this.deviceData &&
        this.deviceData.config &&
        this.deviceData.config.fuel_refill &&
        Object.keys(this.deviceData.config.fuel_refill).length
          ? this.deviceData.config.fuel_refill
          : this.fuelConfig.fuel_refill;
      this.detectorChange();
    },

    fuelConfig: {
      handler(val) {
        this.FuelTheftPropFun(val);
      },
      deep: true
    }
  },
  methods: {
    redirectList() {},
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    save() {
      this.FuelTheftPropFun(this.fuelConfig);
      this.saveFuelTheft();
    },
    detectorChange() {
      this.FuelTheftPropFun(this.fuelConfig);
    }
  }
};
</script>

<style lang="scss" scoped>
.desktop-60-height {
  height: 60px;
}
.desktop-30-top {
  top: 30px;
}
@import "@core/scss/vue/libs/vue-select.scss";
@media only screen and (max-width: 600px) {
  .FuelTheft-w,
  .fuel-con,
  .FuelTheft-h-w {
    padding: 10px !important;
  }
  .fuel-con {
    .col-sm-6,
    .col-sm-12 {
      padding-right: 0rem;
      padding-left: 0rem;
    }
  }
  .desktop-60-height {
    height: 25px !important;
  }
  .desktop-30-top {
    top: 0px !important;
  }
}
.vr {
  width: 100%;
  height: 1.5px;
  background-color: #c8bebe;
  margin-top: 10px;
}
.fuel-con {
  height: calc(100vh - 339px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}
.edit-FuelTheft-w {
  .fuel-con {
    height: calc(100vh - 320px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
