<template>
  <b-container class="calculation-table">
    <h3 class="text-center">{{ $t("device.SensorTable") }}</h3>
    <b-form-group class="form-radio-class" v-slot="{ ariaDescribedby }">
      <b-form-radio
        v-model="selected_tab"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        value="XYPair"
        >{{ $t("device.XYPair") }}</b-form-radio
      >
      <b-form-radio
        v-model="selected_tab"
        :aria-describedby="ariaDescribedby"
        name="some-radios"
        value="RangeTable"
        @input="rangeTableSelected"
        >{{ $t("device.RangeTable") }}</b-form-radio
      >
    </b-form-group>
    <hr />
    <b-row v-if="selected_tab === 'XYPair'">
      <b-col class="table-section" cols="6">
        <b-container class="no-padding-0">
          <b-row class="calculation-table-title-row">
            <b-col cols="8"
              ><h3 class="mobile-calculation-table">
                {{ $t("CalculationTable") }}
              </h3></b-col
            >
            <b-col cols="2" self-align="right" class="active">
              <b-button
                variant="outline-light"
                size="sm"
                class="custom-upload-button mobile-calculation-table"
                :disabled="isViewOnly"
                v-b-tooltip.hover.top="$t('device.Download CSV')"
                @click="() => downloadCsv()"
              >
                <DownloadDark class="btn-active" />
              </b-button>
            </b-col>
            <b-col cols="2" self-align="right" class="active">
              <b-button
                variant="outline-light"
                size="sm"
                :disabled="isViewOnly"
                v-b-tooltip.hover.top="$t('device.Upload CSV')"
                class="btn-active custom-upload-button"
              >
                <UploadDark />
                <b-form-file
                  class="custom-file-input"
                  v-if="!isViewOnly"
                  v-model="inputCsvFile"
                  @input="() => importCsv()"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
        <b-container class="no-pd-mobbile">
          <b-row class="header-row">
            <b-col class="column col-width"
              ><b-form-input readonly value="X"
            /></b-col>
            <b-col class="column col-width"
              ><b-form-input readonly value="Y"
            /></b-col>
            <b-col
              class="column align-items-center d-flex pl-1 mobile-delete-button"
              cols="1"
            >
              <span v-b-tooltip.hover.top="$t('tooTip.deleteAll')">
                <feather-icon
                  icon="Trash2Icon"
                  color="red"
                  v-if="!isViewOnly"
                  size="22"
                  :class="!rows.length ? 'disabled' : 'curserPointer'"
                  @click="() => removeAllRecord()" /></span
            ></b-col>
          </b-row>
        </b-container>
        <b-container class="table-rows np-padding-row">
          <b-row class="value-row" v-for="(row, index) of rows" :key="index">
            <b-col class="column col-width"
              ><b-form-input
                type="number"
                :disabled="isViewOnly"
                autofocus
                v-model="row.x"
                @input="validateRows"
                :state="row.x === '' ? null : !row.invalid"
                v-b-tooltip.hover.top="
                  (row.invalid_reason_x && row.invalid_reason_x) || ''
                "
            /></b-col>
            <b-col class="column col-width"
              ><b-form-input
                type="number"
                v-model="row.y"
                :disabled="isViewOnly"
                @input="validateRows"
                :state="row.y === '' ? null : !row.invalid"
                v-b-tooltip.hover.top="
                  (row.invalid_reason_y && row.invalid_reason_y) || ''
                "
            /></b-col>
            <b-col
              class="column align-items-center d-flex pl-1 mobile-delete-button"
              cols="1"
            >
              <feather-icon
                icon="Trash2Icon"
                color="red"
                v-if="!isViewOnly"
                class="curserPointer"
                size="22"
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                @click="() => removeRow(index)"
              />
            </b-col>
          </b-row>
        </b-container>
        <b-row>
          <b-col cols="12">
            <a
              class="add-new-line"
              @click="this.addNewRow"
              v-if="!isViewOnly"
              >{{ $t("device.AddPair") }}</a
            >
          </b-col>
        </b-row>
      </b-col>
      <b-col class="graph-section" cols="6">
        <app-echart-line :option-data="chartData" />
      </b-col>
    </b-row>
    <b-row v-if="selected_tab === 'RangeTable'">
      <b-col class="table-section" cols="6">
        <b-container class="no-padding-0">
          <b-row class="calculation-table-title-row">
            <b-col cols="8"
              ><h3 class="mobile-calculation-table">
                {{ $t("CalculationTable") }}
              </h3></b-col
            >
            <b-col cols="2" self-align="right" class="active">
              <b-button
                variant="outline-light"
                size="sm"
                class="custom-upload-button mobile-calculation-table"
                :disabled="isViewOnly"
                v-b-tooltip.hover.top="$t('device.Download CSV')"
                @click="() => downloadRangeCsv()"
              >
                <DownloadDark class="btn-active" />
              </b-button>
            </b-col>
            <b-col cols="2" self-align="right" class="active">
              <b-button
                variant="outline-light"
                size="sm"
                :disabled="isViewOnly"
                v-b-tooltip.hover.top="$t('device.Upload CSV')"
                class="btn-active custom-upload-button"
              >
                <UploadDark />
                <b-form-file
                  class="custom-file-input"
                  v-if="!isViewOnly"
                  v-model="inputCsvFile"
                  @input="() => importCsv(true)"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-container>
        <b-container class="no-pd-mobbile">
          <b-row class="header-row">
            <b-col class="column col-width-1"
              ><b-form-input readonly value="X"
            /></b-col>
            <b-col class="column col-width-1"
              ><b-form-input readonly value="Y"
            /></b-col>
            <b-col class="column col-width-1"
              ><b-form-input readonly value="Z"
            /></b-col>
            <b-col
              class="column align-items-center d-flex pl-1 mobile-delete-button"
              cols="1"
              ><span v-b-tooltip.hover.top="$t('tooTip.deleteAll')"
                ><feather-icon
                  icon="Trash2Icon"
                  color="red"
                  v-if="!isViewOnly"
                  :class="!ranges.length ? 'disabled' : 'curserPointer'"
                  size="22"
                  @click="() => removeAllRangesRecord()" /></span
            ></b-col>
          </b-row>
        </b-container>
        <b-container class="table-rows np-padding-row">
          <b-row
            class="value-row"
            v-for="(range, index) of ranges"
            :key="index"
          >
            <b-col class="column col-width-1"
              ><b-form-input
                type="number"
                :disabled="isViewOnly"
                autofocus
                v-model="range.x"
                @input="validateRangeRows"
                :state="range.x === '' ? null : !range.invalid"
                v-b-tooltip.hover.top="
                  index == ranges.length - 1 && range.invalid_reason_x
                    ? range.invalid_reason_x
                    : ''
                "
            /></b-col>
            <b-col class="column col-width-1"
              ><b-form-input
                type="number"
                v-model="range.y"
                :disabled="isViewOnly"
                @input="validateRangeRows"
                :state="range.y === '' ? null : !range.invalid"
                v-b-tooltip.hover.top="
                  index == ranges.length - 1 && range.invalid_reason_y
                    ? range.invalid_reason_y
                    : ''
                "
            /></b-col>
            <b-col class="column col-width-1"
              ><b-form-input
                type="number"
                v-model="range.z"
                :disabled="isViewOnly"
                @input="validateRangeRows"
                :state="
                  range.z === '' && !range.invalid_reason_z
                    ? null
                    : !range.invalid
                "
                v-b-tooltip.hover.top="
                  index == ranges.length - 1 && range.invalid_reason_z
                    ? range.invalid_reason_z
                    : ''
                "
            /></b-col>
            <b-col
              class="column align-items-center d-flex pl-1 mobile-delete-button"
              cols="1"
              ><feather-icon
                icon="Trash2Icon"
                color="red"
                v-if="!isViewOnly"
                class="curserPointer"
                size="22"
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                @click="() => removeRangeRow(index)"
            /></b-col>
          </b-row>
        </b-container>
        <b-row>
          <b-col cols="12">
            <a
              class="add-new-line"
              @click="this.addNewRangeRow"
              v-if="!isViewOnly"
              >{{ $t("device.AddPair") }}</a
            >
          </b-col>
        </b-row>
      </b-col>
      <b-col class="graph-section" cols="6">
        <!-- <e-chart :options="chartOptions" style="height: 300px"></e-chart> -->
        <app-echart-bar :option-data="chartOptions" />
        <!-- <vue-apex-charts type="bar" :options="chartOptions" :series="series" /> -->
        <!-- <app-echart-line :option-data="chartData" /> -->
      </b-col>
    </b-row>

    <b-container class="bound-limits-row no-padding-0">
      <b-row>
        <b-col cols="12">
          <span v-if="selected_tab === 'XYPair'">
            {{ $t("device.XYPairMessage") }}
          </span>
          <span v-if="selected_tab === 'RangeTable'">
            {{ $t("device.RangeMessage") }}
          </span>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BAlert,
  BButton,
  BFormFile,
  BCardText,
  BImg,
  VBTooltip,
  BTabs,
  BTab,
  BFormRadio
} from "bootstrap-vue";
import AppEchartLine from "@core/components/charts/echart/AppEchartLine.vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";
import DownloadDark from "@/assets/images/new-icon/download-dark.svg";
import UploadDark from "@/assets/images/new-icon/upload-dark.svg";
import VueApexCharts from "vue-apexcharts";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";

export default {
  name: "CalculationTable",
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    AppEchartLine,
    AppEchartBar,
    BAlert,
    BButton,
    BFormFile,
    BCardText,
    BImg,
    DownloadDark,
    UploadDark,
    VBTooltip,
    BTabs,
    BTab,
    BFormRadio,
    VueApexCharts,
    "e-chart": ECharts
  },
  computed: {
    chartData() {
      const result = { xAxisData: [], series: [] };
      this.rows.forEach((row) => {
        if (row.x && row.y) {
          result.xAxisData.push(row.x);
          result.series.push(row.y);
        }
      });
      return result;
    },
    chartOptions() {
      const result = {
        xAxis: {
          type: "category",
          data: []
        },
        yAxis: {
          type: "value"
        },
        series: [
          {
            data: [],
            type: "bar"
          }
        ],
        grid: {
          containLabel: true,
          left: "2%",
          right: "0%",
          top: "2%",
          bottom: "0%",
          containLabel: true
        }
      };
      result.xAxis.data = [];
      result.series[0].data = [];
      this.ranges &&
        this.ranges.forEach((item) => {
          if (item.x < item.y) {
            result.series[0].data.push(item.z);
            result.xAxis.data.push(`${item.x}-${item.y}`);
          }
        });
      return result;
    }
  },
  data() {
    return {
      inputCsvFile: [],
      upperBound: "",
      lowerBound: "",
      rows: [],
      selected_tab: "XYPair",
      ranges: []
    };
  },
  mounted() {
    if (this.value && this.value.length) {
      if (this.value[0].z !== undefined) {
        this.ranges = this.value;
        this.selected_tab = "RangeTable";
        this.validateRangeRows();
      } else {
        this.rows = this.value;
        this.selected_tab = "XYPair";
        this.validateRows();
        this.$emit("radioButtonChanged", this.selected_tab);
      }
    } else {
      this.selected_tab = "XYPair";
      this.validateRows();
      this.$emit("radioButtonChanged", this.selected_tab);
    }
  },
  props: ["value", "isViewOnly"],
  directives: {
    "b-tooltip": VBTooltip
  },
  watch: {
    value: {
      immediate: true,
      handler(newProps) {
        if (newProps) {
          if (newProps && newProps[0] && newProps[0].z !== undefined) {
            this.ranges = newProps;
          } else if (newProps && newProps[0] && newProps[0].x !== undefined) {
            this.rows = newProps || [];
          }
        }
      }
    },
    selected_tab() {
      this.$emit("radioButtonChanged", this.selected_tab);
      if (this.selected_tab === "XYPair") {
        this.validateRows();
        this.$emit("rowsChanges", JSON.parse(JSON.stringify(this.rows)));
      } else {
        this.validateRangeRows();
        this.$emit("rangesChanges", JSON.parse(JSON.stringify(this.ranges)));
      }
    }
  },

  methods: {
    rangeTableSelected() {
      // console.log("the tab selected:", this.selected_tab);
      // if (this.selected_tab === "RangeTable" && !this.ranges.length) {
      //   this.addNewRangeRow();
      // }
    },
    removeAllRangesRecord() {
      this.ranges = [];
      this.clearOtherSegment(true);
      this.sendDisabledFlag(false);
      this.$emit("rangesChanges", JSON.parse(JSON.stringify(this.ranges)));
      this.ranges = JSON.parse(JSON.stringify(this.ranges));
    },
    removeAllRecord() {
      this.rows = [];
      this.clearOtherSegment();
      this.sendDisabledFlag(false);
      this.$emit("rowsChanges", JSON.parse(JSON.stringify(this.rows)));
      this.rows = JSON.parse(JSON.stringify(this.rows));
    },
    validateNumber(value) {
      // Assuming range.x is a string, convert it to a number
      let input = parseFloat(value);

      // Check if the value is a valid number
      if (!isNaN(input)) {
        // Limit the number of decimal places to 2
        let roundedValue = parseFloat(input.toFixed(2));

        // Update the model with the rounded value
        return roundedValue;
      } else {
        return 0;
      }

      // Additional validation logic goes here
    },
    sendDisabledFlag(disabled) {
      this.$emit("flagChanged", disabled);
    },
    clearOtherSegment(rows = false) {
      // if (rows) {
      //   this.rows = [];
      // } else {
      //   this.ranges = [];
      // }
    },
    validateRangeRows() {
      // if (this.ranges.length === 0) {
      //   this.sendDisabledFlag(true);
      //   return;
      // }
      for (let i = this.ranges.length - 1; i >= 0; i--) {
        const isFirst = i == 0;
        delete this.ranges[i].invalid;
        delete this.ranges[i].invalid_reason_x;
        delete this.ranges[i].invalid_reason_y;
        delete this.ranges[i].invalid_reason_z;
        this.ranges[i].x =
          (this.ranges[i].x !== "" &&
            Number(this.validateNumber(this.ranges[i].x))) ||
          this.ranges[i].x;
        this.ranges[i].y =
          (this.ranges[i].y !== "" &&
            Number(this.validateNumber(this.ranges[i].y))) ||
          this.ranges[i].y;
        this.ranges[i].z =
          (this.ranges[i].z !== "" &&
            Number(this.validateNumber(this.ranges[i].z))) ||
          this.ranges[i].z;
        if (this.ranges[i].x > this.ranges[i].y) {
          this.ranges[i].invalid = true;
          this.ranges[i].invalid_reason_x = this.$t("device.XlessThanY");
          this.sendDisabledFlag(true);
          return;
        }
        if (this.ranges[i].x === "" || this.ranges[i].x === false) {
          this.ranges[i].invalid = true;
          this.ranges[i].invalid_reason_x = this.$t("device.InvalidValue");
          this.sendDisabledFlag(true);
          return;
        }
        if (this.ranges[i].y === "" || this.ranges[i].y === false) {
          this.ranges[i].invalid = true;
          this.ranges[i].invalid_reason_y = this.$t("device.InvalidValue");
          this.sendDisabledFlag(true);
          return;
        }
        if (this.ranges[i].z === "" || this.ranges[i].z === false) {
          this.ranges[i].invalid = true;
          this.ranges[i].invalid_reason_z = this.$t("device.InvalidValue");
          this.sendDisabledFlag(true);
          return;
        }
        for (let j = i - 1; j >= 0; j--) {
          if (
            (this.ranges[i].x <= this.ranges[j].x &&
              this.ranges[i].y >= this.ranges[j].y) ||
            (this.ranges[i].x >= this.ranges[j].x &&
              this.ranges[i].y <= this.ranges[j].y) ||
            (this.ranges[i].x <= this.ranges[j].x &&
              this.ranges[i].y >= this.ranges[j].x) ||
            (this.ranges[i].x <= this.ranges[j].y &&
              this.ranges[i].y >= this.ranges[j].y)
          ) {
            this.ranges[i].invalid = true;
            this.ranges[i].invalid_reason_x = this.ranges[i].invalid_reason_y =
              this.$t("device.Overlapping", { name: j + 1 });
            this.ranges[j].invalid = true;
            this.ranges[j].invalid_reason_x = this.ranges[j].invalid_reason_y =
              this.$t("device.Overlapping", { name: i + 1 });
            this.sendDisabledFlag(true);
            return;
          }
        }
        if (this.ranges[i].z === "") {
          this.ranges[i].invalid = true;
          this.ranges[i].invalid_reason_z = this.$t("device.InvalidValue");
          this.sendDisabledFlag(true);
          return;
        }
      }
      this.$emit("rangesChanges", JSON.parse(JSON.stringify(this.ranges)));
      this.sendDisabledFlag(false);
      this.ranges = JSON.parse(JSON.stringify(this.ranges));
      this.clearOtherSegment(true);
    },
    validateRows() {
      let i = this.rows.length - 1;
      if (i === -1) {
        // this.sendDisabledFlag(true);
        // return;
      }
      for (let z = 0; z <= i; z++) {
        delete this.rows[z].invalid;
        delete this.rows[z].invalid_reason_x;
        delete this.rows[z].invalid_reason_y;
        this.rows[z].x =
          (this.rows[z].x !== "" &&
            Number(this.validateNumber(this.rows[z].x))) ||
          this.rows[z].x;
        this.rows[z].y =
          (this.rows[z].y !== "" &&
            Number(this.validateNumber(this.rows[z].y))) ||
          this.rows[z].y;
      }
      for (let j = i; j >= 0; j--) {
        if (this.rows[j].x === "" || this.rows[j].x === false) {
          this.rows[j].invalid = true;
          this.rows[j].invalid_reason_x = this.$t("device.InvalidValue");
          this.sendDisabledFlag(true);
          return;
        }
        if (this.rows[j].y === "" || this.rows[j].y === false) {
          this.rows[j].invalid = true;
          this.rows[j].invalid_reason_y = this.$t("device.InvalidValue");
          this.sendDisabledFlag(true);
          return;
        }
        if (j - 1 >= 0 && this.rows[j].x <= this.rows[j - 1].x) {
          this.rows[j].invalid = true;
          this.rows[j].invalid_reason_x = this.$t("device.XGreaterThan");
          this.sendDisabledFlag(true);
          return;
        }
        if (j - 1 >= 0 && this.rows[j].y <= this.rows[j - 1].y) {
          this.rows[j].invalid = true;
          this.rows[j].invalid_reason_y = this.$t("device.YGreaterThan");
          this.sendDisabledFlag(true);
          return;
        }
      }
      // this.$emit("input", this.rows);
      this.$emit("rowsChanges", JSON.parse(JSON.stringify(this.rows)));
      this.sendDisabledFlag(false);
      this.rows = JSON.parse(JSON.stringify(this.rows));
      this.clearOtherSegment();
    },
    addNewRow() {
      if (
        this.rows.length &&
        (this.rows[this.rows.length - 1].invalid ||
          this.rows[this.rows.length - 1].x === "" ||
          this.rows[this.rows.length - 1].y === "")
      ) {
        return;
      }
      this.rows.push({ x: "", y: "" });
      this.$emit("rowsChanges", JSON.parse(JSON.stringify(this.rows)));
      this.rows = JSON.parse(JSON.stringify(this.rows));
      this.sendDisabledFlag(true);
      this.clearOtherSegment();
    },
    addNewRangeRow() {
      if (
        this.ranges.length &&
        (this.ranges[this.ranges.length - 1].invalid ||
          this.ranges[this.ranges.length - 1].x === "" ||
          this.ranges[this.ranges.length - 1].y === "" ||
          this.ranges[this.ranges.length - 1].z === "")
      ) {
        return;
      }
      this.ranges.push({ x: "", y: "", z: "" });
      this.$emit("rangesChanges", JSON.parse(JSON.stringify(this.ranges)));
      this.ranges = JSON.parse(JSON.stringify(this.ranges));
      this.sendDisabledFlag(true);
      this.clearOtherSegment(true);
    },
    removeRangeRow(index) {
      this.ranges.splice(index, 1);
      this.$emit("rangesChanges", JSON.parse(JSON.stringify(this.ranges)));
      this.validateRangeRows();
    },
    removeRow(index) {
      this.rows.splice(index, 1);
      this.$emit("rowsChanges", JSON.parse(JSON.stringify(this.rows)));
      this.validateRows();
    },
    downloadRangeCsv() {
      let csvContent = "data:text/csv;charset=utf-8,";
      this.ranges.forEach((range) => {
        csvContent += `${range.x},${range.y},${range.z}` + "\n";
      });
      window.open(encodeURI(csvContent));
    },
    downloadCsv() {
      let csvContent = "data:text/csv;charset=utf-8,";
      this.rows.forEach((row) => {
        csvContent += `${row.x},${row.y}` + "\n";
      });
      window.open(encodeURI(csvContent));
    },
    parseCsv(content) {
      this.clearOtherSegment();
      this.rows = content
        .split("\n")
        .map((row) => row.split(","))
        .map((row) => {
          return {
            x: row[0] && row[0].trim(),
            y: row[1] !== undefined && row[1].trim()
          };
        })
        .filter((r) => r.x || r.y);
      this.validateRows();
    },
    parseRangeCsv(content) {
      this.clearOtherSegment(true);
      this.ranges = content
        .split("\n")
        .map((range) => range.split(","))
        .map((range) => {
          return {
            x: range[0] && range[0].trim(),
            y: range[1] && range[1].trim(),
            z: range[2] !== undefined && range[2].trim()
          };
        })
        .filter((r) => r.x || r.y || r.z);
      this.validateRangeRows();
    },
    importCsv(range = false) {
      // this.csvFile = this.$refs.doc.files[0];
      if (this.inputCsvFile.name.includes(".csv")) {
        const reader = new FileReader();
        reader.onload = (res) => {
          range
            ? this.parseRangeCsv(res.target.result)
            : this.parseCsv(res.target.result);
        };
        reader.onerror = (err) => console.log(err);
        reader.readAsText(this.inputCsvFile);
      }
      setTimeout(() => {
        this.inputCsvFile = [];
      }, 100);
    }
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/core/colors/palette-variables.scss";
.calculation-table {
  padding: 10px;
  .table-rows.container {
    max-height: 315px;
    min-height: 315px;
    overflow: auto;
  }
  .bound-limits-row {
    border-top: solid 1px #ebe9f1;
    border-bottom: solid 1px #ebe9f1;
    padding: 20px 0 0;
    .alert {
      padding: 15px;
    }
  }
  .graph-section {
    border-left: 1px #dee2e6 solid;
  }
  .header-row {
    text-align: center;
    .column {
      padding: 5px;
      input {
        cursor: default;
        border: none;
        background: #e9e9e9;
        text-align: center;
      }
    }
  }
  .value-row {
    .column {
      padding: 5px;
    }
  }
  .add-new-line {
    cursor: pointer;
    margin: 12px;
    color: var(--primary);
  }
  // .feather {
  //   cursor: pointer;
  //   position: absolute;
  //   top: 8px;
  // }
}
.custom-upload-button {
  position: relative;
  cursor: pointer;
  .custom-file-input.b-form-file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: $percent_100;
    height: $percent_100;
    opacity: 0;
    cursor: pointer;
  }
  .custom-file-label {
    cursor: pointer;
  }
}
.align-right-file-name {
  text-align: right;
  padding-right: 0;
}
.btn-active:hover {
  svg,
  path {
    fill: var(--primary);
  }
}
.form-radio-class > div {
  display: flex !important;
}
.form-radio-class .custom-radio {
  margin: 10px;
}
.col-width {
  width: 49%;
}
.col-width-1 {
  width: 49%;
}
.calculation-table-title-row {
  margin-bottom: 6px;
}
// canvas {
//   width: 100% !important;
//   min-width: 400px;
// }
// .echarts div {
//   width: 100% !important;
// }
// .graph-section .echarts div {
//   width: 100% !important;
// }
</style>
