<template>
  <div>
    <b-row>
      <b-col
        :sm="
          ['string', 'number'].includes(data_type.toLowerCase()) &&
          value == '0' &&
          field == 'camera_type' &&
          selectedSensor === 'VIDEO_STREAM' &&
          !streamUrl
            ? 8
            : 12
        "
        ><div class="d-flex">
          <v-select
            v-model="inputVal"
            :disabled="!isEditable"
            :options="dropValue"
            :reduce="(option) => option.value"
            :clearable="!is_required"
            @input="updateInputState"
            :loading="loading"
            :multiple="multi_select ? true : false"
            label="text"
            class="w-100"
            @search="searchFilter"
          />
          <b-button
            v-if="
              ['string', 'number'].includes(data_type.toLowerCase()) &&
              value == '0' &&
              field == 'camera_type' &&
              selectedSensor === 'VIDEO_STREAM'
            "
            class="ml-1 getURL"
            :disabled="loading || !isEditable"
            variant="primary"
            @click="createLiveVideoStream"
            >{{ $t("device.GetURL") }}

            <b-spinner v-if="loading" small class=""></b-spinner>
          </b-button></div
      ></b-col>

      <!-- <b-col
        sm="2"
        v-if="
          ['string', 'number'].includes(data_type.toLowerCase()) &&
          value == '0' &&
          field == 'camera_type' &&
          selectedSensor === 'VIDEO_STREAM' &&
          !streamUrl
        "
      >
        <b-button
          class="mw-160"
          :disabled="loading || !isEditable"
          variant="primary"
          @click="createLiveVideoStream"
          >{{ $t("device.GetStreamURL") }}

          <b-spinner
            v-if="loading"
            small
            class="button-spinner"
          ></b-spinner> </b-button
      ></b-col> -->
    </b-row>
    <b-row
      v-if="
        ['string', 'number'].includes(data_type.toLowerCase()) &&
        value == '0' &&
        field == 'camera_type' &&
        selectedSensor === 'VIDEO_STREAM'
      "
    >
      <b-col>
        <div class="d-flex flex-columns copy-element">
          <span
            class="mt-1"
            v-if="
              streamUrl &&
              ['string', 'number'].includes(data_type.toLowerCase()) &&
              value == '0' &&
              field == 'camera_type' &&
              selectedSensor === 'VIDEO_STREAM'
            "
          >
            {{ $t("device.Hostname") }}
            <span class="text-primary" id="textToCopyHostname">{{
              streamUrl
            }}</span>

            <feather-icon
              icon="CopyIcon"
              class="text-primary action-icon"
              size="18"
              @click="copyText(true)"
              style="position: relative; cursor: pointer"
            ></feather-icon>
          </span>
          <span
            class="mt-1"
            v-if="
              streamId &&
              ['string', 'number'].includes(data_type.toLowerCase()) &&
              value == '0' &&
              field == 'camera_type' &&
              selectedSensor === 'VIDEO_STREAM'
            "
          >
            {{ $t("device.StreamKey") }}
            <span class="text-primary" id="textToCopyStreamId">
              {{ streamId }}</span
            >

            <feather-icon
              icon="CopyIcon"
              class="text-primary action-icon"
              size="18"
              @click="copyText()"
              style="position: relative; cursor: pointer"
            ></feather-icon>
          </span>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapMutations } from "vuex";
import { BCol, BRow, BButton, BSpinner } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import DataService from "@/libs/api/data-service";

import APIService from "@/libs/api/api";
export default {
  props: [
    "name",
    "desc",
    "label",
    "component_value",
    "data_type",
    "component",
    "multi_select",
    "value",
    "isEditable",
    "isRequired",
    "field",
    "sensorInfo",
    "selectedSensor",
    "device",
    "is_required"
  ],
  components: { vSelect, BCol, BRow, BButton, BSpinner },
  data() {
    return {
      inputVal: "",
      isIndeterminate: false,
      streamUrl: null,
      loading: false,
      streamId: null,
      dropValue: [],
      isAPICall: false,
      filterVal: "",
      timeoutId: ""
    };
  },
  mounted() {
    this.inputVal = this.value == "0" ? 0 : this.value;
    this.streamUrl = this.sensorInfo?.streamUrlData?.streamUrl || "";
    this.streamId = this.sensorInfo?.streamUrlData?.streamId || "";
    if (
      this.sensorInfo &&
      this.sensorInfo.type &&
      this.sensorInfo.type === "FUEL" &&
      this.sensorInfo.fields &&
      this.sensorInfo.fields.unit &&
      this.field == "unit" &&
      this.value
    ) {
      store.commit("sensor/SET_FUEL_SENSOR_UNIT", this.inputVal);
    }
    this.getOptions();
  },
  beforeDestroy() {
    this.videoStreamData({});
  },
  watch: {
    "$store.state.sensor.fuelSensorUnit"(val) {
      this.getOptions();
    }
    // sensorInfo: {
    //   handler(val) {
    //     if (
    //       this.sensorInfo &&
    //       this.sensorInfo.type &&
    //       this.sensorInfo.type === "FUEL" &&
    //       this.sensorInfo.fields &&
    //       this.sensorInfo.fields.unit &&
    //       this.sensorInfo.fields.unit.value &&
    //       this.field == "measure_system"
    //     ) {
    //       this.getOptions();
    //     }
    //   },
    //   deep: true
    // }
  },
  methods: {
    ...mapMutations("device", ["videoStreamData"]),
    updateInputState() {
      if (
        this.sensorInfo &&
        this.sensorInfo.type &&
        this.sensorInfo.type === "FUEL" &&
        this.sensorInfo.fields &&
        this.sensorInfo.fields.unit &&
        this.field == "unit"
      ) {
        store.commit("sensor/SET_FUEL_SENSOR_UNIT", this.inputVal);
      }

      if (this.field === "clipping_time" && this.value !== this.inputVal) {
        let response = new DataService().updateClippingTime({
          device_id: this.device.id,
          sensor: this.sensorInfo.name,
          clipping_time: this.inputVal
        });
      }
      this.$emit("input", this.inputVal);
    },
    copyText(url = false) {
      const textElement = document.getElementById(
        url ? "textToCopyHostname" : "textToCopyStreamId"
      );
      const range = document.createRange();
      range.selectNode(textElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      try {
        document.execCommand("copy");
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("device.VideoStreamURLSuccessfullyCopy"),
            text: "",
            icon: "InfoIcon",
            variant: "success"
          }
        });
      } catch (error) {
        console.error("Unable to copy text: ", error);
        // Handle any errors, e.g., show an error message to the user

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("device.VideoStreamURLFailedCopy"),
            text: "",
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }

      // Clear the selection
      window.getSelection().removeAllRanges();
    },
    async createLiveVideoStream() {
      try {
        if (!this.sensorInfo.name) {
          throw { message: this.$t("device.PleaseEnterSensorName") };
          return;
        }
        this.loading = true;
        let response = await new DataService().createLiveVideoStream({
          device_id: this.device.id,
          sensor: this.sensorInfo.name,
          camera_type: 0,
          clipping_time:
            (this.sensorInfo.fields["clipping_time"] &&
              this.sensorInfo.fields["clipping_time"].value) ||
            null
        });
        this.loading = false;
        if (response && response.data) {
          this.streamUrl = response.data.hostname;
          this.streamId = response.data.stream_id;
          this.videoStreamData({
            streamUrl: this.streamUrl,
            streamId: this.streamId
          });
        } else {
          throw response.error;
        }
      } catch (error) {
        this.loading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async getOptions() {
      if (
        this.sensorInfo &&
        this.sensorInfo.type &&
        this.sensorInfo.type === "FUEL" &&
        this.sensorInfo.fields &&
        this.sensorInfo.fields.unit &&
        this.sensorInfo.fields.unit.value &&
        this.field == "measure_system"
      ) {
        if (this.$store.state.sensor.fuelSensorUnit === "Liter") {
          this.dropValue = this.component_value.filter((e) => e.isLitre);
          const d = this.dropValue.find((e) => e.value === this.inputVal);
          if (!d || !d.value) {
            this.inputVal = "";
          }
        } else if (this.$store.state.sensor.fuelSensorUnit === "Gallon") {
          this.dropValue = this.component_value.filter((e) => e.isGallon);
          const d = this.dropValue.find((e) => e.value === this.inputVal);
          if (!d || !d.value) {
            this.inputVal = "";
          }
        } else {
          this.dropValue = [];
          this.inputVal = "";
          this.updateInputState();
        }
      } else if (
        this.sensorInfo &&
        this.sensorInfo.type &&
        this.sensorInfo.type === "FUEL" &&
        this.sensorInfo.fields &&
        this.sensorInfo.fields.unit &&
        this.field == "measure_system" &&
        !this.sensorInfo.fields.unit.value
      ) {
        this.dropValue = [];
        this.inputVal = "";
        this.updateInputState();
      } else {
        if (
          this.component_value &&
          this.component_value.length &&
          this.component_value[0] &&
          this.component_value[0].isApiCall
        ) {
          this.isAPICall = true;
          await this.getData(this.component_value[0]);
        } else {
          this.dropValue = this.component_value;
        }
      }
    },
    async getData(componentValue) {
      try {
        this.loading = true;
        let filter = {};
        if (
          componentValue.filters &&
          componentValue.filters.length &&
          componentValue.filters[0] &&
          componentValue.filters[0].field &&
          this.filterVal
        ) {
          componentValue.filters[0].value = this.filterVal;
          filter.filters = componentValue.filters;
        }
        const data = await new APIService().api(
          {
            service: componentValue.service,
            method: componentValue.method
          },
          {
            ...filter,
            page: componentValue.page || 1,
            page_size: componentValue.page_size || 100,
            account_id: componentValue.isAccount
              ? this.$route.query.account_id ||
                localStorage.getItem("USER_ACCOUNT_ID")
              : null,
            id: this.value ? this.value : null
          },
          {
            options: {
              show_loader: true,
              http_headers: {},
              api_version: undefined
            }
          }
        );
        this.isAPICall = true;
        this.loading = false;
        if (data && data.data && data.data[componentValue.main]) {
          const list = JSON.parse(
            JSON.stringify(data.data[componentValue.main])
          ).map((e) => {
            if (componentValue.value) {
              e.value = e[componentValue.value];
            }
            if (componentValue.title) {
              e.text = e[componentValue.title];
            }
            return e;
          });
          this.dropValue = JSON.parse(JSON.stringify(list));
          if (
            data.data[componentValue.main] &&
            data.data[componentValue.main].length &&
            data.data[componentValue.main].length === 1
          ) {
            this.selectedValue = this.dropValue[0].value;
            this.$emit("input", this.selectedValue);
            this.$emit("emitDataToParent", null);
          }
        } else if (data && data.error && data.error.message) {
          this.$emit("input", "");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "",
              text: data.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.isShow = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async searchFilter(e) {
      if (this.isAPICall && e) {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(async () => {
          this.filterVal = e;
          await this.getData(this.component_value[0]);
        }, 400);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.button-spinner {
  position: absolute;
}
.mw-160 {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
}
.copy-element {
  flex-direction: column;
}
.getURL {
  min-width: 103px;
  font-size: 1rem;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
