<template>
  <div>
    <div
      class="under-line-title"
      :class="
        ['edit-device', 'create-device', 'view-device'].includes($route.name)
          ? $route.name === 'edit-device'
            ? 'trip-detector-h'
            : $route.name === 'create-device'
            ? 'trip-detector-s'
            : 'trip-detector-v'
          : 'trip-detector-h-w'
      "
    >
      <div class="d-flex justify-content-between align-items-center mb-1">
        <div>
          <h4 class="card-title mb-0">
            {{ $t("device.TripDetector.TripDetectorName") }}
          </h4>
        </div>

        <b-button
          variant="outline-primary"
          size="sm"
          @click="
            (e) => {
              $router.push({ name: 'devices-list' });
            }
          "
          class="btn set-back-mobile v2-back"
        >
          <feather-icon icon="ArrowLeftIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{ $t("back.back") }}</span>
        </b-button>
      </div>
      <div>
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form @reset.prevent="tripDetectorReset">
            <div
              :class="
                ['edit-device', 'create-device', 'view-device'].includes(
                  $route.name
                )
                  ? $route.name === 'edit-device'
                    ? 'trip-create'
                    : $route.name === 'create-device'
                    ? 'trip-create-v'
                    : 'trip-create-h'
                  : 'trip-create-c'
              "
            >
              <b-row>
                <b-col sm="6">
                  <b-form-group
                    :label="$t('device.TripDetector.MovementDetection') + '  *'"
                  >
                    <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      :rules="{
                        required: true
                      }"
                    >
                      <v-select
                        v-model="source"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                        :placeholder="
                          $t('device.TripDetector.MovementDetection')
                        "
                        :options="tripDetectorSource"
                        :reduce="(option) => option.value"
                        :disabled="isViewOnly"
                        label="label"
                      />

                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="$t('device.TripDetector.MinMovingSpeed') + '  *'"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Min. Moving Speed"
                      vid="min_m_speed"
                      :rules="{
                        required: true,
                        min: 0
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          type="number"
                          @input="detectorChange()"
                          id="min_m_speed"
                          v-model="detector.min_m_speed"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          :disabled="isViewOnly"
                          name="name"
                          :placeholder="
                            $t('device.TripDetector.MinMovingSpeed')
                          "
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="$t('device.TripDetector.MinParkingTime') + '  *'"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Min. Parking time"
                      vid="min_park_time"
                      :rules="{
                        required: true,
                        min: 0
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          type="number"
                          @input="detectorChange()"
                          id="min_park_time"
                          v-model="detector.min_park_time"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="name"
                          :disabled="isViewOnly"
                          :placeholder="
                            $t('device.TripDetector.MinParkingTimePlace')
                          "
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="
                      $t('device.TripDetector.MaxDistanceBetweenMessages') +
                      '  *'
                    "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Max . Distance Between Messages"
                      vid="max_dist_in_msgs"
                      :rules="{
                        required: true,
                        min: 0
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          type="number"
                          @input="detectorChange()"
                          id="max_dist_in_msgs"
                          v-model="detector.max_dist_in_msgs"
                          :disabled="isViewOnly"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="name"
                          :placeholder="
                            $t('device.TripDetector.MaxDistanceBetweenMessages')
                          "
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="
                      $t('device.TripDetector.MinNumberOfSatellites') + '  *'
                    "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Min. Number Of Satellites"
                      vid="min_satellites"
                      :rules="{
                        required: true,
                        min: 0
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          type="number"
                          @input="detectorChange()"
                          id="min_satellites"
                          v-model="detector.min_satellites"
                          :disabled="isViewOnly"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="name"
                          :placeholder="
                            $t('device.TripDetector.MinNumberOfSatellites')
                          "
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="$t('device.TripDetector.MinTripDistance') + '  *'"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Min. Trip Distance"
                      vid="min_trip_dist"
                      :rules="{
                        required: true,
                        min: 0
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          type="number"
                          @input="detectorChange()"
                          id="min_trip_dist"
                          v-model="detector.min_trip_dist"
                          :disabled="isViewOnly"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="name"
                          :placeholder="
                            $t('device.TripDetector.MinTripDistance')
                          "
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="
                      $t('device.TripDetector.MaxIntervalInMessages') + '  *'
                    "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Max. Interval In Messages"
                      vid="max_intrl_in_msgs"
                      :rules="{
                        required: true,
                        min: 0
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          type="number"
                          @input="detectorChange()"
                          id="max_intrl_in_msgs"
                          v-model="detector.max_intrl_in_msgs"
                          :disabled="isViewOnly"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="name"
                          :placeholder="
                            $t('device.TripDetector.MaxIntervalInMessages')
                          "
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    :label="$t('device.TripDetector.MinTripTime') + '  *'"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Min. Trip Time"
                      vid="min_trip_time"
                      :rules="{
                        min: 0,
                        required: true
                      }"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="min_trip_time"
                          v-model="detector.min_trip_time"
                          :disabled="isViewOnly"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="name"
                          type="number"
                          @input="detectorChange()"
                          :placeholder="$t('device.TripDetector.MinTripTime')"
                        />
                      </b-input-group>
                      <small class="text-danger">{{
                        errors[0] ? handleError(errors[0]) : ""
                      }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="6"
                  :style="!detector.gps_correct ? 'height: 79px' : ''"
                >
                  <b-form-group>
                    <b-form-checkbox
                      v-model="detector.gps_correct"
                      :disabled="isViewOnly"
                      class="form-top"
                      >{{ $t("device.TripDetector.AllowGPSCorrection") }}
                    </b-form-checkbox>
                    <b-form-invalid-feedback> </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>

            <div class="d-flex justify-content-end">
              <b-button
                type="button"
                variant="primary"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :disabled="invalid"
                class="mt-2 ml-1"
                v-if="$route.name === 'edit-device'"
                @click="save()"
              >
                {{ $t("profile.SaveChanges") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <Loader :show="show" />
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BCard,
  BButton,
  BInputGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import constants from "@/utils/constants";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    BCard,
    BInputGroup,
    BButton,
    Loader,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BFormInvalidFeedback,
    BFormCheckbox
  },
  data() {
    return {
      selected: "ACTIVE",
      option: ["ACTIVE"],
      countries: [],
      tripDetectorSource: [],
      show: false,
      source: "auto",

      detector: {}
    };
  },
  directives: {
    Ripple
  },
  mounted() {
    this.tripDetectorSource = constants.TripDetector;

    this.detector =
      this.deviceData &&
      this.deviceData.config &&
      this.deviceData.config.trip_detector &&
      Object.keys(this.deviceData.config.trip_detector).length
        ? this.deviceData.config.trip_detector
        : constants.TRIP_DETECTOR_DEFAULT_VALUE;
    this.source = this.detector.m_source;
    this.tripDetectorPropFun(this.detector);
  },
  computed() {},
  props: [
    "deviceData",
    "isViewOnly",
    "tripDetectorPropFun",
    "saveTripDetector"
  ],
  watch: {
    deviceData() {
      this.deviceData &&
      this.deviceData.config &&
      this.deviceData.config.trip_detector &&
      Object.keys(this.deviceData.config.trip_detector).length
        ? this.deviceData.config.trip_detector
        : this.detector;
      this.tripDetectorPropFun(this.detector);
    },
    detector() {
      this.tripDetectorPropFun(this.detector);
    },

    source() {
      this.detector.m_source = this.source;
      this.tripDetectorPropFun(this.detector);
    }
  },
  methods: {
    redirectList() {},
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    save() {
      this.tripDetectorPropFun(this.detector);
      this.saveTripDetector();
    },
    detectorChange() {
      this.tripDetectorPropFun(this.detector);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.form-top {
  top: 15px;
}
.trip-detector-h {
  padding: 1.5rem !important;
  .card-body {
    padding: 0 !important;
    min-height: calc(100vh - 245px) !important;
  }
}
@media only screen and (max-width: 600px) {
  .trip-create {
    height: calc(100vh - 354px) !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .trip-detector-h-w {
    padding: 1rem;
  }
  .trip-detector-h {
    padding: 1rem !important;
  }
}
.trip-detector-h-w {
  .card-body {
    min-height: calc(100vh - 285px) !important;
  }
}
.trip-create {
  height: calc(100vh - 320px);
  overflow-y: auto;
  overflow-x: hidden;
}
.trip-create-edit {
  height: calc(100vh - 340px) !important;
  overflow-y: auto;
}

.trip-create-c {
  height: calc(100vh - 340px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.under-line-title {
  .card-body {
    padding: 0 !important;
    min-height: calc(100vh - 245px);
  }
}
.trip-detector-v {
  padding: 1.5rem;
}
</style>
