<template>
  <div>
    <b-form-input
      v-model="inputVal"
      :disabled="!isEditable"
      @input="updateInputState"
      :type="data_type == 'Number' ? 'number' : 'text'"
    >
    </b-form-input>
  </div>
</template>

<script>
import { BFormInput } from "bootstrap-vue";

export default {
  props: [
    "name",
    "desc",
    "label",
    "data_type",
    "component",
    "value",
    "isEditable",
    "isRequired"
  ],
  components: { BFormInput },
  data() {
    return {
      inputVal: "",
      isIndeterminate: false
    };
  },
  mounted() {
    this.inputVal = this.value;
  },
  methods: {
    updateInputState() {
      this.$emit("input", this.inputVal);
    }
  }
};
</script>
