import { render, staticRenderFns } from "./UnitInfoMenu.vue?vue&type=template&id=dd778978&"
import script from "./UnitInfoMenu.vue?vue&type=script&lang=js&"
export * from "./UnitInfoMenu.vue?vue&type=script&lang=js&"
import style0 from "./UnitInfoMenu.vue?vue&type=style&index=0&id=dd778978&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports