import APIService from "./api";

/** This class will include all services defined in unit-service */
export default class CommandService extends APIService {
  service = "command-service";
  async execute(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "execute" },
      data,
      options
    );
  }
  async executeDeviceCommand(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "executeDeviceCommand" },
      data,
      options
    );
  }
  async getAllCommandsHistory(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllCommandsHistory" },
      data,
      options
    );
  }
}
