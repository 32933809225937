<template>
  <div>
    <!-- {{ sensorsData }} -->
    <b-form-group>
      <b-input-group class="eval-protocol">
        <b-form-input
          v-if="isPlanParameter"
          v-model="selectTelProtocol"
          class="d-inline-block open-tel-parama curserPointer"
          :readonly="readOnlyTxt"
        />
        <DeviceProtocolSearch
          v-else
          v-model="selectTelProtocol"
          @save="closeModal"
          @valid="valid"
          :isViewOnly="isViewOnly"
          :sensorInfo="sensorInfo"
          :sensorField="sensorField"
          :telParameters="protocolParamsList"
          :selectedSensor="selectedSensor"
          :sensorsData="sensorsData"
        ></DeviceProtocolSearch>

        <b-input-group-append
          class="input-prefix-img curserPointer"
          @click="selectTelParameters(sensorField)"
        >
          <BGImageURL />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>

    <b-modal
      :id="`modal-select-tel-parameters-${this.sensorField.field}`"
      centered
      size="xl"
      no-close-on-esc
      no-close-on-backdrop
      modal-class="modal-select-tel-parameters no-header-modal w-90"
    >
      <div class="slect-account-title-center">{{ protocolName }}</div>
      <p v-if="!protocolName" style="text-align: center; color: red">
        Device model of this device is configured incorrectly, kindly contact
        support
      </p>
      <div class="table-responsive" v-if="showLoading && protocolName">
        <b-skeleton-table
          v-if="showLoading && protocolName"
          :rows="7"
          :columns="6"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
      </div>
      <DeviceProtocolParameters
        v-if="!showLoading && protocolName"
        v-model="selectTelProtocol"
        :protocolParams="protocolParams"
        :protocolName="protocolName"
        :isPlanParameter="isPlanParameter"
        :closeModal="closeModal"
        :isViewOnly="isViewOnly"
        :sensorField="sensorField"
        :parameter="parameter"
      ></DeviceProtocolParameters>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BSkeletonTable
} from "bootstrap-vue";
import DeviceProtocolSearch from "./DeviceProtocolSearch";
import DeviceProtocolParameters from "./DeviceProtocolParameters.vue";
import BGImageURL from "@/assets/images/new-icon/open-tel-parama.svg";
import store from "@/store";
import DeviceService from "@/libs/api/device-service";
import { mapMutations } from "vuex";
export default {
  name: "DeviceProtocolParametersModal",
  components: {
    BFormGroup,
    BFormInput,
    BCardText,
    DeviceProtocolParameters,
    BGImageURL,
    BInputGroupAppend,
    BInputGroup,
    BSkeletonTable,
    DeviceProtocolSearch
  },
  computed: {},
  data() {
    return {
      readOnlyTxt: true,
      selectTelProtocol: "",
      protocolParams: [],
      protocolParamsList: [],
      oldProtocolName: "",
      isValid: false,
      showLoading: false
    };
  },
  watch: {
    protocolName(old, newVal) {
      if (old !== newVal) {
        this.getProtocolParams(true);
      }
    }
  },
  async mounted() {
    this.selectTelProtocol = this.value;
    setTimeout(() => {
      this.getProtocolParams();
    }, 1000);
  },
  props: [
    "value",
    "sensorField",
    "sensorInfo",
    "protocolName",
    "state",
    "isViewOnly",
    "parameter",
    "isPlanParameter",
    "selectedSensor",
    "sensorsData"
  ],

  methods: {
    // ...mapMutations("device", ["setProtocolList"]),
    valid(e) {
      this.isValid = e;
      if (!e) {
        this.$emit("input", "");
        this.$emit("isInvalid", false);
      } else {
        this.$emit("input", this.selectTelProtocol);
        this.$emit("isInvalid", true);
      }
    },
    async selectTelParameters(data) {
      const me = this;
      this.$bvModal.show(
        `modal-select-tel-parameters-${this.sensorField.field}`
      );

      await this.getProtocolParams(false);

      this.selectedTelPara = data.field;
      this.selectTelProtocol = this.value;
      this.protocolParams = data.data_type
        ? this.protocolParamsList.filter(
            (e) =>
              e.type &&
              data.data_type &&
              e.type.toLowerCase() === data.data_type.toLowerCase()
          )
        : this.protocolParamsList;
    },
    closeModal() {
      let matches = "";
      if (this.selectTelProtocol && this.selectTelProtocol.length) {
        matches = this.selectTelProtocol.match(/{([^}]+)}/g);
      }
      let isInvalid = false;
      if (matches && matches.length) {
        matches = matches.map((match) => match.replace(/[{}]/g, ""));
        matches.forEach((ee) => {
          const match = this.protocolParamsList.find((e) => e.property === ee);
          if (!match || !match.property) {
            isInvalid = true;
          }
        });
      } else {
        this.$bvModal.hide(
          `modal-select-tel-parameters-${this.sensorField.field}`
        );
        this.$emit("input", "");
      }
      if (!isInvalid) {
        this.$emit("input", this.selectTelProtocol);
        this.$bvModal.hide(
          `modal-select-tel-parameters-${this.sensorField.field}`
        );
      } else {
        this.$bvModal.hide(
          `modal-select-tel-parameters-${this.sensorField.field}`
        );
        this.$emit("input", "");
      }
    },
    async getProtocolParams(updateProtocol) {
      try {
        if (!this.protocolName) {
          this.protocolParamsList = [];
          return;
        }
        const protocolParamsStore = this.$store.state.device.parameters;
        if (
          protocolParamsStore &&
          protocolParamsStore.length &&
          !updateProtocol
        ) {
          this.protocolParamsList = protocolParamsStore;
          return;
        }
        this.oldProtocolName = this.protocolName;

        this.showLoading = true;
        let response = await new DeviceService().getProtocolParams({
          protocol: this.protocolName
        });

        this.showLoading = false;
        if (response && response.data) {
          this.protocolParamsList = response.data;
          store.commit("device/setProtocolList", this.protocolParamsList);
          // this.setProtocolList({ ...this.protocolParamsList });
        } else if (response && response.error && response.error.message) {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: response.error.title,
          //     text: response.error.message,
          //     icon: "InfoIcon",
          //     variant: "danger"
          //   }
          // });
        }
      } catch (err) {
        this.showLoading = false;
        // this.$toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: err.message,
        //     icon: "InfoIcon",
        //     variant: "danger"
        //   }
        // });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.input-prefix-img {
  border: 1px solid var(--gray4);
  height: 37.96px;
  width: 40px;
  border-radius: 0px 7px 7px 0px;
  background: #d8d6de;
  text-align: center;
  svg {
    height: 32px;
    margin: auto;
    padding: 3px;

    path {
      fill: var(--primary);
    }
  }
  .b-avatar-img {
    img {
      object-fit: contain;
      padding: 5px;
    }
  }
}
// .slect-account-title-center{
//   .table-responsive{
//     display:none
//   }
// }

@media only screen and (min-width: 1025px) {
  .modal-select-tel-parameters {
    left: 0 !important;
    width: 100% !important;
  }
}
.eval-protocol div:first-child {
  width: calc(100% - 42px) !important;
}
</style>
