<template>
  <div class="time-picker-main">
    <vue-timepicker
      format="HH:mm:ss"
      v-model="selectedTime"
      :disabled="!isEditable"
      hide-clear-button
      @change="updateValues"
    ></vue-timepicker>
    <div class="time-picker-clear">
      <feather-icon
        icon="XIcon"
        class="curserPointer"
        @click="clearTxt"
        size="20"
      />
    </div>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";

export default {
  components: { VueTimepicker },
  data() {
    return {
      selectedTime: "00:00:00"
    };
  },

  props: [
    "name",
    "desc",
    "label",
    "data_type",
    "component",
    "value",
    "isEditable",
    "isRequired"
  ],
  watch: {},
  mounted() {
    this.selectedTime = this.value;
  },
  methods: {
    updateValues(e) {
      if (e.displayTime) {
        this.$emit("input", e.displayTime);
      }
    },
    clearTxt() {
      if (this.isEditable) {
        this.selectedTime = "00:00:00";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.time-picker-main {
  display: flex;
  align-items: center;
  width: 100%;
  .time-picker {
    width: 100%;
  }
}
.vue__time-picker input.display-time {
  // height: 38px !important;
  // border-top-left-radius: 4px !important;
  // border-bottom-left-radius: 4px !important;
}
.time-picker-clear {
  height: 36px;
  display: flex;
  width: 30px;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: 1px solid #d8d6de !important;
}
.dark-layout {
  .time-picker-main {
  }
}
</style>
