<template>
  <div>
    <b-modal
      id="CustomSensors"
      no-close-on-esc
      no-close-on-backdrop
      centered
      modal-class="no-header-modal"
      :hide-header="true"
      size="lg"
    >
      <div style="padding: 0.8rem 1.4rem"></div>
      <b-card-text>
        <div class="new-invite-modal-title">
          {{ $t("device.AddCustomSensor") }}
        </div>
        <div>
          <validation-observer ref="customComponent" #default="{ invalid }">
            <b-form class="mtt-37" @submit.prevent="save">
              <validation-provider
                #default="{ errors }"
                name="field"
                vid="field"
                :rules="{
                  required: true,
                  min: 2,
                  max: 30,
                  regex: /^[a-z-0-9_]{2,30}$/
                }"
              >
                <b-row>
                  <b-col style="margin-top: 27px; padding-right: 10px" sm="3">
                    <span style="text-transform: capitalize">
                      {{ `${$t("device.Field")}  *` }}
                    </span>
                  </b-col>
                  <b-col sm="8" style="margin-top: 17px">
                    <b-form-input
                      v-model="parameter.field"
                      @input="updateInputState"
                      style="text-transform: lowercase"
                      :class="
                        errors.length == 0 && parameter.field
                          ? 'is-valid'
                          : null
                      "
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="margin-top: 7px; padding-right: 10px" sm="3">
                  </b-col
                  ><b-col sm="8" style="margin-top: 10px">
                    <small class="text-danger">{{
                      errors[0]
                        ? `${handleError(errors[0])} ${
                            parameter.field && parameter.field.length
                              ? $t("device.sensorFiledValidation")
                              : ""
                          }`
                        : ""
                    }}</small>
                  </b-col>
                </b-row>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="label"
                vid="label"
                :rules="{
                  required: true,
                  min: 2,
                  max: 60
                }"
              >
                <b-row>
                  <b-col style="margin-top: 27px; padding-right: 10px" sm="3">
                    <span style="text-transform: capitalize">
                      {{ `${$t("device.Label")}  *` }}
                    </span>
                  </b-col>
                  <b-col sm="8" style="margin-top: 17px">
                    <b-form-input v-model="parameter.label" />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="margin-top: 7px; padding-right: 10px" sm="3">
                  </b-col
                  ><b-col sm="8" style="margin-top: 10px">
                    <small class="text-danger">{{
                      errors[0] ? `${handleError(errors[0])} ` : ""
                    }}</small>
                  </b-col>
                </b-row>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="dataType"
                vid="dataType"
                :rules="{
                  required: true,
                  min: 3,
                  max: 15
                }"
              >
                <b-row>
                  <b-col style="margin-top: 27px; padding-right: 10px" sm="3">
                    <span style="text-transform: capitalize">
                      {{ `${$t("device.DataType")}  *` }}
                    </span>
                  </b-col>
                  <b-col sm="8" style="margin-top: 17px">
                    <v-select
                      id="vue-select"
                      :placeholder="$t('device.DataType')"
                      :options="DataTypes"
                      :reduce="(option) => option.value"
                      label="text"
                      v-model="parameter.data_type"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="margin-top: 7px; padding-right: 10px" sm="3">
                  </b-col
                  ><b-col sm="8" style="margin-top: 10px">
                    <small class="text-danger">{{
                      errors[0] ? `${handleError(errors[0])} ` : ""
                    }}</small>
                  </b-col>
                </b-row>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="value"
                vid="value"
                :rules="{
                  required: true
                }"
              >
                <b-row
                  class="flex-class"
                  v-if="protocolName && parameter.data_type"
                >
                  <b-col style="margin-top: 27px; padding-right: 10px" sm="3">
                    <span style="text-transform: capitalize">
                      {{ `${$t("device.Value")}  *` }}
                    </span>
                  </b-col>
                  <b-col sm="5" style="margin-top: 27px; padding-right: 10px">
                    <DeviceProtocolParametersModal
                      :isViewOnly="false"
                      :protocolName="protocolName"
                      :parameter="{}"
                      v-model="parameter.value"
                      :sensorsData="sensorsData"
                      :sensorInfo="sensorInfo"
                      :sensorField="{
                        field: parameter.field,
                        data_type: parameter.data_type,
                        is_required: true
                      }"
                    ></DeviceProtocolParametersModal>
                  </b-col>
                  <b-col sm="1" style="margin-top: 27px; padding-right: 10px">
                    <div
                      class="sensor-check ml-1"
                      v-if="
                        parameter.data_type &&
                        parameter.data_type.toLowerCase() == 'number'
                      "
                    >
                      <b-form-checkbox
                        :checked="
                          parameter && parameter.table && parameter.table.length
                            ? true
                            : false
                        "
                        class="disabled-check-box"
                      >
                      </b-form-checkbox>
                    </div>
                  </b-col>
                  <b-col sm="3" style="margin-top: 10px; padding-right: 10px">
                    <div class="calculationTable">
                      <CalculationTableModal
                        v-model="parameter.table"
                        :isViewOnly="false"
                        :sensorField="{
                          field: parameter.field,
                          data_type: parameter.data_type
                        }"
                        :keyField="parameter.field"
                      >
                      </CalculationTableModal>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col style="margin-top: 7px; padding-right: 10px" sm="3">
                  </b-col
                  ><b-col sm="8" style="margin-top: 10px">
                    <small class="text-danger">{{
                      errors[0]
                        ? $t("device.customSensorFiledValidationError")
                        : ""
                    }}</small>
                  </b-col>
                </b-row>
              </validation-provider>
              <div class="d-flex justify-content-center mb-64 mtt-50">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="save"
                  :disabled="invalid"
                >
                  {{ $t("device.Add") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="close"
                  class="mt-2"
                  style="z-index: 100000"
                  type="button"
                >
                  {{ $t("device.Cancel") }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BCardText,
  BForm,
  BFormInvalidFeedback,
  BInputGroup,
  BButton
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { BFormCheckbox } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import CalculationTableModal from "@/views/DeviceOnboarding/CalculationTableModal.vue";
import DeviceProtocolParametersModal from "@/views/DeviceOnboarding/DeviceProtocolParametersModal";
export default {
  props: [
    "protocolName",
    "addCustomSensor",
    "sensors",
    "sensorsData",
    "sensorInfo"
  ],
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BCardText,
    BForm,
    BInputGroup,
    BButton,
    vSelect,
    DeviceProtocolParametersModal,
    CalculationTableModal,
    BFormCheckbox,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      inputVal: [],
      isIndeterminate: false,
      DataTypes: [
        { text: "Number", value: "number" },
        { text: "String", value: "string" }
      ],
      error: "",
      isSave: false,
      getValidationState,
      parameter: {
        table: [],
        value: "",
        label: "",
        field: "",
        data_type: "string"
      }
    };
  },
  mounted() {
    this.inputVal = [];
  },
  directives: {
    Ripple
  },
  methods: {
    updateInputState() {
      // const isFiledExists = this.filedExists();
      // const filedRegEx = /^[a-z-]{3,15}$/;
      // if (
      //   isFiledExists ||
      //   !this.parameter.field ||
      //   !filedRegEx.test(this.parameter.field)
      // ) {
      //   this.isSave = false;
      //   this.error = this.parameter.field
      //     ? isFiledExists
      //       ? this.$t("device.sensorFiledAlreadyExists")
      //       : this.$t("device.sensorFiledValidation")
      //     : this.$t("device.sensorFiledRequired");
      // } else {
      //   this.isSave = true;
      //   this.error = "";
      // }
      if (this.parameter.field) {
        this.parameter.field = this.parameter.field.toLowerCase();
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.customComponent.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    removeRow(index) {
      this.inputVal.splice(index, 1);
      this.$bvModal.hide(index);
      this.$emit("input", this.inputVal);
    },
    addNewRow() {
      this.$bvModal.show("CustomSensors");

      // this.inputVal.push("");
      // this.$emit("input", this.inputVal);
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    async save() {
      await this.validationForm();
      const isFiledExists = this.filedExists();
      if (!isFiledExists) {
        this.$emit(
          "addCustomSensor",
          JSON.parse(JSON.stringify(this.parameter))
        );
        this.close();
      } else {
        return;
      }
    },
    filedExists() {
      const sensorsFields = Object.keys(this.sensors);
      if (sensorsFields.includes(this.parameter.field)) {
        return true;
      } else {
        return false;
      }
    },
    close() {
      this.$bvModal.hide("CustomSensors");
      this.parameter = {
        table: [],
        value: "",
        label: "",
        field: "",
        data_type: "string"
      };
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  color: $headings-color;
}
</style>
