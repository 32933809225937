<template>
  <b-media>
    <b-media-body class="mr-75 marginLeftImg mt-1"> </b-media-body>
    <!-- {{ name }} -->
    <div class="header-video-name">
      <b-row>
        <b-col
          cols="5"
          sm="5"
          class="d-flex align-items-center justify-content-start min-30"
        >
          <h6 class="padding-left-channel name-ellipsis">{{ this.name }}</h6>
        </b-col>
        <b-col
          cols="7"
          sm="7"
          class="d-flex align-items-center justify-content-end min-30"
        >
          <b-button
            variant="primary"
            class="mr-15 nowrap-css"
            size="sm"
            v-if="!isStart && deviceObj.camera_type !== 0"
            @click="stopLiveVideoStream(name)"
            ><feather-icon
              icon="StopCircleIcon"
              class="cursor-pointer mr-5-5"
              stroke="#fff"
              size="14"
            />

            <span class="mobile-visible">{{
              $t("video.stopStreamMobile")
            }}</span>

            <span class="desktop-visible">{{ $t("video.stopStream") }}</span>
          </b-button>
          <feather-icon
            icon="XIcon"
            class="cursor-pointer mr-5-5"
            stroke="#fff"
            size="20"
            @click="closeVideoModal"
          />
        </b-col>
      </b-row>
    </div>
    <div class="position-relative">
      <div class="overlay-btn" v-if="!src">
        <b-button variant="danger" @click="createLiveVideoStream(name)">
          <feather-icon
            icon="PlayCircleIcon"
            class="cursor-pointer mr-5-5"
            stroke="#fff"
            size="14"
          />

          {{ $t("video.startStream") }}
        </b-button>
      </div>
      <poly-video-player
        v-if="!!src"
        :style="'visibility:' + (src ? 'visible' : 'hidden')"
        :media="{ src: src, type: 'application/x-mpegURL' }"
        :options="{ liveui: true, autoplay: true, fluid: true }"
      />
    </div>
  </b-media>
</template>

<script>
import { BMedia, BMediaBody, BButton, BRow, BCol } from "bootstrap-vue";
import PolyVideoPlayer from "@/layouts/components/PolyVideoPlayer";
import DataService from "@/libs/api/data-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "videoPlayer",
  components: { BMedia, BMediaBody, PolyVideoPlayer, BButton, BRow, BCol },
  props: [
    "name",
    "options",
    "unitObj",
    "deviceObj",
    "source",
    "getLiveBtnStatus"
  ],
  mounted() {
    // console.log(this.options, "options");
    this.startStreaming();
  },
  watch: {
    name: function (newVal, oldVal) {
      // this.startStreaming();
    }
  },
  methods: {
    closeVideoModal() {
      this.$emit("closeVideoModal");
    },
    onPlayerReady(player) {
      player.on("error", (e) => {
        console.log(e);
      });
    },
    async stopLiveVideoStream() {
      try {
        this.getLiveBtnStatus({
          ...this.deviceObj,
          stop: true,
          name: this.name
        });
        this.showLoading = true;
        await new DataService().stopLiveVideoStream({
          stream_id: this.streamId,
          device_id: this.deviceObj.id,
          unit_id: this.unitObj.id,
          sensor: this.name,
          camera_type: this.deviceObj.camera_type,
          clipping_time: this.deviceObj.clipping_time
          // source: this.source
        });
        this.src = null;
        this.isStart = true;
        this.showLoading = false;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async createLiveVideoStream(name) {
      try {
        this.showLoading = true;
        this.getLiveBtnStatus({
          ...this.deviceObj,
          stop: false,
          name: this.name
        });
        let response = await new DataService().createLiveVideoStream({
          device_id: this.deviceObj.id,
          unit_id: this.unitObj.id,
          sensor: this.name,
          source: this.source,
          camera_type: this.deviceObj.camera_type,
          clipping_time: this.deviceObj.clipping_time
        });
        this.showLoading = false;
        if (response && response.data) {
          this.streamId = response.data.stream_id;
          this.src = response.data.live_url;
          this.isStart = false;
        } else {
          throw response.error;
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async startStreaming() {
      try {
        this.showLoading = true;
        const response = await new DataService().getLiveVideoStream({
          device_id: this.deviceObj.id,
          unit_id: this.unitObj.id,
          sensor: this.name,
          camera_type: this.deviceObj?.camera_type,
          clipping_time: this.deviceObj?.clipping_time
        });
        this.stopLoading = false;
        if (response && response.data) {
          this.streamId = response.data.stream_id;
          this.src = response.data.live_url;
          this.isStart = false;
        }
        if (response && response.data == null) {
          this.isStart = true;
        } else {
          throw response.error;
        }
      } catch (error) {
        if (error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    }
  },
  data() {
    return {
      src: null,
      isStart: true,
      streamId: null
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.wizard-tab-container video {
  max-width: fit-content;
}
.overlay-btn {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0 0 0 / 1);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 97.4%;
  min-height: 250px;
}
.header-video-name {
  padding: 0px 15px 0 0px;
  margin-bottom: 10px;
  .min-30 {
    min-height: 30px;
  }
  h6 {
    font-weight: 600;
    margin-bottom: 0;
  }
}
.mr-5-5 {
  margin-right: 5px;
}
.table-scrollig-area {
  .nav.nav-pills {
    padding-left: 23px;
    margin-top: 15px;
  }
}
.padding-left-channel {
  padding-left: 15px;
}
.name-ellipsis {
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.media {
  padding-top: 0px !important;
}
</style>
