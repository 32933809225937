<template>
  <span
    :class="
      $route.name == 'maintenance-reminder-add-bill' ? 'dynamic-position' : ''
    "
  >
    <div
      ref="myInput"
      :style="
        $route.name == 'maintenance-reminder-add-bill'
          ? 'float: right; position: absolute; top: 0; left: 0; width: 100%'
          : ''
      "
    ></div>

    <date-range-picker
      v-model="dateRange"
      @update="updateValues"
      :auto-apply="true"
      :disabled="!isEditable"
      :opens="
        this.isMobile
          ? 'center'
          : $route.name == 'maintenance-reminder-add-bill'
          ? datePickerPosition
          : 'center'
      "
      :ranges="customRanges"
      :single-date-picker="true"
    >
      <template #input="picker">
        <span v-if="dateRange && dateRange.startDate">
          {{
            `${picker.startDate ? `${DateTimeConvert(picker.startDate)}` : ""}`
          }}
          <!-- {{
          `${picker.endDate ? ` - ${DateTimeConvert(picker.endDate)}` : " "}`
        }} -->
        </span>
        <span v-else class="place-holder-date">{{ $t("unit.SelectDateRange") }} </span>
      </template>
      <!--    date slot-->
      <template #date="data">
        <span class="small">{{ data.date | dateCell }}</span>
      </template>
      <!--    ranges (new slot syntax) -->
      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="(range, name) in ranges.ranges"
              :key="name"
              @click="ranges.clickRange(range)"
            >
              <b>{{ $t(`dateRange.${name}`) }}</b>
            </li>
          </ul>
        </div>
      </template>
      <!--    footer slot-->
    </date-range-picker></span
  >
</template>

<script>
import constants from "@/utils/constants";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: { DateRangePicker },
  data() {
    return {
      dateRange: {
        startDate: this.getCurrentTime(),
        endDate: this.getCurrentTime()
      },
      customRanges: {
        Today: [new Date(), new Date()],
        Yesterday: [this.getYesterday(), this.getYesterday()]
      },
      datePickerPosition: "center",
      isMobile: true
    };
  },

  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  props: [
    "name",
    "desc",
    "label",
    "data_type",
    "component",
    "value",
    "isEditable",
    "isRequired"
  ],
  watch: {},
  mounted() {
    if (this.value) {
      this.dateRange = {
        startDate: new Date(this.value),
        endDate: new Date(this.value)
      };
    }
    if (window.innerWidth >= 667) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
    this.getInputPosition();
    this.updateValues();
  },
  methods: {
    updateValues() {
      this.$emit(
        "input",
        this.formattedDateTime(this.dateRange.startDate, constants.DATE_FORMAT)
      );
    },
    getInputPosition() {
      if (this.$route.name !== "maintenance-reminder-add-bill") {
        return;
      }
      setTimeout(() => {
        let xPosition = 0;
        let element = this.$refs.myInput;
        while (element) {
          xPosition += element.offsetLeft + element.clientLeft;
          element = element.offsetParent;
        }
        const viewportWidth = window.innerWidth;
        // console.log(element.innerWidth);
        if (xPosition > viewportWidth / 2) {
          this.datePickerPosition = "left";
          // console.log(
          //   "Element is positioned on the left side of the viewport."
          // );
        } else {
          this.datePickerPosition = "right";
          // console.log(
          //   "Element is positioned on the right side of the viewport."
          // );
        }
      }, 1000);
    },
    getYesterday() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return yesterday;
    },
    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_FORMAT);
    }
  }
};
</script>

<style lang="scss" scoped>
.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
// .reportrange-text {
//   background: transparent !important;
//   padding: 9px 10px !important;
//   border: 1px solid #d8d6de !important;
//   margin-bottom: 10px;
// // }
// .daterangepicker.openscenter {
//   left: 23px !important;
// }

// @media only screen and (max-width: 1024px) {
//   .daterangepicker.openscenter {
//     left: 50% !important;
//     .calendars {
//       overflow-y: auto;
//       max-height: 40vh;
//     }
//   }
//   .daterangepicker .drp-selected {
//     font-size: 9px;
//   }
// }

@media only screen and (min-width: 667px) {
  .dynamic-position {
    position: relative;
  }
  .dynamic-position .daterangepicker {
    &.opensleft {
      right: auto !important;
      left: -110px !important;
      z-index: 999;
    }
    &.opensright:before {
      right: 130px !important;
      left: auto !important;
    }
    &.opensright:after {
      right: 130px !important;
      left: auto !important;
    }
    &.opensleft:before {
      left: 140px !important;
      right: auto !important;
    }
    &.opensleft:after {
      left: 140px !important;
      right: auto !important;
    }
    &.openscenter {
      right: auto;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 999;
    }

    &.opensright {
      left: auto !important;
      right: 0px !important;
      z-index: 999;
    }
  }
  .daterangepicker {
    &.show-ranges.show-weeknumbers,
    &.show-ranges {
      min-width: 685px !important;
    }
  }
}
</style>
