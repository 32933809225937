<template>
  <div>
    <b-button
      @click="showUnitInfo"
      class="btn preview-btn-sm"
      :size="size ? size : ''"
      variant="outline-primary"
    >
      <Preview />
      <span class="d-none d-sm-inline">{{ $t("device.Preview") }}</span>
    </b-button>
    <!-- <b-modal
      id="open-preview-popup"
      centered
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      modal-class="no-header-modal  preview-sensors "
      :hide-header="true"
    > -->

    <div class="sensor-view" v-if="UnitInfo && UnitInfo.name">
      <!-- {{ UnitInfo }} -->
      <UnitInfoMenu
        v-if="UnitInfo && UnitInfo.name"
        :UnitInfo="UnitInfo"
        :clearUnitInfo="clearUnitInfo"
      ></UnitInfoMenu>
    </div>
    <!-- </b-modal> -->
    <div class="loader-component">
      <Loader :show="showLoading" />
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip, BModal } from "bootstrap-vue";
import UnitInfoMenu from "@/views/Unit/UnitTracking/shared/UnitInfoMenu.vue";
import Preview from "@/assets/images/icons/Preview.svg";
import DeviceService from "@/libs/api/device-service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Loader from "@/layouts/components/Loader.vue";
export default {
  components: {
    BModal,
    BButton,
    UnitInfoMenu,
    Preview,
    Loader
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      UnitInfo: {},
      showLoading: false
    };
  },
  props: ["unitId", "unitName", "devices", "size"],
  mounted() {},
  computed: {},
  methods: {
    async showUnitInfo() {
      try {
        if (!this.devices || !this.devices.length) {
          return;
        }
        this.showLoading = true;

        let response = await new DeviceService().getDeviceSensors({
          device_list: this.devices || [],
          unit_id: this.unitId || null,
          unit_name: this.unitName || null
        });
        this.showLoading = false;
        if (response && response.data) {
          this.UnitInfo = response.data;

          //this.$bvModal.show("open-preview-popup");
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.showLoading = false;
      }
    },
    clearUnitInfo() {
      //this.$bvModal.hide("open-preview-popup");
      this.UnitInfo = {};
    }
  }
};
</script>

<style lang="scss" scoped>
.loader-component {
  .parent-loader {
    position: fixed;
  }
}
</style>
