<template>
  <div class="">
    <div class="date-range-picker label-h">
      <date-range-picker
        v-model="dateRange"
        @update="updateValues"
        :auto-apply="true"
        :timePicker="false"
        :single-date-picker="false"
        :maxDate="is_coming_date ? null : getCurrentTime()"
        :minDate="is_coming_date ? getCurrentTime() : null"
        :ranges="ranges"
      >
        <template #input="picker">
          <span v-if="dateRange && dateRange.startDate">
            {{
              `${
                picker.startDate ? `${DateTimeConvert(picker.startDate)}` : ""
              }`
            }}
            {{
              `${
                picker.endDate ? ` - ${DateTimeConvert(picker.endDate)}` : " "
              }`
            }}
          </span>
          <span v-else class="place-holder-date">{{ $t("unit.SelectDate") }} </span>
        </template>
        <!--    date slot-->
        <template #date="data">
          <span class="small">{{ data.date | dateCell }}</span>
        </template>
        <!--    ranges (new slot syntax) -->
        <template #ranges="ranges">
          <div class="ranges">
            <ul>
              <li
                v-for="(range, name) in ranges.ranges"
                :key="name"
                @click="ranges.clickRange(range)"
              >
                <b>{{ name }}</b>
              </li>
            </ul>
          </div>
        </template>
        <!--    footer slot-->
      </date-range-picker>
    </div>
  </div>
</template>

<script>
import constants from "@/utils/constants";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  components: { DateRangePicker },
  data() {
    return {
      dateRange: {
        startDate: this.getCurrentTime(),
        endDate: this.getCurrentTime()
      },
      ranges: this.getRanges()
    };
  },

  filters: {
    dateCell(value) {
      let dt = new Date(value);

      return dt.getDate();
    },
    date(val) {
      return val ? val.toLocaleString() : "";
    }
  },
  props: [
    "name",
    "desc",
    "label",
    "data_type",
    "component",
    "value",
    "isEditable",
    "isRequired",
    "isMinDate",
    "isMaxDate",
    "is_coming_date"
  ],
  watch: {
    value(val) {}
  },
  mounted() {
    if (this.value) {
      this.dateRange = { startDate: this.value, endDate: this.value };
    } else {
      this.dateRange = { startDate: null, endDate: null };
    }
  },
  methods: {
    updateValues(e) {
      if (this.dateRange.startDate) {
        const startDate = moment(this.dateRange.startDate);
        startDate.hours(0);
        startDate.minutes(0);
        startDate.seconds(0);
        this.dateRange.startDate = startDate;
      }

      this.dateRange.startDate = this.convertDatePerTimezone(
        this.dateRange.startDate
      );

      if (this.dateRange.endDate) {
        let endDate = moment(this.dateRange.endDate);
        const now = moment();

        const isToday = endDate.isSame(now.format(), "day");
        const isThisWeek = endDate.isSame(now.format(), "week");
        const isThisMonth = endDate.isSame(now.format(), "month");

        const yesterday = moment().subtract(1, "day").startOf("day");
        const isYesterday = endDate.isSame(yesterday, "day");

        const lastWeekStart = moment().subtract(1, "week").startOf("week");
        const lastWeekEnd = moment().subtract(1, "week").endOf("week");
        const isLastWeek = endDate.isBetween(
          lastWeekStart,
          lastWeekEnd,
          null,
          "[]"
        );

        const lastMonthStart = moment().subtract(1, "month").startOf("month");
        const lastMonthEnd = moment().subtract(1, "month").endOf("month");
        const isLastMonth = endDate.isBetween(
          lastMonthStart,
          lastMonthEnd,
          null,
          "[]"
        );

        if (isToday || isThisWeek || isThisMonth) {
          // Set current time for Today, This Week, This Month
          endDate.hours(now.hours());
          endDate.minutes(now.minutes());
          endDate.seconds(now.seconds());
        }

        if (isYesterday || isLastWeek || isLastMonth) {
          // Set to 23:59:59 for Yesterday, Last Week, Last Month
          endDate.hours(23);
          endDate.minutes(59);
          endDate.seconds(59);
        }

        this.dateRange.endDate = endDate;
      }

      this.dateRange.endDate = this.convertDatePerTimezone(
        this.dateRange.endDate
      );

      this.$emit("input", this.dateRange);
    },
    getRanges() {
      if (this.isMinDate) {
        return false;
      }
      return this.getCustomRanges(true);
    },

    DateTimeConvert(date) {
      return this.formattedDateTime(date, constants.DATE_FORMAT);
    }
  }
};
</script>

<style lang="scss">
.daterangepicker select.hourselect {
  background: transparent !important;
  padding-left: 10px;
}
.daterangepicker select.minuteselect {
  background: transparent !important;
  padding-left: 10px;
}
.slot {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-black {
  color: #000;
}
.label-h {
  display: flex;
  .reportrange-text {
    display: flex;
    align-items: center;
  }
}
.history-date {
  white-space: nowrap;
}
.dark-layout {
  .date-range-picker {
    .reportrange-text {
      border: 1px solid #404656 !important;
    }
  }
}
.date-range-picker {
  .reportrange-text {
    border: 1px solid #d8d6de !important;
  }

  .calendars {
    display: flex;
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 768px) {
  .date-range-picker {
    .calendars {
      display: flex;
      width: 255px;
      flex-wrap: wrap;
    }
    .daterangepicker .calendars-container {
      display: flex;
      flex-direction: column;
    }
  }
}
// .reportrange-text {
//   background: transparent !important;
//   padding: 9px 10px !important;
//   border: 1px solid #d8d6de !important;
//   margin-bottom: 10px;
// }
// .daterangepicker.openscenter {
//   left: 23px !important;
// }
// @media only screen and (max-width: 1024px) {
//   .daterangepicker.openscenter {
//     left: 50% !important;
//     .calendars {
//       overflow-y: auto;
//       max-height: 40vh;
//     }
//   }
//   .daterangepicker .drp-selected {
//     font-size: 9px;
//   }
// }
</style>
