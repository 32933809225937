<template>
  <b-modal
    id="modal-add-filters"
    centered
    no-close-on-esc
    no-close-on-backdrop
    size="sm"
    modal-class="no-header-modal "
    :hide-header="true"
  >
    <b-card-text>
      <div class="new-invite-modal-title">
        {{
          filterInfoData && filterInfoData.action === "edit"
            ? $t("device.filters.EditFilter")
            : $t("device.filters.Add Filter")
        }}
      </div>

      <validation-observer ref="selectForm" #default="{ invalid }">
        <b-form @submit.prevent="validationForm" @reset.prevent="closePopup">
          <b-row>
            <b-col>
              <b-form-group
                :label="$t('device.filters.SelectAlgorithm') + ' *'"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('device.filters.SelectAlgorithm')"
                  rules="required"
                >
                  <v-select
                    id="device-algorithm"
                    name="SelectAlgorithm"
                    v-model="filterInfo.type"
                    :options="algorithms"
                    :clearable="false"
                    :reduce="(option) => option.type"
                    label="label"
                    height="80"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group :label="$t('device.filters.SelectSensors') + ' *'">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('device.filters.SelectSensors')"
                  rules="required"
                >
                  <DeviceProtocolParametersModal
                    v-model="filterInfo.key"
                    name="SelectSensors"
                    class="mb-sensors"
                    :isPlanParameter="true"
                    :protocolName="protocolName"
                    :sensorField="{
                      field: 's',
                      data_type: 'number',
                      is_required: true
                    }"
                  ></DeviceProtocolParametersModal>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <div v-if="filterInfo.type === 'median'" class="Median-data">
            <b-row>
              <b-col>
                <b-form-group :label="$t('device.filters.WindowSize') + '*'">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.filters.WindowSize')"
                    :rules="{
                      required: true,
                      min_value: 1,
                      max_value: 50,
                      multiple_value_one: true
                    }"
                  >
                    <b-form-input
                      name="windowSize"
                      v-model="filterInfo.options.window_size"
                      type="number"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group :label="$t('device.filters.ResetTimeout') + '*'">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.filters.ResetTimeout')"
                    :rules="{
                      required: true,
                      min_value: 10,
                      max_value: 3600,
                      multiple_value_one: true
                    }"
                  >
                    <b-form-input
                      id="WindowReset"
                      name="WindowReset"
                      v-model="filterInfo.options.window_reset"
                      type="number"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div v-if="filterInfo.type === 'kalman'" class="Median-data">
            <b-row>
              <b-col>
                <b-form-group :label="$t('device.filters.NoiseLevel') + '*'">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('device.filters.NoiseLevel')"
                    :rules="{
                      required: true,
                      min_value: 0.0000001,
                      multiple_value_zero_one_up_to_six: true
                    }"
                  >
                    <b-form-input
                      name="NoiseLevel"
                      v-model="filterInfo.options.noise_level"
                      type="number"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="d-flex justify-content-center mb-2">
            <b-button
              variant="primary"
              type="submit"
              class="mt-1 mr-1"
              :disabled="invalid"
            >
              {{
                filterInfoData && filterInfoData.action === "edit"
                  ? $t("device.filters.Update")
                  : $t("device.filters.Add")
              }}
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="close"
              type="button"
              class="mt-1"
            >
              {{ $t("user.Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card-text>
  </b-modal>
</template>
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import DeviceProtocolParametersModal from "./DeviceProtocolParametersModal.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BAvatar,
  BButton,
  BForm,
  BTab,
  BTabs,
  BCard,
  BMedia,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormCheckbox,
  VBTooltip,
  BInputGroupAppend,
  BInputGroup,
  BLink,
  BCardText
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BFormGroup,
    BFormInput,
    BImg,
    BForm,
    BCardCode,
    BTab,
    BTabs,
    BCard,
    BMedia,
    BFormInvalidFeedback,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BCardText,
    VBTooltip,
    BInputGroupAppend,
    BInputGroup,
    BLink,
    DeviceProtocolParametersModal
  },
  data() {
    return {
      required,
      paramsvalue: "",
      validationError: "",
      window_reset: "",
      filterInfo: {
        key: "",
        type: "median",
        options: {
          window_size: "",
          window_reset: "",
          noise_level: ""
        }
      },
      algorithms: [
        { type: "median", label: "Median" },
        { type: "kalman", label: "Kalman" }
      ]
    };
  },

  props: ["protocolName", "addFilterData", "filterInfoData", "items"],
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  watch: {
    filterInfoData() {
      if (
        this.filterInfoData &&
        this.filterInfoData.key &&
        this.filterInfoData.action != "add"
      ) {
        this.filterInfo = JSON.parse(JSON.stringify(this.filterInfoData));
      } else {
        this.filterInfo = {
          key: "",
          type: "median",
          options: {
            window_size: "",
            window_reset: "",
            noise_level: ""
          }
        };
      }
    }
  },
  mounted() {
    if (this.filterInfoData && this.filterInfoData.key) {
      this.filterInfo = JSON.parse(JSON.stringify(this.filterInfoData));
    }
  },
  methods: {
    async save() {
      // await this.validationForm();
      // console.log(this.$refs.selectAlgorithm.);

      // Save logic
      const isAlreadyAdded = this.items.filter(
        (e) => e.key === this.filterInfo.key
      );
      if (isAlreadyAdded && isAlreadyAdded.length) {
        if (
          this.filterInfoData.action === "edit" &&
          isAlreadyAdded.length > 1
        ) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "already Exists",
              text: "already Exists",
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return;
        } else if (this.filterInfoData.action !== "edit") {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "already Exists",
              text: "already Exists",
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return;
        }
      }
      let options = {};
      if (this.filterInfo.options.window_size) {
        options.window_size = this.filterInfo.options.window_size;
      }
      if (this.filterInfo.options.window_reset) {
        options.window_reset = this.filterInfo.options.window_reset;
      }
      if (this.filterInfo.options.noise_level) {
        options.noise_level = this.filterInfo.options.noise_level;
      }
      this.addFilterData({
        key: this.filterInfo.key,
        type: this.filterInfo.type,
        options
      });
      this.closePopup();
      // removeFilterData(this.filterInfo);
      // // Clear form inputs after saving
      // this.filterInfo.key = "";
      // this.filterInfo.type = "";
      // this.filterInfo.options.window_size = null;
      // this.filterInfo.options.window_reset = null;
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.selectForm.validate().then((success) => {
          if (success) {
            resolve(true);
            this.save();
          } else {
            reject();
          }
        });
      });
    },
    closePopup() {
      // this.filterInfo.key = "";
      // this.filterInfo.type = "median";
      // this.filterInfo.options.window_size = null;
      // this.filterInfo.options.window_reset = null;
      // this.filterInfo.options.noise_level = null;
      if (this.filterInfoData && this.filterInfoData.key) {
        this.filterInfo = JSON.parse(JSON.stringify(this.filterInfoData));
      }
      this.$bvModal.hide("modal-add-filters");
    },
    async close() {
      this.closePopup();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.mb-sensors .form-group {
  margin-bottom: 0rem !important;
}
.sensor-slection {
  .v-select {
    .vs__dropdown-menu {
      max-height: 160px !important;
    }
  }
}
</style>
<style lang="scss">
.open-tel-parama {
  background-image: url("./../../assets/images/new-icon/open-tel-parama.svg");
  background-repeat: no-repeat;
  background-position: center right 7px;
  padding-right: 35px;
}
.form-control[readonly] {
  background-color: white;
}
.disabled-check-box {
  pointer-events: none;
}
.v-select ul {
  max-height: 150px;
}
.modal-add-sensors-v1 .modal-body {
  height: auto !important;
  max-height: 95vh !important;
}

// .sensor-modal-scroll {
//   max-height: 55vh;
//   overflow-y: auto;
//   padding: 0 15px;
// }

// .sensor-modal-scroll {
//   .sensors-sc {
//     .modal-info-track {
//     max-height: calc(100vh - 500px) !important ;
//     overflow: auto;
//     padding: 0 15px;
//   }
// }
// }
// }

.parameters-label {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--primary);
}
@media screen and (max-width: 600px) {
  .visiable-sensor {
    margin-bottom: 10px;
  }
  .parameterssize {
    align-items: baseline;
  }
  .main123 {
    width: 40%;
  }
  .calculationTable {
    margin-left: 0px;
    margin-top: 0px;
  }
}
.open-tel-parama + svg {
  path {
    fill: var(--primary);
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.new-invite-modal-title {
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 10px;
  color: $headings-color;
}
.Median-data {
  margin-top: -18px;
}
</style>
