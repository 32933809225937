var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"CustomSensors","no-close-on-esc":"","no-close-on-backdrop":"","centered":"","modal-class":"no-header-modal","hide-header":true,"size":"lg"}},[_c('div',{staticStyle:{"padding":"0.8rem 1.4rem"}}),_c('b-card-text',[_c('div',{staticClass:"new-invite-modal-title"},[_vm._v(" "+_vm._s(_vm.$t("device.AddCustomSensor"))+" ")]),_c('div',[_c('validation-observer',{ref:"customComponent",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mtt-37",on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-provider',{attrs:{"name":"field","vid":"field","rules":{
                required: true,
                min: 2,
                max: 30,
                regex: /^[a-z-0-9_]{2,30}$/
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"27px","padding-right":"10px"},attrs:{"sm":"3"}},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(((_vm.$t("device.Field")) + " *"))+" ")])]),_c('b-col',{staticStyle:{"margin-top":"17px"},attrs:{"sm":"8"}},[_c('b-form-input',{class:errors.length == 0 && _vm.parameter.field
                        ? 'is-valid'
                        : null,staticStyle:{"text-transform":"lowercase"},on:{"input":_vm.updateInputState},model:{value:(_vm.parameter.field),callback:function ($$v) {_vm.$set(_vm.parameter, "field", $$v)},expression:"parameter.field"}})],1)],1),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"7px","padding-right":"10px"},attrs:{"sm":"3"}}),_c('b-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"8"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? ((_vm.handleError(errors[0])) + " " + (_vm.parameter.field && _vm.parameter.field.length ? _vm.$t("device.sensorFiledValidation") : "")) : ""))])])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"label","vid":"label","rules":{
                required: true,
                min: 2,
                max: 60
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"27px","padding-right":"10px"},attrs:{"sm":"3"}},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(((_vm.$t("device.Label")) + " *"))+" ")])]),_c('b-col',{staticStyle:{"margin-top":"17px"},attrs:{"sm":"8"}},[_c('b-form-input',{model:{value:(_vm.parameter.label),callback:function ($$v) {_vm.$set(_vm.parameter, "label", $$v)},expression:"parameter.label"}})],1)],1),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"7px","padding-right":"10px"},attrs:{"sm":"3"}}),_c('b-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"8"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? ((_vm.handleError(errors[0])) + " ") : ""))])])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"dataType","vid":"dataType","rules":{
                required: true,
                min: 3,
                max: 15
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"27px","padding-right":"10px"},attrs:{"sm":"3"}},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(((_vm.$t("device.DataType")) + " *"))+" ")])]),_c('b-col',{staticStyle:{"margin-top":"17px"},attrs:{"sm":"8"}},[_c('v-select',{attrs:{"id":"vue-select","placeholder":_vm.$t('device.DataType'),"options":_vm.DataTypes,"reduce":function (option) { return option.value; },"label":"text"},model:{value:(_vm.parameter.data_type),callback:function ($$v) {_vm.$set(_vm.parameter, "data_type", $$v)},expression:"parameter.data_type"}})],1)],1),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"7px","padding-right":"10px"},attrs:{"sm":"3"}}),_c('b-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"8"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? ((_vm.handleError(errors[0])) + " ") : ""))])])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"value","vid":"value","rules":{
                required: true
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [(_vm.protocolName && _vm.parameter.data_type)?_c('b-row',{staticClass:"flex-class"},[_c('b-col',{staticStyle:{"margin-top":"27px","padding-right":"10px"},attrs:{"sm":"3"}},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(((_vm.$t("device.Value")) + " *"))+" ")])]),_c('b-col',{staticStyle:{"margin-top":"27px","padding-right":"10px"},attrs:{"sm":"5"}},[_c('DeviceProtocolParametersModal',{attrs:{"isViewOnly":false,"protocolName":_vm.protocolName,"parameter":{},"sensorsData":_vm.sensorsData,"sensorInfo":_vm.sensorInfo,"sensorField":{
                      field: _vm.parameter.field,
                      data_type: _vm.parameter.data_type,
                      is_required: true
                    }},model:{value:(_vm.parameter.value),callback:function ($$v) {_vm.$set(_vm.parameter, "value", $$v)},expression:"parameter.value"}})],1),_c('b-col',{staticStyle:{"margin-top":"27px","padding-right":"10px"},attrs:{"sm":"1"}},[(
                      _vm.parameter.data_type &&
                      _vm.parameter.data_type.toLowerCase() == 'number'
                    )?_c('div',{staticClass:"sensor-check ml-1"},[_c('b-form-checkbox',{staticClass:"disabled-check-box",attrs:{"checked":_vm.parameter && _vm.parameter.table && _vm.parameter.table.length
                          ? true
                          : false}})],1):_vm._e()]),_c('b-col',{staticStyle:{"margin-top":"10px","padding-right":"10px"},attrs:{"sm":"3"}},[_c('div',{staticClass:"calculationTable"},[_c('CalculationTableModal',{attrs:{"isViewOnly":false,"sensorField":{
                        field: _vm.parameter.field,
                        data_type: _vm.parameter.data_type
                      },"keyField":_vm.parameter.field},model:{value:(_vm.parameter.table),callback:function ($$v) {_vm.$set(_vm.parameter, "table", $$v)},expression:"parameter.table"}})],1)])],1):_vm._e(),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"7px","padding-right":"10px"},attrs:{"sm":"3"}}),_c('b-col',{staticStyle:{"margin-top":"10px"},attrs:{"sm":"8"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t("device.customSensorFiledValidationError") : ""))])])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-content-center mb-64 mtt-50"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("device.Add"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",staticStyle:{"z-index":"100000"},attrs:{"variant":"outline-secondary","type":"button"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("device.Cancel"))+" ")])],1)],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }