var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"modal-info-track new-date-picker"},[_c('b-row',{staticClass:"flex-class"},_vm._l((_vm.parameters),function(parameter,key){return _c('b-col',{key:key,attrs:{"sm":"12"}},[(
            _vm.MAPPINGS[parameter.component] ||
            parameter.source == 'config' ||
            parameter.source == 'input'
          )?_c('span',[_c('validation-provider',{ref:parameter.field,refInFor:true,attrs:{"name":parameter.field,"rules":{
              required: parameter.is_required ? true : false
            }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [(
                _vm.MAPPINGS[parameter.component] && parameter.source == 'config'
              )?_c('b-row',{staticClass:"flex-class"},[_c('b-col',{staticClass:"dy-m-27 dy-pr-10",attrs:{"sm":"3"}},[_c('div',{staticClass:"sensor-label-wrap"},[_c('span',{staticStyle:{"text-transform":"capitalize"},attrs:{"title":((parameter.label
                        ? parameter.label
                        : parameter.field && _vm.formattedString(parameter.field)) + "   " + (parameter.is_required ? '*:' : ':'))}},[_vm._v(" "+_vm._s(parameter.label ? parameter.label : parameter.field && _vm.formattedString(parameter.field))+_vm._s(parameter.is_required ? "*:" : ":")+" ")])])]),_c('b-col',{staticClass:"dy-m-17",attrs:{"sm":"8"}},[_c(_vm.MAPPINGS[parameter.component],_vm._b({tag:"component",attrs:{"state":_vm.getValidationState(validationContext),"isEditable":_vm.isEditable,"selectedSensor":_vm.selectedSensor,"sensorInfo":_vm.sensorInfo,"device":_vm.device},model:{value:(_vm.sensorInfo.fields[parameter.field].value),callback:function ($$v) {_vm.$set(_vm.sensorInfo.fields[parameter.field], "value", $$v)},expression:"sensorInfo.fields[parameter.field].value"}},'component',Object.assign({}, parameter),false))],1)],1):_vm._e(),(
                parameter.source == 'input' &&
                parameter.component !== 'custom'
              )?_c('b-row',{staticClass:"flex-class"},[_c('b-col',{staticClass:"dy-m-40 dy-pr-10",attrs:{"sm":"2"}},[_c('div',{staticClass:"sensor-label-wrap"},[_c('span',{staticStyle:{"text-transform":"capitalize"},attrs:{"title":((parameter.label
                        ? parameter.label
                        : parameter.field && _vm.formattedString(parameter.field)) + "   " + (parameter.is_required ? '*' : ''))}},[_vm._v(" "+_vm._s(parameter.label ? parameter.label : parameter.field && _vm.formattedString(parameter.field))+_vm._s(parameter.is_required ? "*:" : ":")+" ")])])]),_c('b-col',{staticClass:"dy-m-27 dy-pr-10",attrs:{"sm":"7"}},[_c('DeviceProtocolParametersModal',{attrs:{"isViewOnly":!_vm.isEditable,"protocolName":_vm.protocolName,"sensorsData":_vm.sensorsData,"parameter":parameter,"sensorInfo":_vm.sensorInfo,"state":_vm.getValidationState(validationContext),"selectedSensor":_vm.selectedSensor,"sensorField":{
                    field: parameter.field,
                    data_type: parameter.data_type,
                    is_required: parameter.is_required
                  }},on:{"isInvalid":_vm.isInvalid},model:{value:(_vm.sensorInfo.fields[parameter.field].value),callback:function ($$v) {_vm.$set(_vm.sensorInfo.fields[parameter.field], "value", $$v)},expression:"sensorInfo.fields[parameter.field].value"}})],1),_c('b-col',{staticClass:"dy-m-27 dy-pr-10",attrs:{"sm":"1"}},[(
                    parameter.data_type &&
                    parameter.data_type.toLowerCase() == 'number'
                  )?_c('div',{staticClass:"sensor-check ml-1"},[_c('b-form-checkbox',{staticClass:"disabled-check-box",attrs:{"checked":_vm.sensorInfo &&
                      _vm.sensorInfo.fields[parameter.field] &&
                      _vm.sensorInfo.fields[parameter.field].table &&
                      _vm.sensorInfo.fields[parameter.field].table.length
                        ? true
                        : false,"disabled":""}})],1):_vm._e()]),_c('b-col',{staticClass:"dy-m-10 dy-pr-10 apply-table",attrs:{"sm":"2"}},[_c('div',{staticClass:"calculationTable"},[_c('CalculationTableModal',{attrs:{"isViewOnly":!_vm.isEditable,"sensorField":{
                      field: parameter.field,
                      data_type: parameter.data_type
                    },"keyField":parameter.field},model:{value:(_vm.sensorInfo.fields[parameter.field]),callback:function ($$v) {_vm.$set(_vm.sensorInfo.fields, parameter.field, $$v)},expression:"sensorInfo.fields[parameter.field]"}})],1)])],1):_vm._e()]}}],null,true)})],1):_vm._e()])}),1),(
        _vm.parameters &&
        _vm.parameters.length &&
        _vm.parameters.map(function (e) { return e.component; }).includes('custom')
      )?_c('b-row',{staticClass:"flex-class"},[_c('b-col',{staticClass:"dy-m-40 dy-pr-10",attrs:{"sm":"12"}},[_c('CustomComponents',{attrs:{"protocolName":_vm.protocolName,"isEditable":_vm.isEditable,"sensorsData":_vm.sensorsData,"sensorInfo":_vm.sensorInfo},model:{value:(_vm.sensorInfo.fields),callback:function ($$v) {_vm.$set(_vm.sensorInfo, "fields", $$v)},expression:"sensorInfo.fields"}})],1)],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }